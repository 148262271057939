import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as api from '../../utils/api';
import { globalAction, projectAction } from '../../store/actions';
import { Checkbox, FormControlLabel, Radio, RadioGroup, Slider, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import { CirclePicker } from "react-color";
import { SketchPicker } from "react-color";
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import BackButton from '../../components/BackButton';
import { toast } from 'react-toastify';
import * as mapUtils from '../../components/MapView/MapUtils';

import './style.scss';

const CustomCheckbox = withStyles({
	root: {
		color: '#67757c',
		'&$checked': {
			color: '#67757c',
		},
	}
})(props => <Checkbox color="default" {...props} />);


const PointCloudSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
    height: 2,
    padding: '20px 0',
    '& .MuiSlider-thumb': {
      height: 15,
      width: 15,
      backgroundColor: '#fff',
      '&:focus, &:hover, &.Mui-active': {
        boxShadow:
          '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
        },
      },
    },
    '& .MuiSlider-valueLabel': {
      display: 'none'
    },
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#bfbfbf',
      height: 10,
      width: 1,
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'currentColor',
      },
    },
    '& .MuiSlider-markLabel': {
        color: '#bfbfbf',
        fontSize: '12pt',
        marginTop: '6px'
    },
  }));

const useStyles = makeStyles(theme => ({
	root: {
        padding: 10,
        overflow: "auto"
	},
    info_section: {
        position: 'absolute',
        marginTop: '20px',
        justifyContent: 'center',
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 75px)'
    },
    measurement_sub_options: {
        backgroundColor: 'yellow'
    },
    accordion_title: {
        display:'flex',
        gap: 10,
        justifyContents: 'space-between',
    },
    panel_label : {
        color: "#2196f3",
        fontSize: 20,
        marginTop: 3,
        textAlign: 'left',
    },
    panel_title:{
        display: 'flex',
        gap: 10,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    project_label: {
        color: "#9e9e9e",
        fontSize: 16,
        marginTop: 3,
        textAlign: 'left',
    },
    annotation_item: {
        display: 'flex',
        alignItems: 'center',
        gap: 10
    },
    classificationName: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 15,
        marginTop: 8,
        marginLeft: 10,
        width: "calc(100% - 130px)",
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip",
        textAlign: 'left'
    },
    classificationIndex: {
        float: "left",
        color: "#9e9e9e",
        fontSize: 15,
        marginTop: 8,
        marginLeft: 10,
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "clip",
        textAlign: 'center',
        width: 40
    },
    button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
    label_settings_right:{
        float: 'right',
        display: 'flex',
        gap: 20,
        width: 'fit-content !important',
    },
    lidar_icon:{
        width: 20,
        height: 20,
        backgroundSize: 'cover'
    },
    sub_label_settings: {
        alignSelf: 'flex-start',
        marginTop: 12
    }
}));

const marks = [
    {
      value: 0,
      label: "Min"
    },
    {
      value: 50,
    },
    {
      value: 100,
      label: "Max"
    },
];

function valuetext(value) {
    return `${value}`;
}

function valueLabelFormat(value) {
return marks.findIndex((mark) => mark.value === value) + 1;
}

function SettingsPanel(props) {
	const classes = useStyles();

    function redirectToLogin() {
        props.history.replace("/");
    }

    const [pointCloudDensity, setPointCloudDensity] = React.useState(0);
    const [expandedPointCloud, setExpandedPointCloud] = React.useState(false);
    const [expandedProfile, setExpandedProfile] = React.useState(false);
    const [enableAnnotationPopup, setEnableAnnotationPopup] = React.useState(1);
    const [measurementSnapOn, setMeasurementSnapOn] = React.useState(true);
    const [measureUnit, setMeasureUnit] = React.useState("meter");
    const [isShowingColor, setShowingColor] = React.useState(false);
    const [profileName, setProfileName] = React.useState("profile1");
    const [profile1, setProfile1] = React.useState(null);
    const [profile2, setProfile2] = React.useState(null);
    const [profile3, setProfile3] = React.useState(null);
    const [showClassificationTable, setShowClassificationTable] = React.useState(false);

    const [customClassification, setCustomClassification] = React.useState({ index : -1, visible: true, name: '', color: "#ffffff" });
    const [isUpdatingClassification, setUpdatingClassification] = React.useState(false);
    const [isAddingClassification, setAddingClassification] = React.useState(false);
    const [clickEvent, setClickEvent] = React.useState(false);

    const [annotationTypeList, setAnnotationTypeList] = React.useState([]);
    const [annotationStatusList, setAnnotationStatusList] = React.useState([]);
    const [expandedAnnotationType, setExpandedAnnotationType] = React.useState(false);
    const [expandedAnnotationStatus, setExpandedAnnotationStatus] = React.useState(false);
    const [expandedPoleType, setExpandedPoleType] = React.useState(false);


    const [customAnnotation, setCustomAnnotation] = React.useState({ value: "", label: "" });
    const [isUpdatingAnnotation, setUpdatingAnnotation] = React.useState(0); // 0: not updating, 1: annotationType, 2: annotationStatus
    const [updatingAnnotationIndex, setUpdatingAnnotationIndex] = React.useState(0);
    const [expandedMeasurementOptions, setExpandedMeasurementOptions] = React.useState(false);


    const [showTable, setShowTable] = React.useState(false);
    const [tableType, setTableType] = React.useState(0); // 0: Classification, 1: Annotation Type
	const [csvRuleData, setCSVRuleData] = React.useState("");

    useEffect(() => {
		setPointCloudDensity(props.userInfo.point_cloud_density);
        setEnableAnnotationPopup(props.userInfo.enable_annotation_popup);
        if (props.userInfo.measurement_snap_on !== undefined) {
            setMeasurementSnapOn(Boolean(props.userInfo.measurement_snap_on));
        }
        setMeasureUnit(props.userInfo.measurement_units);
        setProfileName(props.userInfo.profile_name);
        if (props.userInfo.profile1_classification === "" || !props.userInfo.profile1_classification) {
            setProfile1([...props.classificationList])
        }
        else {
            setProfile1(JSON.parse(props.userInfo.profile1_classification));
        }
        if (props.userInfo.profile2_classification === "" || !props.userInfo.profile2_classification) {
            setProfile2([...props.classificationList])
        }
        else {
            setProfile2(JSON.parse(props.userInfo.profile2_classification));
        }
        if (props.userInfo.profile3_classification === "" || !props.userInfo.profile3_classification) {
            setProfile3([...props.classificationList])
        }
        else {
            setProfile3(JSON.parse(props.userInfo.profile3_classification));
        }

        if (props.userInfo.annotation_type_list === "" || !props.userInfo.annotation_type_list) {
            setAnnotationTypeList(mapUtils.generateAnnotationTypeList());
        }
        else {
            setAnnotationTypeList(JSON.parse(props.userInfo.annotation_type_list));
        }

        if (props.userInfo.annotation_status_list === "" || !props.userInfo.annotation_status_list) {
            setAnnotationStatusList(mapUtils.generateAnnotationStatusList());
        }
        else {
            setAnnotationStatusList(JSON.parse(props.userInfo.annotation_status_list));
        }
	}, [props.userInfo]);

    async function updateSettings(enable_annotation_popup, measurement_units, point_cloud_density,
        pole_icon, style_color, profile_name, profile1_classification, profile2_classification, profile3_classification,
        annotation_type_list, annotation_status_list, measurement_snap_on) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.updateSettings({token: userToken, enable_annotation_popup,
                measurement_units, point_cloud_density, pole_icon, style_color, profile_name, profile1_classification, profile2_classification, profile3_classification,
                annotation_type_list, annotation_status_list, measurement_snap_on});

            if (response.data && !response.data.error) {
                props.setUserInfo(response.data);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

    function handleEnableAnnotationPopup() {
        let cloneUserInfo = Object.assign({}, props.userInfo);
        cloneUserInfo.enable_annotation_popup = !cloneUserInfo.enable_annotation_popup;
        props.setUserInfo(cloneUserInfo)
        updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
            cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
            cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
            cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
	}

    function handleMeasurmentSnapOn() {
        let cloneUserInfo = Object.assign({}, props.userInfo);
        cloneUserInfo.measurement_snap_on = !cloneUserInfo.measurement_snap_on;
        setMeasurementSnapOn(!measurementSnapOn);
        props.setUserInfo(cloneUserInfo)
        updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
            cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
            cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
            cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
	}

    function handleMesurementUnits() {
        let cloneUserInfo = Object.assign({}, props.userInfo);
        let units = (cloneUserInfo.measurement_units === "imperial")?"meter":"imperial";
        cloneUserInfo.measurement_units = units;
        props.setUserInfo(cloneUserInfo)
        updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
            cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
            cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
            cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
	}

    function handleProfile1Classification(event) {
        if (!clickEvent) {
            setClickEvent(true);
            if (props.userInfo.profile_name === "profile1") {
                setShowClassificationTable(!showClassificationTable);
            }
            else {
                let cloneUserInfo = Object.assign({}, props.userInfo);
                cloneUserInfo.profile_name = "profile1";
                props.setUserInfo(cloneUserInfo);
                updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
                    cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
                    cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
                    cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
            }
        }
        setTimeout(function() {
            setClickEvent(false);
        }, 500);
	}

    function handleProfile2Classification(event) {
        if (!clickEvent) {
            setClickEvent(true);
            if (props.userInfo.profile_name === "profile2") {
                setShowClassificationTable(!showClassificationTable);
            }
            else {
                let cloneUserInfo = Object.assign({}, props.userInfo);
                cloneUserInfo.profile_name = "profile2";
                props.setUserInfo(cloneUserInfo);
                updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
                    cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
                    cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
                    cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
            }
        }
        setTimeout(function() {
            setClickEvent(false);
        }, 500);
	}

    function handleProfile3Classification(event) {
        if (!clickEvent) {
            setClickEvent(true);
            if (props.userInfo.profile_name === "profile3") {
                setShowClassificationTable(!showClassificationTable);
            }
            else {
                let cloneUserInfo = Object.assign({}, props.userInfo);
                cloneUserInfo.profile_name = "profile3";
                props.setUserInfo(cloneUserInfo);
                updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
                    cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
                    cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
                    cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
            }
        }
        setTimeout(function() {
            setClickEvent(false);
        }, 500);
	}

    function handlePointCloudDensity(event, newValue) {
        let cloneUserInfo = Object.assign({}, props.userInfo);
        cloneUserInfo.point_cloud_density = newValue;
        props.setUserInfo(cloneUserInfo);
    }

    function pointCloudDensityStop(event, newValue) {
        let cloneUserInfo = Object.assign({}, props.userInfo);
        cloneUserInfo.point_cloud_density = newValue;
        props.setUserInfo(cloneUserInfo);
        updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
            cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
            cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
            cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
    }

    async function chanePoleIcon(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {
            let cloneUserInfo = Object.assign({}, props.userInfo);
            cloneUserInfo.pole_icon = parseInt(event.currentTarget.dataset.id);
            props.setUserInfo(cloneUserInfo);
            updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
                cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
                cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
                cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
        }
    }

    async function changeStyleColor(rgbColorValue) {
        let r = rgbColorValue.r.toString(16);
        let g = rgbColorValue.g.toString(16);
        let b = rgbColorValue.b.toString(16);

        if (r.length === 1)
            r = "0" + r;
        if (g.length === 1)
            g = "0" + g;
        if (b.length === 1)
            b = "0" + b;

        let cssColorValue = "#" + r + g + b;

        let cloneUserInfo = Object.assign({}, props.userInfo);
        cloneUserInfo.style_color = cssColorValue;
        props.setUserInfo(cloneUserInfo);
        updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
            cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
            cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
            cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
    }

    function getRGBColor(cssColorValue) {
        let r = 0, g = 0, b = 0;

        // 3 digits
        if (cssColorValue.length === 4) {
            r = "0x" + cssColorValue[1] + cssColorValue[1];
            g = "0x" + cssColorValue[2] + cssColorValue[2];
            b = "0x" + cssColorValue[3] + cssColorValue[3];

          // 6 digits
          } else if (cssColorValue.length === 7) {
            r = "0x" + cssColorValue[1] + cssColorValue[2];
            g = "0x" + cssColorValue[3] + cssColorValue[4];
            b = "0x" + cssColorValue[5] + cssColorValue[6];
          }

        return {r: +r, g: +g, b: +b, a: 1};
    }

    function showColorPallete() {
        setShowingColor(!isShowingColor);
    }

    function updateClassificationVisible(index, visible) {
        let cloneUserInfo = Object.assign({}, props.userInfo);
        let _classificationSettings = null;
        if (props.userInfo.profile_name === "profile1") {
            _classificationSettings = [...profile1];
        }
        else if (props.userInfo.profile_name === "profile2") {
            _classificationSettings = [...profile2];
        }
        else if (props.userInfo.profile_name === "profile3") {
            _classificationSettings = [...profile3];
        }
        for (let i = 0; i < _classificationSettings.length; i ++) {
            if (_classificationSettings[i].index === index) {
                _classificationSettings[i].visible = visible;
            }
        }

        if (props.userInfo.profile_name === "profile1") {
            setProfile1(_classificationSettings);
            cloneUserInfo.profile1_classification = JSON.stringify(_classificationSettings);
        }
        else if (props.userInfo.profile_name === "profile2") {
            setProfile2(_classificationSettings);
            cloneUserInfo.profile2_classification = JSON.stringify(_classificationSettings);
        }
        else if (props.userInfo.profile_name === "profile3") {
            setProfile3(_classificationSettings);
            cloneUserInfo.profile3_classification = JSON.stringify(_classificationSettings);
        }
        props.setUserInfo(cloneUserInfo);
        updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
            cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
            cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
            cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
    }

    function ClassificationEyeComponent(classificationProps) {
        const [visibleClassification, setVisibleClassification] = React.useState(classificationProps.classification.visible);

        function onClickEyeIcon() {
            updateClassificationVisible(classificationProps.classification.index, !visibleClassification)
            setVisibleClassification(!visibleClassification);
        }

        return (
            <div className={"small_icon can_hover_icon no_boder_line settings_visible_icon"} onClick={onClickEyeIcon}>
                {visibleClassification?
                <div className='icon visible_icon'></div>:
                <div className='icon invisible_icon'></div>}
            </div>
        );
    }

    function showUpdateClassification(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {
            let _classificationSettings = null;
            if (props.userInfo.profile_name === "profile1") {
                _classificationSettings = [...profile1];
            }
            else if (props.userInfo.profile_name === "profile2") {
                _classificationSettings = [...profile2];
            }
            else if (props.userInfo.profile_name === "profile3") {
                _classificationSettings = [...profile3];
            }
            for (let i = 0; i < _classificationSettings.length; i ++) {
                if (_classificationSettings[i].index + "" === event.currentTarget.dataset.id + "") {
                    setCustomClassification(_classificationSettings[i]);
                    setUpdatingClassification(true);
                }
            }
        }
    }

    function showAnnotationTypeChange(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {
            let index = parseInt(event.currentTarget.dataset.id)
            setUpdatingAnnotationIndex(index);
            setCustomAnnotation(annotationTypeList[index]);
            setUpdatingAnnotation(1);
        }
    }

    function showAnnotationStatusChange(event) {
        if (event.currentTarget && event.currentTarget.dataset.id) {
            let index = parseInt(event.currentTarget.dataset.id)
            setUpdatingAnnotationIndex(index);
            setCustomAnnotation(annotationStatusList[index]);
            setUpdatingAnnotation(2);
        }
    }

    function hideAnnotationEdit() {
        setUpdatingAnnotationIndex(0);
        setCustomAnnotation({ value: "", label: "" });
        setUpdatingAnnotation(0);
    }

    function updateAnnotation() {
        let cloneUserInfo = Object.assign({}, props.userInfo);
        if (isUpdatingAnnotation === 1) {
            let _annotationTypeList = [...annotationTypeList];
            _annotationTypeList[updatingAnnotationIndex] = customAnnotation;
            cloneUserInfo.annotation_type_list = JSON.stringify(_annotationTypeList);
            setAnnotationTypeList(_annotationTypeList);
        }
        else if (isUpdatingAnnotation === 2) {
            let _annotationStatusList = [...annotationStatusList];
            _annotationStatusList[updatingAnnotationIndex] = customAnnotation;
            cloneUserInfo.annotation_status_list = JSON.stringify(_annotationStatusList);
            setAnnotationStatusList(_annotationStatusList);
        }

        props.setUserInfo(cloneUserInfo);
        updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
            cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
            cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
            cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);

        hideAnnotationEdit();
    }

    function getClassificationTable() {
        let _classificationSettings;
        if (props.userInfo.profile_name === "profile1") {
            _classificationSettings = [...profile1];
        }
        else if (props.userInfo.profile_name === "profile2") {
            _classificationSettings = [...profile2];
        }
        else if (props.userInfo.profile_name === "profile3") {
            _classificationSettings = [...profile3];
        }
        if (_classificationSettings && _classificationSettings.length > 0) {
            const items = _classificationSettings.map((classification, index) => (
                <div key={index} className="detail_expandable_item">
                    <div className={classes.classificationIndex} data-id={classification.index} onClick={showUpdateClassification} >({classification.index})</div>
                    <ClassificationEyeComponent
                        classification={classification}
                    />
                    <div className="classification_color" style={{background: classification.color}} data-id={classification.index} onClick={showUpdateClassification}></div>
                    <div className={classes.classificationName} data-id={classification.index} onClick={showUpdateClassification}>{classification.name}</div>
                </div>
            ));

            return items;
        }
    }

    function getAnnotationTypeList() {
        const items = annotationTypeList.map((annotationType, index) => (
            <div key={index} className="expand_annotation_type">
                <div data-id={index} onClick={showAnnotationTypeChange} >({index + 1}) {annotationType.label}</div>
            </div>
        ));

        return items;
    }

    function getAnnotationStatusList() {
        const items = annotationStatusList.map((annotationStatus, index) => (
            <div key={index} className="expand_annotation_type">
                <div data-id={index} onClick={showAnnotationStatusChange} >({index + 1}) {annotationStatus.label}</div>
            </div>
        ));

        return items;
    }

    function hideClassificationDialog() {
        setUpdatingClassification(false);
        setAddingClassification(false);
        setCustomClassification({ index : -1, visible: true, name: '', color: "#ffffff" });
    }

    function updateClassification() {
        let _customClassification = Object.assign({}, customClassification);
        if (_customClassification.index === "") {
            toast("Classification Index shouldn't be empty");
            return;
        }
        if (isNaN(_customClassification.index)) {
            toast("Classification Index should be number");
            return;
        }
        _customClassification.index = Number(_customClassification.index);
        if (!Number.isInteger(_customClassification.index)
            || _customClassification.index < 0 || _customClassification.index > 255) {
            toast("Classification Index should be positive integer from 0 to 255");
            return;
        }

        if (_customClassification.name === "") {
            toast("Classification Name shouldn't be empty");
            return;
        }

        let _classificationSettings;
        if (props.userInfo.profile_name === "profile1") {
            _classificationSettings = [...profile1];
        }
        else if (props.userInfo.profile_name === "profile2") {
            _classificationSettings = [...profile2];
        }
        else if (props.userInfo.profile_name === "profile3") {
            _classificationSettings = [...profile3];
        }

        for (let i = 0; i < _classificationSettings.length; i ++) {
            if (isAddingClassification && _classificationSettings[i].index === _customClassification.index) {
                toast("The Classification Index exist already");
                return;
            }
            if (_classificationSettings[i].name === _customClassification.name && _classificationSettings[i].index !== _customClassification.index) {
                toast("The Classification Name exist already");
                return;
            }
        }

        if (isUpdatingClassification) {
            for (let i = 0; i < _classificationSettings.length; i ++) {
                if (_classificationSettings[i].index === _customClassification.index) {
                    _classificationSettings[i].name = _customClassification.name;
                    _classificationSettings[i].color = _customClassification.color;
                    break;
                }
            }
            let cloneUserInfo = Object.assign({}, props.userInfo);

            if (props.userInfo.profile_name === "profile1") {
                setProfile1(_classificationSettings);
                cloneUserInfo.profile1_classification = JSON.stringify(_classificationSettings);
            }
            else if (props.userInfo.profile_name === "profile2") {
                setProfile2(_classificationSettings);
                cloneUserInfo.profile2_classification = JSON.stringify(_classificationSettings);
            }
            else if (props.userInfo.profile_name === "profile3") {
                setProfile3(_classificationSettings);
                cloneUserInfo.profile3_classification = JSON.stringify(_classificationSettings);
            }

            props.setUserInfo(cloneUserInfo);
            updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
                cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
                cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
                cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
        }
        else if (isAddingClassification) {
            _classificationSettings.push(_customClassification);

            let cloneUserInfo = Object.assign({}, props.userInfo);

            if (props.userInfo.profile_name === "profile1") {
                setProfile1(_classificationSettings);
                cloneUserInfo.profile1_classification = JSON.stringify(_classificationSettings);
            }
            else if (props.userInfo.profile_name === "profile2") {
                setProfile2(_classificationSettings);
                cloneUserInfo.profile2_classification = JSON.stringify(_classificationSettings);
            }
            else if (props.userInfo.profile_name === "profile3") {
                setProfile3(_classificationSettings);
                cloneUserInfo.profile3_classification = JSON.stringify(_classificationSettings);
            }

            props.setUserInfo(cloneUserInfo);
            updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
                cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
                cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
                cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
        }
        setUpdatingClassification(false);
        setAddingClassification(false);
        setCustomClassification({ index : -1, visible: true, name: '', color: "#ffffff" });
    }

    function isAllClassificationVisible() {
        let _classificationSettings;
        if (props.userInfo.profile_name === "profile1") {
            _classificationSettings = [...profile1];
        }
        else if (props.userInfo.profile_name === "profile2") {
            _classificationSettings = [...profile2];
        }
        else if (props.userInfo.profile_name === "profile3") {
            _classificationSettings = [...profile3];
        }
        for (let i = 0; i < _classificationSettings.length; i ++) {
            if (!_classificationSettings[i].visible) {
                return false;
            }
        }

        return true;
    }

    function setAllClassificationVisible() {
        let visible = false;
        if (!isAllClassificationVisible()) {
            visible = true;
        }
        let _classificationSettings;
        if (props.userInfo.profile_name === "profile1") {
            _classificationSettings = [...profile1];
        }
        else if (props.userInfo.profile_name === "profile2") {
            _classificationSettings = [...profile2];
        }
        else if (props.userInfo.profile_name === "profile3") {
            _classificationSettings = [...profile3];
        }
        for (let i = 0; i < _classificationSettings.length; i ++) {
            _classificationSettings[i].visible = visible;
        }

        let cloneUserInfo = Object.assign({}, props.userInfo);
        if (props.userInfo.profile_name === "profile1") {
            setProfile1(_classificationSettings);
            cloneUserInfo.profile1_classification = JSON.stringify(_classificationSettings);
        }
        else if (props.userInfo.profile_name === "profile2") {
            setProfile2(_classificationSettings);
            cloneUserInfo.profile2_classification = JSON.stringify(_classificationSettings);
        }
        else if (props.userInfo.profile_name === "profile3") {
            setProfile3(_classificationSettings);
            cloneUserInfo.profile3_classification = JSON.stringify(_classificationSettings);
        }

        props.setUserInfo(cloneUserInfo);
        updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
            cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
            cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
            cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
    }

    function showUpdateClassificationCSV() {
        setShowTable(true);
        setTableType(0);
        setCSVRuleData(generateProfileClassifcationCSV());
    }

    function showUpdateAnnotationTypeCSV() {
        setShowTable(true);
        setTableType(1);
        setCSVRuleData(generateAnnotationCSV());
    }

    function generateAnnotationCSV() {
        let data = "";
		data += "Value\tName\n";
		annotationTypeList.forEach(function (annotationType) {
            data += annotationType.value + "\t" + annotationType.label + "\n";
		});
		return data;
    }

    function generateProfileClassifcationCSV() {
        let _classificationSettings;
        if (props.userInfo.profile_name === "profile1") {
            _classificationSettings = [...profile1];
        }
        else if (props.userInfo.profile_name === "profile2") {
            _classificationSettings = [...profile2];
        }
        else if (props.userInfo.profile_name === "profile3") {
            _classificationSettings = [...profile3];
        }

        let data = "";
		data += "Index\tName\tColor\tVisible\n";
		_classificationSettings.forEach(function (_classification) {
            data += _classification.index + "\t" + _classification.name + "\t" + _classification.color + "\t" + (_classification.visible?"1":"0") + "\n";
		});
		return data;
    }

    function hideRuleTable() {
		setShowTable(false);
	}

    function saveCSVRule() {
		if (tableType === 0) {
            let lines = csvRuleData.split("\n");
            let _classificationSettings = [];
            for (let i = 1; i < lines.length; i ++) {
                if (lines[i] === "") continue;
                let datas = lines[i].split("\t");

                if (datas.length !== 4 || isNaN(datas[0])) continue;
                _classificationSettings.push({
                    index :parseInt(datas[0]),
                    visible: datas[3] === "1",
                    name: (datas[1] !== "")?datas[1]:("Feature-" + parseInt(datas[0])),
                    color: (datas[2].indexOf("#") != 0 || datas[2].length != 7)?"#FFFFFF":datas[2],
                });
            }

            let cloneUserInfo = Object.assign({}, props.userInfo);
            if (props.userInfo.profile_name === "profile1") {
                setProfile1(_classificationSettings);
                cloneUserInfo.profile1_classification = JSON.stringify(_classificationSettings);
            }
            else if (props.userInfo.profile_name === "profile2") {
                setProfile2(_classificationSettings);
                cloneUserInfo.profile2_classification = JSON.stringify(_classificationSettings);
            }
            else if (props.userInfo.profile_name === "profile3") {
                setProfile3(_classificationSettings);
                cloneUserInfo.profile3_classification = JSON.stringify(_classificationSettings);
            }

            props.setUserInfo(cloneUserInfo);
            updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
                cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
                cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification, cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
        }
        else if (tableType === 1) {
            let lines = csvRuleData.split("\n");
            let _annotationTypeList = [];
            for (let i = 1; i < lines.length; i ++) {
                if (lines[i] === "") continue;
                let datas = lines[i].split("\t");

                if (datas.length !== 2) continue;
                _annotationTypeList.push({
                    value : datas[0],
                    label: datas[1],
                });
            }

            let cloneUserInfo = Object.assign({}, props.userInfo);
            setAnnotationTypeList(_annotationTypeList);
            cloneUserInfo.annotation_type_list = JSON.stringify(_annotationTypeList);

            props.setUserInfo(cloneUserInfo);
            updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
                cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
                cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification, cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
        }

        hideRuleTable();
	}

    function getTableUpdateName() {
        if (tableType === 0) {
            if (props.userInfo.profile_name === "profile1") {
                return "Update Classification for Profile 1";
            }
            else if (props.userInfo.profile_name === "profile2") {
                return "Update Classification for Profile 2";
            }
            else if (props.userInfo.profile_name === "profile3") {
                return "Update Classification for Profile 3";
            }
        }
        else if (tableType === 1) {
            return "Update Annotation Type";
        }

        return "";
    }

    function setResetProfile() {
        let cloneUserInfo = Object.assign({}, props.userInfo);
        if (props.userInfo.profile_name === "profile1") {
            setProfile1([...props.classificationList]);
            cloneUserInfo.profile1_classification = JSON.stringify(props.classificationList);
        }
        else if (props.userInfo.profile_name === "profile2") {
            setProfile2([...props.classificationList]);
            cloneUserInfo.profile2_classification = JSON.stringify(props.classificationList);
        }
        else if (props.userInfo.profile_name === "profile3") {
            setProfile3([...props.classificationList]);
            cloneUserInfo.profile3_classification = JSON.stringify(props.classificationList);
        }

        props.setUserInfo(cloneUserInfo);
        updateSettings(cloneUserInfo.enable_annotation_popup, cloneUserInfo.measurement_units,
            cloneUserInfo.point_cloud_density, cloneUserInfo.pole_icon, cloneUserInfo.style_color,
            cloneUserInfo.profile_name, cloneUserInfo.profile1_classification, cloneUserInfo.profile2_classification, cloneUserInfo.profile3_classification,
            cloneUserInfo.annotation_type_list, cloneUserInfo.annotation_status_list, cloneUserInfo.measurement_snap_on);
    }

    function addClassificationToProfile() {
        setCustomClassification({ index : 0, visible: true, name: 'Feature', color: "#ffffff" });
        setAddingClassification(true);
    }

    function expandAnnotationType() {
        setExpandedAnnotationType(!expandedAnnotationType);
    }

    function expandAnnotationStatus() {
        setExpandedAnnotationStatus(!expandedAnnotationStatus);
    }

    function expandPoleType() {
        setExpandedPoleType(!expandedPoleType);
    }

	return (
		<div className={classes.root}>

            <div className={classes.panel_title}>
                <div className={classes.panel_label}>Settings</div>
                <BackButton callback={props.callback} />
            </div>


            {pointCloudDensity !== null?
                <div className={classes.info_section}>
                <div className='settings_part'>


                    {/* Point Cloud Density */}
                    <div className='settings_panel_item'>
                        <div className={classes.accordion_title}>
                                <div className='settings_component'>
                                    <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                        setExpandedPointCloud(!expandedPointCloud)
                                    }}>
                                        {!expandedPointCloud?
                                            <div className='icon below_icon'></div>
                                        :
                                            <div className='icon up_icon'></div>
                                        }
                                    </div>
                                </div>
                                <label className='label_settings' onClick={() => {
                                    setExpandedPointCloud(!expandedPointCloud)
                                }}>Point Cloud Density</label>
                        </div>

                        {expandedPointCloud ?
                            <div className='settings_component'>
                                <div className='slider_component'>
                                    <PointCloudSlider
                                        valueLabelDisplay="off"
                                        marks={marks}
                                        value={pointCloudDensity}
                                        valueLabelFormat={valueLabelFormat}
                                        getAriaValueText={valuetext}
                                        step={1}
                                        onChange={handlePointCloudDensity}
                                        onChangeCommitted={pointCloudDensityStop}
                                    />
                                </div>
                                <div className='slider_value'>
                                    {pointCloudDensity}%
                                </div>
                            </div>
                            : null}
                    </div>


                    {/* Profiles */}
                    <div className='settings_panel_item'>
                        <div className={classes.accordion_title}>
                            <div className='settings_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setExpandedProfile(!expandedProfile)
                                }}>
                                    {!expandedProfile?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                            </div>
                            <label className='label_settings' onClick={() => {
                                setExpandedProfile(!expandedProfile)
                            }}>Profiles</label>
                        </div>

                        {expandedProfile ?
                            <div>
                                <div className='settings_component'>
                                    <RadioGroup
                                        name="controlled-radio-buttons-group"
                                        value={profileName}
                                    >
                                        <FormControlLabel onClick={handleProfile1Classification} value="profile1" control={<Radio />} label="Profile1" />
                                        <FormControlLabel onClick={handleProfile2Classification} value="profile2" control={<Radio />} label="Profile2" />
                                        <FormControlLabel onClick={handleProfile3Classification} value="profile3" control={<Radio />} label="Profile3" />
                                    </RadioGroup>
                                </div>
                                <label className={`${classes.label_settings_right} label_settings`} onClick={() => {setShowClassificationTable(!showClassificationTable)}}>

                                    {!showClassificationTable
                                        ? <div className={`${classes.lidar_icon} below_icon`}></div>
                                        : <div className={`${classes.lidar_icon} up_icon`}></div>
                                    }
                                    <span>LiDAR Class Profiles</span>
                                </label>

                                {showClassificationTable?
                                    <div className='settings_classification_menu'>
                                        <div className='settings_classification_button' onClick={setResetProfile}>
                                            <div className='icon reset_icon'></div>
                                            <div className='button_label'>Reset Profile</div>
                                        </div>
                                        <div className='settings_classification_button' onClick={setAllClassificationVisible}>
                                            {isAllClassificationVisible()?
                                            <div className='icon visible_icon'></div>:
                                            <div className='icon invisible_icon'></div>
                                            }
                                            <div className='button_label'>Show/Hide All</div>
                                        </div>
                                        <div className='settings_classification_button' onClick={showUpdateClassificationCSV}>
                                            <div className='icon table_rule_icon_grey'></div>
                                            <div className='button_label'>Rule Table</div>
                                        </div>
                                        {/* <div className='settings_classification_button' onClick={addClassificationToProfile}>
                                            <div className='icon add_class_icon'></div>
                                            <div className='button_label'>Add Classification</div>
                                        </div> */}
                                    </div>
                                    :null}
                                {showClassificationTable?
                                    <div className='settings_classification'>
                                        {getClassificationTable()}
                                    </div>
                                    :null}
                            </div>
                            : null}
                    </div>



                    <div className='settings_panel_item'>
                        <div className={classes.accordion_title}>
                            <div className='settings_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
                                    setExpandedMeasurementOptions(!expandedMeasurementOptions)
                                }}>
                                    {!expandedMeasurementOptions?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                            </div>
                            <label className='label_settings' onClick={() => {
                                setExpandedMeasurementOptions(!expandedMeasurementOptions)
                            }}>Measurement Options</label>
                        </div>

                        <div>
                            {expandedMeasurementOptions?
                                <div className='settings_sub_menu'>
                                    <div className='settings_component'>
                                        <RadioGroup
                                            name="controlled-radio-buttons-group"
                                            value={measureUnit}
                                            onChange={handleMesurementUnits}
                                        >
                                            <FormControlLabel value="imperial" control={<Radio />} label="Imperial" />
                                            <FormControlLabel value="meter" control={<Radio />} label="Meter" />
                                        </RadioGroup>
                                    </div>
                                    <label className={classes.sub_label_settings} onClick={handleMesurementUnits}>Measurement Units</label>
                                </div>
                                :null}
                            {expandedMeasurementOptions?
                                <div className='settings_sub_menu'>
                                    <div className='settings_component'>
                                        <Switch
                                            checked={measurementSnapOn}
                                            onChange={handleMeasurmentSnapOn}
                                        />
                                    </div>
                                    <label className={classes.sub_label_settings} onClick={handleMeasurmentSnapOn}>Measurement Snap On/Off</label>
                                </div>
                                :null}
                        </div>
                    </div>

                    {/* Annotation Popup */}
                    <div className={`${classes.annotation_item} settings_panel_item`}>
                        <div className='settings_component'>
                            <CustomCheckbox checked={enableAnnotationPopup === 1} onChange={handleEnableAnnotationPopup} />
                        </div>
                        <label className='label_settings' onClick={handleEnableAnnotationPopup}>Enable Annotation Popup</label>
                    </div>




                    {/* Pole Color */}
                    <div className='settings_panel_item'>
                        <div className={classes.accordion_title}>
                            <div className='settings_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={expandPoleType}>
                                    {!expandedPoleType?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                            </div>
                            <label onClick={expandPoleType} className='label_settings'>Pole Icon Color</label>
                        </div>

                        {expandedPoleType?
                            <div>
                                <div className='pole_selection_part'>
                                    <div
                                        onClick={showColorPallete}
                                        style={{
                                            backgroundColor: `${props.userInfo.style_color}`,
                                            width: 100,
                                            height: 50,
                                            float: 'left',
                                            border: "2px solid white",
                                        }}
                                    ></div>
                                    {isShowingColor?
                                        <CirclePicker
                                            onChange={(color) => {
                                                changeStyleColor(color.rgb);
                                            }}
                                            color={getRGBColor(props.userInfo.style_color)}
                                        />
                                        :null}
                                </div>
                            </div>
                            : null}
                    </div>


                    {/* Annotation Type */}
                    <div className='settings_panel_item'>

                        <div className={classes.accordion_title}>
                            <div className='settings_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={expandAnnotationType}>
                                    {!expandedAnnotationType?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                            </div>
                            <label className='label_settings' onClick={expandAnnotationType}>Annotation Type</label>
                        </div>

                        {expandedAnnotationType?
                            <div className='settings_classification_menu'>
                                <div className='settings_classification_button' onClick={showUpdateAnnotationTypeCSV}>
                                    <div className='icon table_rule_icon_grey'></div>
                                    <div className='button_label'>Rule Table</div>
                                </div>
                            </div>
                            :null}
                        {expandedAnnotationType?
                            <div className='settings_classification'>
                                {getAnnotationTypeList()}
                            </div>
                            :null}
                    </div>


                    {/* Annotation Status */}
                    <div className='settings_panel_item'>
                        <div className={classes.accordion_title}>
                            <div className='settings_component'>
                                <div className="analytics_icon can_hover_icon no_boder_line" onClick={expandAnnotationStatus}>
                                    {!expandedAnnotationStatus?
                                        <div className='icon below_icon'></div>
                                    :
                                        <div className='icon up_icon'></div>
                                    }
                                </div>
                            </div>
                            <label className='label_settings' onClick={expandAnnotationStatus}>Annotation Status</label>
                        </div>

                        {expandedAnnotationStatus?
                            <div className='settings_classification'>
                                {getAnnotationStatusList()}
                            </div>
                            :null}
                    </div>
                </div>
            </div>
            :null}
            <Modal show={isUpdatingClassification || isAddingClassification} animation={false} className="modal-classification-label">
                <Modal.Header>
                    <Modal.Title>{isUpdatingClassification?"Custom Classification":"Add Classification"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="classification_update_container">
                        <SketchPicker
                            onChange={(color) => {
                                let rgbColorValue = color.rgb;
                                let r = rgbColorValue.r.toString(16);
                                let g = rgbColorValue.g.toString(16);
                                let b = rgbColorValue.b.toString(16);

                                if (r.length === 1)
                                    r = "0" + r;
                                if (g.length === 1)
                                    g = "0" + g;
                                if (b.length === 1)
                                    b = "0" + b;

                                let cssColorValue = "#" + r + g + b;
                                let _customClassification = Object.assign({}, customClassification);
                                _customClassification.color = cssColorValue;
                                setCustomClassification(_customClassification);
                            }}
                            color={getRGBColor(customClassification.color)}
                        />
                        <div className='classification_update_info'>
                            <div className='project-name-box'>
                                <p className="project-name-title">Classification Index</p>
                                {isUpdatingClassification?
                                <input type="text" className="project-name-input" disabled
                                value={customClassification.index === -1?"":(customClassification.index + "")}
                                ></input>
                                :
                                <input type="text" className="project-name-input"
                                value={customClassification.index === -1?"":(customClassification.index + "")}
                                onChange={(event) => {
                                    let _customClassification = Object.assign({}, customClassification);
                                    _customClassification.index = event.target.value;
                                    setCustomClassification(_customClassification);
                                }}></input>
                                }

                            </div>
                            <div className='project-name-box'>
                                <p className="project-name-title">Classification Name</p>
                                <input type="text" className="project-name-input"
                                    value={customClassification.name }
                                    onChange={(event) => {
                                        let _customClassification = Object.assign({}, customClassification);
                                        _customClassification.name = event.target.value;
                                        setCustomClassification(_customClassification);
                                    }}></input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={hideClassificationDialog} className={classes.button}>
                    Close
                </Button>
                <Button variant="contained"
                    color="primary" onClick={updateClassification} className={classes.button}>
                    {isUpdatingClassification?"Update":"Add"}
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isUpdatingAnnotation !== 0} animation={false} className="modal-classification-label">
                <Modal.Header>
                    <Modal.Title>{isUpdatingAnnotation === 1?"Annotation Type":"Annotation Status"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="classification_update_container">
                        <div className='classification_update_info'>
                            <div className='project-name-box'>
                                <p className="project-name-title">{isUpdatingAnnotation === 1?"Annotation Type Name":"Annotation Status Name"}</p>
                                <input type="text" className="project-name-input"
                                    value={customAnnotation.label }
                                    onChange={(event) => {
                                        let _customAnnotation = Object.assign({}, customAnnotation);
                                        _customAnnotation.label = event.target.value;
                                        setCustomAnnotation(_customAnnotation);
                                    }}></input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={hideAnnotationEdit} className={classes.button}>
                    Close
                </Button>
                <Button variant="contained"
                    color="primary" onClick={updateAnnotation} className={classes.button}>
                    Update
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showTable} animation={false} className="rule-dialog">
				<Modal.Header>
					<Modal.Title>
						<div className="dialog_title">
							<div className='logo_title'>
							{getTableUpdateName()}
							</div>
							<div className='close_button' onClick={hideRuleTable}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
                <Modal.Body>
					<div className='selection_type_flex'>
						<textarea className="report-input-class report-input-csv" value={csvRuleData} onChange={(evt) => {
							setCSVRuleData(evt.target.value);
						}} autoComplete="new-password"/>
					</div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={saveCSVRule} className={classes.button}>
                    Update
                </Button>
                </Modal.Footer>
            </Modal>
		</div>
	);
}

const mapStateToProps = state => ({
    userInfo: state.global.userInfo,
    classificationList: state.project.classificationList
});

SettingsPanel.propTypes = {
}
export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(SettingsPanel);
