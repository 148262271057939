import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    back_button: {
        display: 'none',
        backgroundColor: 'transparent',
        border: 0,
        cursor: 'pointer',
        color: '#fff',
        gap: 10,
        alignItems: 'center',
        '@media (max-width: 1000px)': {
            display: 'flex'
        }
    }
}));

export default function BackButton(props) {
  const classes = useStyles();
  const callback = props.callback;

  return (
    <button className={classes.back_button} onClick={e => {callback(e);}}>
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M360-240 120-480l240-240 56 56-144 144h568v80H272l144 144-56 56Z"/></svg>
        <span>Back</span>
    </button>
  );
}