import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { globalAction } from '../../store/actions';
import { projectAction } from '../../store/actions';
import { authActionCreators } from '../../store/actions';
import './style.scss';
import Button from '@material-ui/core/Button';
import * as api from '../../utils/api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Checkbox, Radio, RadioGroup, FormControlLabel} from '@material-ui/core';
import * as mapUtils from '../../components/MapView/MapUtils';
import {Modal} from 'react-bootstrap';
import DatePicker from "react-datepicker";

const CustomCheckbox = withStyles({
	root: {
		color: '#67757c',
		'&$checked': {
			color: '#67757c',
		},
	}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	view: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	inline: {
		display: "inline-block",
	},
	button: {
		fontSize: 15,
		fontWeight: 400,
	},
}));

const selectStyle = {
    control: (base, state) => ({
      ...base,
      background: "#17181b",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "#323232",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "white"
      },
      color: 'white',
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
	multiValue: (provided) => ({
		...provided,
		backgroundColor: '#6a6a6a'
	}),
	multiValueLabel: (provided) => ({
		...provided,
		color: 'white',
	}),
	multiValueRemove: (provided) => ({
		...provided,
		color: 'white',
		':hover': {
			backgroundColor: '#999999',
			color: 'white',
		},
	}),
    input: base => ({
      ...base,
      color: "white"
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused?"#27282b":"#17181b",
      color : "white",
      zIndex: 1,
      textAlign: 'left',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
	  border: "1px solid white"
    })
  };

function Report(props) {
	const classes = useStyles();

	const reportType = props.type;
	const [selectedPointDataSource, setSelectedPointDataSource] = React.useState("");
	const [pointDataSourceList, setPointDataSourceList] = React.useState([]);

	const [selectedKMZDataSource, setSelectedKMZDataSource] = React.useState(null);
	const [kmzDataSourceList, setKMZDataSourceList] = React.useState([]);

	const [selectedKMZDataLabel, setSelectedKMZDataLabel] = React.useState(null);
	const [kmzDataLabelList, setKMZDataLabelList] = React.useState([]);

	const [zoomOption, setZoomOption] = React.useState("1");
	const [zoomOptionList, setZoomOptionList] = React.useState([
		{
			value: "1",
			label: "X1"
		},
		{
			value: "2",
			label: "X2"
		},
		{
			value: "3",
			label: "X3"
		},
		{
			value: "4",
			label: "X4"
		},
		{
			value: "5",
			label: "X5"
		},
	]);

	const [selectedImageSource, setSelectedImageSource] = React.useState("");
	const [imageSourceList, setImageSourceList] = React.useState([]);

	const [selectedClassSource, setSelectedClassSource] = React.useState(null);
	const [classSourceList, setClassSourceList] = React.useState([]);
	const [availableClassSourceList, setAvailableClassSourceList] = React.useState([]);

	const [selectedMeasurementSource, setSelectedMeasurementSource] = React.useState(null);
	const [selectedGroup, setSelectedGroup] = React.useState(-1);

	const [reportName, setReportName] = React.useState("");
	const [reportDescription, setReportDescription] = React.useState("");

	const [placemarkReport, setPlacemarReport] = React.useState(true);
	const [pdfReport, setPDFReport] = React.useState(true);
	const [csvReport, setCSVReport] = React.useState(true);
	const [jpgReportWithOverlay, setJpgReportWithOverlay] = React.useState(true);
	const [jpgReportNoOverlay, setJpgReportNoOverlay] = React.useState(true);

	const [reportRules, setReportRules] = React.useState([]);
	const [reportGroups, setReportGroups] = React.useState([]);
	const [reportGroupExtend, setReportGroupExtend] = React.useState([]);
	const [selectedRules, setSelectedRules] = React.useState([]);
	const [selectedGroupRules, setSelectedGroupRules] = React.useState([]);
	const [isShowingRule, setShowingRule] = React.useState(false);
	const [ruleName, setRuleName] = React.useState("");
	const [redRadius, setRedRadius] = React.useState("");
	const [orangeRadius, setOrangeRadius] = React.useState("");
	const [yellowRadius, setYellowRadius] = React.useState("");
	const [verticalOnly, setVerticalOnly] = React.useState(false);
	const [updateRuleID, setUpdateRuleID] = React.useState(-1);

	const [showGroupDialog, setShowGroupDialog] = React.useState(false);
	const [editGroupObject, setEditGroupObject] = React.useState(null);
	const [groupName, setGroupName] = React.useState("");
	const [showTable, setShowTable] = React.useState(false);
	const [csvRuleData, setCSVRuleData] = React.useState("");

	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(new Date());
	const [filterOption, setFilterOption] = React.useState("annotation");

	const [annotationTypeArray, setAnnotationTypeArray] = React.useState([]);
	const [annotationStatusArray, setAnnotationStatusArray] = React.useState([]);
	const [annotationSeverity, setAnnotationSeverity] = React.useState(["red", "orange", "yellow", "green"]);
	const [imageNaming, setImageNaming] = React.useState("no_change");

	const [annotationTypeList, setAnnotationTypeList] = React.useState([]);
    const [annotationStatusList, setAnnotationStatusList] = React.useState([]);

	const [isExpandedDateFilter, setExpandedDateFilter] = React.useState(false);
	const [isExpandedTypeFilter, setExpandedTypeFilter] = React.useState(false);
	const [isExpandedStatusFilter, setExpandedStatusFilter] = React.useState(false);
	const [isExpandedSeverityFilter, setExpandedSeverityFilter] = React.useState(false);
	const [isExpandedIamgeNamingFilter, setExpandedImageNamingFilter] = React.useState(false);
	const [isExpandedTextFilter, setExpandedTextFilter] = React.useState(false);

	const [imageSearch, setImageSearch] = React.useState("");

	const [sortRule, setSortRule] = React.useState(false);

	const [demColorScale, setDemColorScale] = React.useState("auto"); // auto, manual
	const [demColorScaleList, setDemColorScaleList] = React.useState([
		{
			value: "auto",
			label: "Auto Scale"
		},
		{
			value: "manual",
			label: "Manual Scale"
		}
	]);
	const [demColorDepth, setDemColorDepth] = React.useState("256"); // auto, manual
	const [demColorDepthList, setDemColorDepthList] = React.useState([
		{
			value: "256",
			label: "256 Colors"
		},
		{
			value: "64",
			label: "64 Colors"
		},
		{
			value: "16",
			label: "16 Colors"
		},
		{
			value: "4",
			label: "4 Colors"
		}
	]);
	const [minScale, setMinScale] = React.useState(""); // auto, manual
	const [maxScale, setMaxScale] = React.useState(""); // auto, manual

	const [annotationSpacing, setAnnotationSpacing] = React.useState(100); // auto, manual
	const [annotationSpacingList, setAnnotationSpacingList] = React.useState([
		{
			value: 100,
			label: "100 ft"
		},
		{
			value: 200,
			label: "200 ft"
		},
		{
			value: 300,
			label: "300 ft"
		},
		{
			value: 500,
			label: "500 ft"
		},
		{
			value: 1000,
			label: "1000 ft"
		},
		{
			value: -1,
			label: "Per Pole"
		}
	]);

	const [checkedAll, setTypeCheckAll] = React.useState(true);

	const [selectedRuleConstraints, setselectedRuleConstraints] = React.useState(0);
	const [ruleConstraintsList, setRuleConstraintsList] = React.useState([
		{
			value: 0,
			label: "Radial Measurements"
		},
		{
			value: 1,
			label: "Vertical measurements"
		},
		{
			value: 2,
			label: "Horizontal measurements"
		}
		,
		{
			value: 3,
			label: "Span measurements"
		}
	]);

	const [selectedRuleFunction, setselectedRuleFunction] = React.useState(0);
	const [ruleFunctionList, setRuleFunctionList] = React.useState([
		{
			value: 0,
			label: "Less than"
		},
		{
			value: 1,
			label: "Greater than"
		},
		{
			value: 2,
			label: "Statistics Per Pole"
		}
	]);



	function onReportNameChange(evt) {
		setReportName(evt.target.value);
	}

	function onReportDescriptionChange(evt) {
		setReportDescription(evt.target.value);
	}

	function redirectToLogin() {
        props.history.replace("/");
    }

	function getProfileClassification() {
        let _classificationList = JSON.parse(JSON.stringify(props.classificationList));
        if (props.userInfo.profile_name === "profile1" && props.userInfo.profile1_classification && props.userInfo.profile1_classification !== "") {
            _classificationList = JSON.parse(props.userInfo.profile1_classification);
        }
        else if (props.userInfo.profile_name === "profile2" && props.userInfo.profile2_classification && props.userInfo.profile2_classification !== "") {
            _classificationList = JSON.parse(props.userInfo.profile2_classification);
        }
        else if (props.userInfo.profile_name === "profile3" && props.userInfo.profile3_classification && props.userInfo.profile3_classification !== "") {
            _classificationList = JSON.parse(props.userInfo.profile3_classification);
        }

        return _classificationList;
    }

	async function addToProject(datasetID) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.addDataset({token: userToken, id: props.selectedProject.project.id, dataset_id: datasetID + ""});

            if (response.data && !response.data.error) {
                props.selectProject(response.data);
            }
        }
        else {
            redirectToLogin();
        }
    }

	async function startLidarGenerateReport(datasetID) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            await api.startLidarReport({token: userToken, id: datasetID + "", project_id: props.selectedProject.project.id + ""});
			addToProject(datasetID);
			toast("Report started successfully. Please check projects to check progress.");
			if (props.showProjectPanel) {
				props.showProjectPanel();
			}
        }
        else {
            redirectToLogin();
        }
    }

	async function startLidarDem(datasetID) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            await api.startLidarDem({token: userToken, id: datasetID + "", project_id: props.selectedProject.project.id + ""});
			addToProject(datasetID);
			toast("Report started successfully. Please check projects to check progress.");
			if (props.showProjectPanel) {
				props.showProjectPanel();
			}
        }
        else {
            redirectToLogin();
        }
    }

	async function startImageGenerateReport(datasetID) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            await api.startImageReport({token: userToken, id: datasetID + "", project_id: props.selectedProject.project.id + ""});
			addToProject(datasetID);
			toast("Report started successfully. Please check projects to check progress.");
			if (props.showProjectPanel) {
				props.showProjectPanel();
			}
        }
        else {
            redirectToLogin();
        }
    }

	async function startMapGenerateReport(datasetID) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            await api.startMapReport({token: userToken, id: datasetID + "", project_id: props.selectedProject.project.id + ""});
			addToProject(datasetID);
			toast("Report started successfully. Please check projects to check progress.");
			if (props.showProjectPanel) {
				props.showProjectPanel();
			}
        }
        else {
            redirectToLogin();
        }
    }

	async function generateLidarReport(selectedRulesList) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.createLidarNewReport({
				token: userToken,
				name: reportName,
				description: reportDescription,
				type: reportType,
				cloud_source_id: selectedPointDataSource,
				kmz_source_id: (selectedKMZDataSource !== null)?selectedKMZDataSource.item.id:"",
				kmz_label: (selectedKMZDataLabel !== null)?selectedKMZDataLabel:"none",
				rule: selectedRulesList.join(','),
				annotation_spacing: annotationSpacing,
				start_date: (startDate)?Math.floor(startDate.getTime() / 1000):"",
				end_date: (endDate)?Math.floor(endDate.getTime() / 1000):"",
			});

            if (response.data && !response.data.error) {
				startLidarGenerateReport(response.data.id);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

	async function generateLidarDem() {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.createLidarDem({
				token: userToken,
				name: reportName,
				description: reportDescription,
				type: reportType,
				cloud_source_id: selectedPointDataSource,
				demColorScale: demColorScale,
				demColorDepth: demColorDepth,
				minScale: minScale,
				maxScale: maxScale,
			});

            if (response.data && !response.data.error) {
				startLidarDem(response.data.id);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

	async function generateImageReport() {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.createImageReport({
				token: userToken,
				name: reportName,
				description: reportDescription,
				type: reportType,
				image_source_id: selectedImageSource,
				kmz_source_id: (selectedKMZDataSource !== null)?selectedKMZDataSource.item.id:"",
				kmz_label: (selectedKMZDataLabel !== null)?selectedKMZDataLabel:"none",
				image_naming: imageNaming,
				annotation_type_filter: JSON.stringify(annotationTypeArray),
				annotation_severity_filter: JSON.stringify(annotationSeverity),
				annotation_status_filter: JSON.stringify(annotationStatusArray),
				text_filter: imageSearch,
				start_date: (startDate)?Math.floor(startDate.getTime() / 1000):"",
				end_date: (endDate)?Math.floor(endDate.getTime() / 1000):"",
				filter_option: filterOption,
				placemarkReport: placemarkReport,
				csvReport: csvReport,
				pdfReport: pdfReport,
				jpgReportWithOverlay: jpgReportWithOverlay,
				jpgReportWithNoOverlay: jpgReportNoOverlay,
				zoom_option: zoomOption,
			});

            if (response.data && !response.data.error) {
				startImageGenerateReport(response.data.id);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

	async function generateMapReport() {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.createMapReport({
				token: userToken,
				name: reportName,
				description: reportDescription,
				type: reportType,
				kmz_source_id: (selectedKMZDataSource !== null)?selectedKMZDataSource.item.id:"",
				kmz_label: (selectedKMZDataLabel !== null)?selectedKMZDataLabel:"none",
				annotation_type_filter: JSON.stringify(annotationTypeArray),
				annotation_severity_filter: JSON.stringify(annotationSeverity),
				annotation_status_filter: JSON.stringify(annotationStatusArray),
				text_filter: imageSearch,
				start_date: (startDate)?Math.floor(startDate.getTime() / 1000):"",
				end_date: (endDate)?Math.floor(endDate.getTime() / 1000):"",
			});

            if (response.data && !response.data.error) {
				startMapGenerateReport(response.data.id);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

	async function refreshDataset(callback) {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.getDatasetList({token: userToken});

            if (response.data && !response.data.error) {
                props.setAllDatasets(response.data);
				if (callback) {
					callback();
				}
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

	async function getProjectItem(projectID) {
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
			let response = await api.getProjectByID({id :projectID, token: userToken});
			if (response.data && !response.data.error) {
				props.selectProject(response.data);
			}
			else {
				redirectToLogin();
			}
		}
		else {
			redirectToLogin();
		}
	}

	async function startImageGrouper() {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.startImageGrouper({
				token: userToken,
				image_source_id: selectedImageSource,
				kmz_source_id: (selectedKMZDataSource !== null)?selectedKMZDataSource.item.id:"",
				kmz_label: (selectedKMZDataLabel !== null)?selectedKMZDataLabel:"none",
			});

            if (response.data && !response.data.error) {
                toast("Start Image Grouper Succeed");
				refreshDataset(function() {
					getProjectItem(props.selectedProject.project.id);
				})
            }
            else if (response.data) {
                toast(response.data.error);
            }
        }
        else {
            redirectToLogin();
        }
    }

	function generateReport() {
		if (reportType === "lidar") {
			if (selectedPointDataSource === "") {
				toast("Please Select Point Datasource");
				return;
			}
			else if (selectedRules.length === 0) {
				toast("Please select rule");
				return;
			}
			else if (reportName === "") {
				toast("Input Report Name");
				return;
			}
			else if (annotationSpacing === -1 && selectedKMZDataSource === null) {
				toast("Please select KMZ data for Per Pole option");
				return;
			}
			else {
				let _rulesSelected = [];
				for (let i = 0; i < selectedRules.length; i ++) {
					let filteredRules = reportRules.filter(function(ruleValue) {
						return ruleValue.id + "" === selectedRules[i] + "";
					});

					if (filteredRules.length != 0 && isAvailableRules(filteredRules[0])) {
						_rulesSelected.push(selectedRules[i]);
					}
				}
				if (_rulesSelected.length == 0) {
					toast("There are no available rules you selected");
					return;
				}
				generateLidarReport(_rulesSelected);
			}
		}
		else if (reportType === "lidar_dem") {
			if (selectedPointDataSource === "") {
				toast("Please Select Point Datasource");
				return;
			}
			else if (reportName === "") {
				toast("Input Report Name");
				return;
			}
			else if (demColorScale === "manual"  && (minScale === "" || maxScale === ""
			|| isNaN(parseFloat(minScale)) || isNaN(parseFloat(maxScale)) || parseFloat(maxScale) <= parseFloat(minScale))) {
				toast("Please enter the correct min/max Scale");
				return;
			}
			else {
				generateLidarDem();
			}
		}
		else if (reportType === "image") {
			if (selectedImageSource === null || selectedImageSource === "") {
				toast("Please Select Image Datasource");
				return;
			}
			else if (reportName === "") {
				toast("Input Report Name");
				return;
			}
			else if (annotationTypeArray.length === 0) {
				toast("Please Select Annotation Type for filter");
				return;
			}
			else if (annotationSeverity.length === 0) {
				toast("Please Select Annotation Severity for filter");
				return;
			}
			else if (annotationStatusArray.length === 0) {
				toast("Please Select Annotation Status for filter");
				return;
			}
			else {
				generateImageReport();
			}
		}
		else if (reportType === "image_grouper") {
			if (selectedImageSource === null || selectedImageSource === "") {
				toast("Please Select Image Datasource");
				return;
			}
			else if (selectedKMZDataSource === null) {
				toast("Please Select KMZ Source");
				return;
			}
			else if (selectedKMZDataLabel === null) {
				toast("Please Select KMZ Label");
				return;
			}
			else {
				startImageGrouper();
			}
		}
		else if (reportType === "map") {
			if (reportName === "") {
				toast("Input Report Name");
				return;
			}
			else if (annotationTypeArray.length === 0) {
				toast("Please Select Annotation Type for filter");
				return;
			}
			else if (annotationSeverity.length === 0) {
				toast("Please Select Annotation Severity for filter");
				return;
			}
			else if (annotationStatusArray.length === 0) {
				toast("Please Select Annotation Status for filter");
				return;
			}
			else {
				generateMapReport();
			}
		}
		else {
			toast("Not yet Supported");
		}

		props.onClose();
	}

	function getReportType() {
		if (reportType === "image") {
			return "Image Report";
		}
		else if (reportType === "lidar") {
			return "Lidar Report";
		}
		else if (reportType === "image_grouper") {
			return "Image Grouper";
		}
		else if (reportType === "map") {
			return "Map Report";
		}
		else if (reportType === "lidar_dem") {
			return "Lidar to Dem";
		}
		else return ""
	}

	function selectKMZDataSource(event) {
		if (!event) {
			setSelectedKMZDataSource(null);
			setKMZDataLabelList([]);
			setSelectedKMZDataLabel(null);
			return;
		}
		setSelectedKMZDataSource(event.value)

		let dataset = event.value;
		if (dataset) {
			let labelProperties = ["none", "default"];
			let arrayProperties = [
				{
					value: "none",
					label: "None"
				},
				{
					value: "default",
					label: "Default"
				}
			];
			for (let i = 0; i < dataset.files.length; i ++) {
				let item = window.loadedObjects.get(dataset.item.datatype + "_" + dataset.files[i].id);
				if (item && item.object && item.object.entities) {
					let entities = item.object.entities.values;
					for (let j = 0; j < entities.length; j ++) {
						if (entities[j].description) {
							let properties = mapUtils.parseDescription(entities[j].description._value);
							for (let prIndex = 0; prIndex < properties.length; prIndex ++) {
								if (labelProperties.indexOf(properties[prIndex]) >= 0) {
									continue;
								}
								labelProperties.push(properties[prIndex]);
								arrayProperties.push({
									value : properties[prIndex],
									label: properties[prIndex].toUpperCase(),
								});
							}
						}
					}
				}
			}
			setKMZDataLabelList(arrayProperties);
			setSelectedKMZDataLabel(labelProperties[0]);
		}
	}

	function selectKMZDataLabel(event) {
		if (event) {
			setSelectedKMZDataLabel(event.value)
		}
	}

	function selectClassSource(event) {
		if (event) {
			setSelectedClassSource(event.value);
		}
		else {
			setSelectedClassSource(null);
		}
	}

	function selectMeasurementSource(event) {
		if (event) {
			setSelectedMeasurementSource(event.value);
		}
		else {
			setSelectedMeasurementSource(null);
		}
	}

	function selectRuleConstraints(event) {
		if (event) {
			setselectedRuleConstraints(event.value);
		}
		else {
			setselectedRuleConstraints(null);
		}
	}

	function selectRuleFunction(event) {
		if (event) {
			setselectedRuleFunction(event.value);
		}
		else {
			setselectedRuleFunction(null);
		}
	}

	function selectGroup(event) {
		setSelectedGroup(event.value);
	}

	function getRuleGroupsSelectOptions() {
		const items = [];
		items.push({
			label: "None",
			value: -1
		})
		for (let i = 0; i < reportGroups.length; i ++) {
			items.push({
				label: reportGroups[i].name,
				value: reportGroups[i].id,
			})
		}

		return items;
	}

	function selectPointCloudSource(event) {
		if (!event) {
			setSelectedPointDataSource("");
			generateMatchCloudSources(null);
			generateClassSourceList("");
		}
		else {
			if (event.length) { // This is for Multi
				let result = "";
				for (let i = 0; i < event.length; i ++) {
					if (i !== 0) result += ", "
					result += event[i].value.item.id;
				}

				if (result === "") {
					generateMatchCloudSources(null);
				}
				else if (event.length === 1) {
					generateMatchCloudSources(event[0].value);
				}

				setSelectedPointDataSource(result);
				generateClassSourceList(result);
			}
			else if (event.value) {
				setSelectedPointDataSource(event.value.item.id + "");
			}
			else {
				setSelectedPointDataSource("");
				generateMatchCloudSources(null);
				generateClassSourceList("");
			}
		}
	}

	function selectDemColorScale(event) {
		if (event) {
			setDemColorScale(event.value)
		}
	}

	function selectDemColorDepth(event) {
		if (event) {
			setDemColorDepth(event.value)
		}
	}

	function selectAnnotationSpacing(event) {
		if (event) {
			setAnnotationSpacing(event.value)
		}
	}

	function selectImageResource(event) {
		if (!event) setSelectedImageSource("");
		else {
			let result = "";
			for (let i = 0; i < event.length; i ++) {
				if (i !== 0) result += ", "
				result += event[i].value.item.id;
			}

			setSelectedImageSource(result)
		}
	}

	function selectAnnotationType(event) {
		let cloneFilterArrays = annotationTypeArray.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;

		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}
		setAnnotationTypeArray(cloneFilterArrays)
	}

	function changeFilterStatus(event) {
		let cloneFilterArrays = annotationStatusArray.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}

		setAnnotationStatusArray(cloneFilterArrays);
	}

	function selectAnnotationSeverity(event) {
		let cloneFilterArrays = annotationSeverity.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;

		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}
		setAnnotationSeverity(cloneFilterArrays)
	}

	function selectImageNaming(event, value) {
		setImageNaming(value);
	}

	function selectFilterOption(event, value) {
		setFilterOption(value);
	}

	function handlePlaceMarkReport() {
		setPlacemarReport(!placemarkReport)
	}

	function handleCSVReport() {
		setCSVReport(!csvReport)
	}

	function handlePDFReport() {
		setPDFReport(!pdfReport)
	}

	function handleJpgReportWithOverly() {
		setJpgReportWithOverlay(!jpgReportWithOverlay)
	}

	function handleJpgReportNoOverlay() {
		setJpgReportNoOverlay(!jpgReportNoOverlay)
	}

	useEffect(() => {
		if (reportType === "lidar") {
			getReportRuleList();
		}
	}, [reportType]);

	useEffect(() => {
		if (reportType === "lidar") {
			updateSelectRules();
		}
	}, [reportRules, availableClassSourceList]);

	useEffect(() => {
		let annotationTypeArrays = [];
		let annotationStatusArrays = [];
        if (props.userInfo.annotation_type_list === "" || !props.userInfo.annotation_type_list) {
            annotationTypeArrays = mapUtils.generateAnnotationTypeList();
        }
        else {
			annotationTypeArrays = JSON.parse(props.userInfo.annotation_type_list);

        }
		setAnnotationTypeList(annotationTypeArrays);
		setAnnotationTypeArray(annotationTypeArrays.map((annotationType) => annotationType.value));

        if (props.userInfo.annotation_status_list === "" || !props.userInfo.annotation_status_list) {
            annotationStatusArrays = mapUtils.generateAnnotationStatusList();
        }
        else {
			annotationStatusArrays = JSON.parse(props.userInfo.annotation_status_list);

        }

		setAnnotationStatusList(annotationStatusArrays);
		setAnnotationStatusArray(annotationStatusArrays.map((annotationStatus) => annotationStatus.value));
	}, [props.userInfo]);

	async function getReportRuleList() {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			if (reportType === "lidar") {
				let response = await api.getReportRuleList({token: userToken});

				if (response.data && !response.data.error) {
					if (response.data.fail_message) {
						toast(response.data.fail_message);
					}
					else {
						setReportRules(response.data.rules);
						updateGroupRulesFromAPI(response.data.groups);
					}
				}
			}
        }
        else {
            redirectToLogin();
        }
	}

	useEffect(() => {
		refreshDataList();
	}, [props.datasetList]);

	function refreshDataList() {
		let kmzSourceList = [];
		let imageSourceList = [];

		props.datasetList.forEach(function(dataset) {
			if (dataset.item.datatype === "kml" && dataset.item.status === "finish") {
				kmzSourceList.push({
					value : dataset,
					label: dataset.item.name,
				});
			}
			if (dataset.item.datatype === "img" && dataset.item.status === "finish") {
				imageSourceList.push({
					value : dataset,
					label: dataset.item.name,
				});
			}
		});
		if (reportType === "lidar") {
			generateMatchCloudSources(null);
			setKMZDataSourceList(kmzSourceList);
		}
		else if (reportType === "lidar_dem") {
			generateMatchCloudSources(null);
			setKMZDataSourceList(kmzSourceList);
		}
		else if (reportType === "map") {
			setKMZDataSourceList(kmzSourceList);
		}
		else if (reportType === "image" || reportType === "image_grouper") {
			setKMZDataSourceList(kmzSourceList);
			setImageSourceList(imageSourceList);
		}

		let classificationSourceList = [];
		let profileClassification = getProfileClassification();
		for (let i = 0; i < profileClassification.length; i ++) {
			classificationSourceList.push({
				value: profileClassification[i].index,
				label: ((profileClassification[i].index < 10)?"0":"") + profileClassification[i].index + " - " + profileClassification[i].name,
			});
		}
		setClassSourceList(classificationSourceList);
	}

	function generateClassSourceList(datasetIds) {
		datasetIds = datasetIds.split(",").map(function(value) {
			return value.trim();
		})
		let classificationList = [];
		props.datasetList.forEach(function(dataset) {
			if (dataset.item.datatype === "pointcloud" && dataset.item.status === "finish" && datasetIds.indexOf(dataset.item.id + "") >= 0) {
				if (!dataset.item.lasinfo || dataset.item.lasinfo === "") return;
				classificationList = classificationList.concat(JSON.parse(dataset.item.lasinfo)["classification"]);
			}
		});
		let availableList = [];
		let profileClassification = getProfileClassification();
		for (let i = 0; i < profileClassification.length; i ++) {
			if (classificationList.indexOf(profileClassification[i].index) >= 0) {
				availableList.push({
					value: profileClassification[i].index,
					label: ((profileClassification[i].index < 10)?"0":"") + profileClassification[i].index + " - " + profileClassification[i].name,
				});
			}
		}
		setAvailableClassSourceList(availableList);
		setSelectedGroupRules([]);
		setSelectedRules([]);
	}

	function generateMatchCloudSources(selectedSource) {
		let cloudSourceList = [];
		props.datasetList.forEach(function(dataset) {
			if (dataset.item.datatype === "pointcloud" && dataset.item.status === "finish") {
				if (selectedSource != null) {
					if (dataset.item.epsg_code !== selectedSource.item.epsg_code) return;
					if (dataset.item.elevation_unit !== selectedSource.item.elevation_unit) return;
				}
				cloudSourceList.push({
					value : dataset,
					label: dataset.item.name,
				});
			}
		});
		setPointDataSourceList(cloudSourceList);
	}

	function addGroup() {
		if (reportType === "lidar") {
			setEditGroupObject(null);
			setGroupName("");
			setShowGroupDialog(true);
		}
	}

	function hideGroupDialog() {
		setShowGroupDialog(false);
	}

	function sortRules() {
		setSortRule(!sortRule);
	}

	function showRuleTable() {
		setShowTable(true);
		setCSVRuleData(generateRuleCSVData());
	}

	function hideRuleTable() {
		setShowTable(false);
	}

	function generateRuleCSVData() {
		let data = "";
		data += "GROUP\tRule\tReference\tMeasurement\tConstraints\tFunction\tYellow(ft)\tOrange(ft)\tRed(ft)\tVertical Only\n";
		reportGroups.forEach(function (ruleGroup) {
			let filteredReportRules = reportRules.filter(function (rule) { return rule.group_id === ruleGroup.id })
			if (filteredReportRules.length > 0) {
				filteredReportRules.forEach(function(rule) {
					data += ruleGroup.name + "\t" + rule.name + "\t" + rule.point_cloud_reference + "\t" + rule.point_cloud_measurement + "\t" + rule.rule_constraints + "\t" + rule.rule_function+
					"\t" + rule.yellow + "\t" + rule.orange + "\t" + rule.red + "\t" + (rule.vertical_measurements_only?"1":"0") + "\n";
				});
			}
			else {
				data += ruleGroup.name + "\n";
			}
		});
		reportRules.filter(function (rule) { return rule.group_id === -1 }).forEach(function(rule) {
			data += "None\t" + rule.name + "\t" + rule.point_cloud_reference + "\t" + rule.point_cloud_measurement + "\t" + rule.rule_constraints + "\t" + rule.rule_function +
			"\t" + rule.yellow + "\t" + rule.orange + "\t" + rule.red + "\t" + (rule.vertical_measurements_only?"1":"0") + "\n";
		});

		return data;
	}

	function addRules() {
		if (reportType === "lidar") {
			setShowingRule(true);
			setRuleName("");
			setRedRadius("");
			setYellowRadius("");
			setOrangeRadius("");
			setVerticalOnly(false);
			setSelectedClassSource(null);
			setSelectedMeasurementSource(null);
			setselectedRuleConstraints(null);
			setselectedRuleFunction(null);
			setSelectedGroup(-1);
			setUpdateRuleID(-1);
		}
	}

	function hideRuleDialog() {
		if (reportType === "lidar") {
			setShowingRule(false);
			setRuleName("");
			setRedRadius("");
			setYellowRadius("");
			setOrangeRadius("");
			setVerticalOnly(false);
			setSelectedClassSource(null);
			setSelectedMeasurementSource(null);
			setselectedRuleConstraints(null);
			setselectedRuleFunction(null);
			setSelectedGroup(-1);
			setUpdateRuleID(-1);
		}
	}

	function onChangeRuleName(evt) {
		setRuleName(evt.target.value);
	}

	function onRedRadiusChange(evt) {
		setRedRadius(evt.target.value);
	}

	function onOrangeRadius(evt) {
		setOrangeRadius(evt.target.value);
	}

	function onYellowRadiusChange(evt) {
		setYellowRadius(evt.target.value);
	}

	function handleVerticalOnly() {
		setVerticalOnly(!verticalOnly);
	}

	function onMinScaleChange(evt) {
		setMinScale(evt.target.value);
	}

	function onMaxScaleChange(evt) {
		setMaxScale(evt.target.value);
	}

	async function saveRule() {
		if (ruleName === "") {
			toast("Please input rule name");
			return;
		}
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
			if (reportType === "lidar") {
				if (selectedClassSource === null) {
					toast("Please Select Reference Class");
					return;
				}
				else if (selectedMeasurementSource === null) {
					toast("Please Select Measurement Class");
					return;
				}
				// While admin only testing, disable the validation for these new rules
				// else if (selectedRuleConstraints === null) {
				// 	toast("Please Select Rule Constraints");
				// 	return;
				// }
				// else if (selectedRuleFunction === null) {
				// 	toast("Please Select Rule Function");
				// 	return;
				// }
				else if (redRadius === "" || isNaN(redRadius)) {
					toast("Input Red Radius correctly");
					return;
				}
				else if (orangeRadius === "" || isNaN(orangeRadius)) {
					toast("Input Orange Radius correctly");
					return;
				}
				else if (yellowRadius === "" || isNaN(yellowRadius)) {
					toast("Input Yellow Radius correctly");
					return;
				}

				/* The "Vertical Measurements Only" checkbox has been removed and it's functionaly is now handled by the "Rule Contraints" select box. To keep compatibilty with backend logic, when the "Vertical measurements" option (value 1) is selected, set the vertical_measurements_only parameter to true. */
				var vertical_measurements_only = false;
				if(selectedRuleConstraints == 1)
				{
					vertical_measurements_only = true;
				}


				if (updateRuleID !== -1) {
					let response = await api.updateReportRule({
						token: userToken,
						id:updateRuleID,
						name:ruleName,
						point_cloud_reference:selectedClassSource,
						point_cloud_measurement: selectedMeasurementSource,
						rule_constraints: selectedRuleConstraints,
						rule_function: selectedRuleFunction,
						red: redRadius,
						orange:orangeRadius,
						yellow:yellowRadius,
						vertical_measurements_only:vertical_measurements_only,
						group_id:selectedGroup
					});

					if (response.data && !response.data.error) {
						if (response.data.fail_message) {
							toast(response.data.fail_message);
						}
						else {
							let cloneRules = JSON.parse(JSON.stringify(reportRules));
							for (let i = 0; i < cloneRules.length; i ++) {
								if (cloneRules[i].id + "" === updateRuleID + "") {
									cloneRules[i] = response.data;
									break;
								}
							}
							setReportRules(cloneRules);
							hideRuleDialog();
						}
					}
					else {
						toast("Please reload and retry");
					}
				}
				else {
					let response = await api.createReportRule({
						token: userToken,
						name:ruleName,
						point_cloud_reference:selectedClassSource,
						point_cloud_measurement: selectedMeasurementSource,
						rule_constraints: selectedRuleConstraints,
						rule_function: selectedRuleFunction,
						red: redRadius,
						orange:orangeRadius,
						yellow:yellowRadius,
						vertical_measurements_only:vertical_measurements_only,
						group_id:selectedGroup
					});

					if (response.data && !response.data.error) {
						if (response.data.fail_message) {
							toast(response.data.fail_message);
						}
						else {
							let cloneRules = JSON.parse(JSON.stringify(reportRules));
							cloneRules.push(response.data);
							setReportRules(cloneRules);
							hideRuleDialog();
						}
					}
					else {
						toast("Please reload and retry");
					}
				}
			}
		}
		else {
			redirectToLogin();
		}
	}

	async function saveGroup() {
		if (groupName === "") {
			toast("Please input group name");
			return;
		}
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
			if (reportType === "lidar") {
				if (editGroupObject) {
					let response = await api.updateRuleGroup({ token: userToken, id:editGroupObject.id, name:groupName });

					if (response.data && !response.data.error) {
						if (response.data.fail_message) {
							toast(response.data.fail_message);
						}
						else {
							let cloneRules = JSON.parse(JSON.stringify(reportGroups));
							for (let i = 0; i < cloneRules.length; i ++) {
								if (cloneRules[i].id + "" === editGroupObject.id + "") {
									cloneRules[i] = response.data;
									break;
								}
							}
							setReportGroups(cloneRules);
							hideGroupDialog();
						}
					}
					else {
						toast("Please reload and retry");
					}
				}
				else {
					let response = await api.createRuleGroup({ token: userToken, name:groupName });

					if (response.data && !response.data.error) {
						if (response.data.fail_message) {
							toast(response.data.fail_message);
						}
						else {
							let cloneRules = JSON.parse(JSON.stringify(reportGroups));
							cloneRules.push(response.data);
							setReportGroups(cloneRules);
							updateGroupRulesFromAPI(cloneRules)
							hideGroupDialog();
						}
					}
					else {
						toast("Please reload and retry");
					}
				}
			}
		}
		else {
			redirectToLogin();
		}
	}

	function availableCSVRuleData(data) {
		let lineData = data.split("\n");
		for (let i = 0; i < lineData.length; i ++) {
			if (lineData[i] === "") continue;
			let eachData = lineData[i].split("\t");
			if (eachData.length == 1 && eachData[0] != "") continue;
			if (eachData.length != 10 || isNaN(parseInt(eachData[2])) || parseInt(eachData[2]) < 0 || isNaN(parseInt(eachData[3])) || parseInt(eachData[3]) < 0
			|| isNaN(parseFloat(eachData[4])) || isNaN(parseFloat(eachData[5])) || isNaN(parseFloat(eachData[6])) || isNaN(parseFloat(eachData[7])) || isNaN(parseFloat(eachData[8])) || (eachData[9] != "0" && eachData[9] != "1")) {
				return false;
			}
		}
		return true;
	}

	async function saveCSVRule() {
		let data = csvRuleData.substring(csvRuleData.indexOf("\n") + 1)
		if (!availableCSVRuleData(data)) {
			toast("Your input rule table is invalid, Please check");
			return;
		}
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
			if (reportType === "lidar") {
				let response = await api.updateCSVRuleData({ token: userToken, data:data });

				if (response.data && !response.data.error) {
					if (response.data.fail_message) {
						toast(response.data.fail_message);
					}
					else {
						setReportRules(response.data.rules);
						updateGroupRulesFromAPI(response.data.groups);
						hideRuleTable();
					}
				}
				else {
					toast("Please reload and retry");
				}
			}
		}
		else {
			redirectToLogin();
		}
	}

	function isAvailableRules(rule) {
		return availableClassSourceList.filter(function (option) {
			return option.value === rule.point_cloud_reference;
		}).length > 0
		&& availableClassSourceList.filter(function (option) {
			return option.value === rule.point_cloud_measurement;
		}).length > 0;
	}

	function isAvailableGroupRules(rules, groupID) {
		let groupRules = rules.filter(function (rule) { return rule.group_id === groupID });

		if (groupRules.length === 0) return false;

		for (let i = 0; i < groupRules.length; i ++) {
			if (!isAvailableRules(groupRules[i])) return false;
		}

		return true;
	}

	function updateSelectRules() {
		let updateRuleSelect = [];
		for (let i = 0; i < selectedRules.length; i ++) {
			if (reportType === "lidar") {
				let filteredRules = reportRules.filter(function(ruleValue) {
					return ruleValue.id + "" === selectedRules[i] + "";
				});

				if (filteredRules.length != 0) {
					updateRuleSelect.push(selectedRules[i]);
				}
			}
		}

		setSelectedRules(updateRuleSelect);
	}

	function addSelectedRule(ruleID) {
		let updateRuleSelect = [];
		let added = false;
		for (let i = 0; i < selectedRules.length; i ++) {
			if (parseInt(selectedRules[i]) > parseInt(ruleID) && !added) {
				added = true;
				updateRuleSelect.push(ruleID);
			}
			updateRuleSelect.push(selectedRules[i]);
		}

		if (!added) {
			added = true;
			updateRuleSelect.push(ruleID);
		}

		setSelectedRules(updateRuleSelect);
	}

	function deleteSelectedRule(ruleID) {
		let updateRuleSelect = [];
		for (let i = 0; i < selectedRules.length; i ++) {
			if (selectedRules[i] + "" === ruleID + "") {
				continue;
			}
			updateRuleSelect.push(selectedRules[i]);
		}

		setSelectedRules(updateRuleSelect);
	}

	function handleSelectRule(event) {
		if (event.currentTarget && event.currentTarget.dataset.id) {
			if (selectedRules.indexOf(event.currentTarget.dataset.id + "") >= 0) {
				deleteSelectedRule(event.currentTarget.dataset.id + "");
			}
			else {
				addSelectedRule(event.currentTarget.dataset.id + "")
			}

		}
		else if (event.currentTarget && event.currentTarget.parentNode && event.currentTarget.parentNode.parentNode && event.currentTarget.parentNode.parentNode.dataset.id) {
			if (selectedRules.indexOf(event.currentTarget.parentNode.parentNode.dataset.id + "") >= 0) {
				deleteSelectedRule(event.currentTarget.parentNode.parentNode.dataset.id + "");
			}
			else {
				addSelectedRule(event.currentTarget.parentNode.parentNode.dataset.id + "");
			}

		}
	}

	function addSelectedRuleGroup(ruleID) {
		let updateRuleSelect = [];
		let added = false;
		for (let i = 0; i < selectedGroupRules.length; i ++) {
			if (parseInt(selectedGroupRules[i]) > parseInt(ruleID) && !added) {
				added = true;
				updateRuleSelect.push(ruleID);
			}
			updateRuleSelect.push(selectedGroupRules[i]);
		}

		if (!added) {
			added = true;
			updateRuleSelect.push(ruleID);
		}

		reportRules.filter(function (rule) { return rule.group_id + "" === ruleID + "" }).forEach(function(rule){
			addSelectedRule(rule.id + "")
		});

		setSelectedGroupRules(updateRuleSelect);
	}

	function deleteSelectedRuleGroup(ruleID) {
		let updateRuleSelect = [];
		for (let i = 0; i < selectedGroupRules.length; i ++) {
			if (selectedGroupRules[i] + "" === ruleID + "") {
				continue;
			}
			updateRuleSelect.push(selectedGroupRules[i]);
		}

		reportRules.filter(function (rule) { return rule.group_id + "" === ruleID + "" }).forEach(function(rule){
			deleteSelectedRule(rule.id + "")
		});

		setSelectedGroupRules(updateRuleSelect);
	}

	function handleSelectRuleGroup(event) {
		if (event.currentTarget && event.currentTarget.dataset.id) {
			if (selectedGroupRules.indexOf(event.currentTarget.dataset.id + "") >= 0) {
				deleteSelectedRuleGroup(event.currentTarget.dataset.id + "");
			}
			else {
				addSelectedRuleGroup(event.currentTarget.dataset.id + "");
			}

		}
		else if (event.currentTarget && event.currentTarget.parentNode && event.currentTarget.parentNode.parentNode && event.currentTarget.parentNode.parentNode.dataset.id) {
			if (selectedGroupRules.indexOf(event.currentTarget.parentNode.parentNode.dataset.id + "") >= 0) {
				deleteSelectedRuleGroup(event.currentTarget.parentNode.parentNode.dataset.id + "");
			}
			else {
				addSelectedRuleGroup(event.currentTarget.parentNode.parentNode.dataset.id + "");
			}

		}
	}

	function handleNotAvailableRule(event) {
		toast("Your selected rule is not available for the selected PointCloud Sources. Please check classification for the Rule.", {
			autoClose: 20000
		});

		// toast('🦄 Wow so easy!', {
		// 	position: "top-right",
		// 	autoClose: 5000,
		// 	hideProgressBar: false,
		// 	closeOnClick: true,
		// 	pauseOnHover: true,
		// 	draggable: true,
		// 	progress: undefined,
		// 	theme: "light",
		// });
	}

	function handleNotAvailableGroup(event) {
		toast("Your selected rule group is not available for the selected PointCloud Sources. Please check classification for the Rules.", {
			autoClose: 20000
		});
	}

	function deleteRuleDialog(event) {
		if (event.currentTarget && event.currentTarget.dataset.id) {
			let datasetID = event.currentTarget.dataset.id;
			props.onDeleteDialog("Rule", "Do you want to delete this Rule?", function() {
				deleteRule(datasetID);
			}, "Delete");
		}
	}

	async function deleteRule(datasetID) {
		let ruleID = parseInt(datasetID);
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
			if (reportType === "lidar") {
				let response = await api.deleteReportRule({ token: userToken, id:ruleID });

				if (response.data && !response.data.error) {
					if (response.data.fail_message) {
						toast(response.data.fail_message);
					}
					else {
						let cloneRules = JSON.parse(JSON.stringify(reportRules));
						for (let i = 0; i < cloneRules.length; i ++) {
							if (cloneRules[i].id + "" === ruleID + "") {
								cloneRules.splice(i, 1);
								break;
							}
						}
						setReportRules(cloneRules);
					}
				}
			}
		}
	}

	function editGroup(event) {
		if (event.currentTarget && event.currentTarget.dataset.id) {
			if (reportType === "lidar") {
				let ruleID = parseInt(event.currentTarget.dataset.id);
				let rule = reportGroups.filter(function (value) {
					return value.id + "" === ruleID + "";
				})[0];
				setEditGroupObject(rule);
				setGroupName(rule.name);
				setShowGroupDialog(true);
			}
		}
	}

	function deleteGroupDialog(event) {
		if (event.currentTarget && event.currentTarget.dataset.id) {
			let datasetID = event.currentTarget.dataset.id;
			props.onDeleteDialog("Rule Group", "Do you want to delete this Rule Group?", function() {
				deleteGroup(datasetID);
			}, "Delete");
		}
	}

	function updateGroupRulesFromAPI(groups) {
		let cloneRuleGroups = [];
		let cloneRulesExtend = [];

		for (let i = 0; i < groups.length; i ++) {
			cloneRuleGroups.push(groups[i]);
			const pos = reportGroups.map(e => e.id).indexOf(groups[i]);
			if (pos >= 0) {
				cloneRulesExtend.push(reportGroupExtend[pos]);
			}
			else {
				cloneRulesExtend.push(false);
			}
		}
		setReportGroups(cloneRuleGroups);
		setReportGroupExtend(cloneRulesExtend);
	}

	async function deleteGroup(datasetID) {
		let ruleID = parseInt(datasetID);
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
			if (reportType === "lidar") {
				let response = await api.deleteRuleGroup({ token: userToken, id:ruleID });

				if (response.data && !response.data.error) {
					if (response.data.fail_message) {
						toast(response.data.fail_message);
					}
					else {
						setReportRules(response.data.rules);
						updateGroupRulesFromAPI(response.data.groups);
					}
				}
			}
		}
	}

	function editRule(event) {
		if (event.currentTarget && event.currentTarget.dataset.id) {
			if (reportType === "lidar") {
				let ruleID = parseInt(event.currentTarget.dataset.id);
				let rule = reportRules.filter(function (value) {
					return value.id + "" === ruleID + "";
				})[0];
				setUpdateRuleID(parseInt(event.currentTarget.dataset.id));
				setRuleName(rule.name);
				setRedRadius(rule.red);
				setYellowRadius(rule.yellow);
				setOrangeRadius(rule.orange);
				setVerticalOnly(rule.vertical_measurements_only === 1);
				setSelectedClassSource(rule.point_cloud_reference);
				setSelectedMeasurementSource(rule.point_cloud_measurement);
				setselectedRuleConstraints(rule.rule_constraints);
				setselectedRuleFunction(rule.rule_function);
				setSelectedGroup(rule.group_id);
				setShowingRule(true);
			}
		}
	}

	function getGroupRulesList(rules, groupID) {
		const items = rules.filter(function (rule) { return rule.group_id === groupID }).map((rule, index) => (
			<div key={rule.id} className="rule_item">
				{isAvailableRules(rule) ?
				<CustomCheckbox data-id={rule.id} checked={selectedRules.indexOf(rule.id + "") >= 0} onChange={handleSelectRule} />
				:
				<CustomCheckbox data-id={rule.id} checked={selectedRules.indexOf(rule.id + "") >= 0} onChange={handleNotAvailableRule}/>}
				<div className="rule_small_icon can_hover_icon no_boder_line" data-id={rule.id} onClick={editRule}><div className='icon settings_icon'></div></div>
				<div className="rule_small_icon can_hover_icon no_boder_line" data-id={rule.id} onClick={deleteRuleDialog}><div className='icon delete_icon'></div></div>
				{isAvailableRules(rule) ?
				<div className='rule_white_label' data-id={rule.id} onClick={handleSelectRule}>{rule.name}</div>
				:
				<div className='rule_label' data-id={rule.id}>{rule.name}</div>
				}
			</div>
		));
		return items;
	}

	function getRulesList() {
		let items = [];

		let cloneRules = JSON.parse(JSON.stringify(reportRules));
		let cloneGroupRules = JSON.parse(JSON.stringify(reportGroups));

		if (sortRule) {
			cloneRules.sort(function(first, second) {
				return first.name.toLowerCase().localeCompare(second.name.toLowerCase());
			});
			cloneGroupRules.sort(function(first, second) {
				return first.name.toLowerCase().localeCompare(second.name.toLowerCase());
			});
		}

		items = items.concat(cloneGroupRules.map((ruleGroup, index) => (
			<div key={ruleGroup.id} className="rule_group_item">
				{!reportGroupExtend[index]?
					<div className='expand_icon below_icon' onClick={function() {
						let _reportGroupExtend = JSON.parse(JSON.stringify(reportGroupExtend));
						_reportGroupExtend[index] = !_reportGroupExtend[index];
						setReportGroupExtend(_reportGroupExtend);
					}}></div>
				:
					<div className='expand_icon up_icon' onClick={function() {
						let _reportGroupExtend = JSON.parse(JSON.stringify(reportGroupExtend));
						_reportGroupExtend[index] = !_reportGroupExtend[index];
						setReportGroupExtend(_reportGroupExtend);
					}}></div>
				}
				<div className='item_group_list'>
					<div className='flex_part'>
						{isAvailableGroupRules(cloneRules, ruleGroup.id) ?
						<CustomCheckbox data-id={ruleGroup.id} checked={selectedGroupRules.indexOf(ruleGroup.id + "") >= 0} onChange={handleSelectRuleGroup} />
						:
						<CustomCheckbox data-id={ruleGroup.id} checked={selectedGroupRules.indexOf(ruleGroup.id + "") >= 0} onChange={handleNotAvailableGroup} />
						}
						<div className="rule_small_icon can_hover_icon no_boder_line" data-id={ruleGroup.id} onClick={editGroup}><div className='icon settings_icon'></div></div>
						<div className="rule_small_icon can_hover_icon no_boder_line" data-id={ruleGroup.id} onClick={deleteGroupDialog}><div className='icon delete_icon'></div></div>
						{isAvailableGroupRules(cloneRules, ruleGroup.id) ?
						<div className='rule_white_label' data-id={ruleGroup.id} onClick={handleSelectRuleGroup}>{ruleGroup.name}</div>
						:
						<div className='rule_label' data-id={ruleGroup.id}>{ruleGroup.name}</div>
						}
					</div>
					{reportGroupExtend[index] ?
						getGroupRulesList(cloneRules, ruleGroup.id)
					:null}
				</div>
			</div>
		)));
		items = items.concat(cloneRules.filter(function (rule) { return rule.group_id === -1 }).map((rule, index) => (
			<div key={rule.id} className="rule_item">
				{isAvailableRules(rule) ?
				<CustomCheckbox data-id={rule.id} checked={selectedRules.indexOf(rule.id + "") >= 0} onChange={handleSelectRule} />
				:
				<CustomCheckbox data-id={rule.id} checked={selectedRules.indexOf(rule.id + "") >= 0} onChange={handleNotAvailableRule}/>}
				<div className="rule_small_icon can_hover_icon no_boder_line" data-id={rule.id} onClick={editRule}><div className='icon settings_icon'></div></div>
				<div className="rule_small_icon can_hover_icon no_boder_line" data-id={rule.id} onClick={deleteRuleDialog}><div className='icon delete_icon'></div></div>
				{isAvailableRules(rule) ?
				<div className='rule_white_label' data-id={rule.id} onClick={handleSelectRule}>{rule.name}</div>
				:
				<div className='rule_label' data-id={rule.id}>{rule.name}</div>
				}
			</div>
		)));

		return items;
	}

	function checkAllAnnotationType() {
		if (checkedAll) {
			setAnnotationTypeArray([]);
		}
		else {
			setAnnotationTypeArray(annotationTypeList.map((annotationType) => annotationType.value));
		}

		setTypeCheckAll(!checkedAll);
	}

	function getAnnotationTypeMenu() {
		const items = annotationTypeList.map((annotationType, index) => (
			<div key={index} onClick={selectAnnotationType} data-id={annotationType.value} className='image_filter_annotation_type'>
				<CustomCheckbox checked={annotationTypeArray.indexOf(annotationType.value) >= 0}/>
				<div className='filter_image_type'>{annotationType.label}</div>
			</div>
        ));

		items.unshift((
			<div key={-1} onClick={checkAllAnnotationType} className='image_filter_annotation_type'>
				<CustomCheckbox checked={checkedAll}/>
				<div className='filter_image_type'>Check/Uncheck All</div>
			</div>
        ));

        return items;
	}

	function getAnnotationStatusMenu() {
		const items= annotationStatusList.map((annotationStatus, index) => (
			<div key={index} onClick={changeFilterStatus} data-id={annotationStatus.value} className='image_filter_annotation_type'>
				<CustomCheckbox checked={annotationStatusArray.indexOf(annotationStatus.value) >= 0}/>
				<div className='filter_image_type'>{annotationStatus.label}</div>
			</div>
        ));

        return items;
	}

	function onChangeSearchImage(evt) {
        setImageSearch(evt.target.value);
    }

	function showAdminOnly()
	{
		var css = {};

		if(props.userInfo.role !== "admin")
		{
			css = {display : 'none'};
		}

		return css;
	}

	function depricatedField()
	{
		// Hide fields that are now longer used
		var css = {display : 'none'};

		return css;
	}

	return (
		<div className={clsx(classes.view)}
		>
			<div className='report_generator_part'>
				<div className={`input_part ${(reportType === "image_grouper" || reportType === "lidar_dem") ? 'parameter_part_2' : 'parameter_part_lidar'}`}>
					<div className='part_label'>1. INPUTS</div>
					{reportType === "lidar" || reportType === "image" || reportType === "map" || reportType === "lidar_dem"?
					<div className='selection_type'>
						<div className='selection_label'>{reportType === "lidar_dem"?"Name":"Report Name"}</div>
						<input type="text" className="report-input-class" name="report_name" value={reportName} onChange={onReportNameChange} autoComplete="new-password"/>
					</div>
					:null}
					{reportType === "lidar" || reportType === "image" || reportType === "map" || reportType === "lidar_dem"?
					<div className='selection_type'>
						<div className='selection_label'>{reportType === "lidar_dem"?"Description":"Report Description"}</div>
						<input type="text" className="report-input-class" name="report_description" value={reportDescription} onChange={onReportDescriptionChange} autoComplete="new-password"/>
					</div>
					:null}
					{reportType === "image" || reportType === "image_grouper"?
					<div className='selection_type'>
						<div className='selection_label'>Images Source</div>
						<div className='selection_list'>
							<Select
								isClearable
								isMulti
								onChange={selectImageResource}
								styles={selectStyle}
								options={imageSourceList}
								value = {
									imageSourceList.filter(function (option) {
										return selectedImageSource.indexOf(option.value.item.id) >= 0;
									})
								}
							></Select>
						</div>
					</div>
					:null}
					{reportType === "lidar"?
					<div className='selection_type'>
						<div className='selection_label'>Lidar Source</div>
						<div className='selection_list'>
							<Select
								isClearable
								isMulti
								onChange={selectPointCloudSource}
								styles={selectStyle}
								options={pointDataSourceList}
								value = {
									pointDataSourceList.filter(function (option) {
										return selectedPointDataSource.indexOf(option.value.item.id) >= 0;
									})
								}
							></Select>
						</div>
					</div>
					:null}
					{reportType === "lidar_dem"?
					<div className='selection_type'>
						<div className='selection_label'>Point Cloud Source</div>
						<div className='selection_list'>
							<Select
								isClearable
								onChange={selectPointCloudSource}
								styles={selectStyle}
								options={pointDataSourceList}
								value = {
									pointDataSourceList.filter(function (option) {
										return selectedPointDataSource.indexOf(option.value.item.id) >= 0;
									})
								}
							></Select>
						</div>
					</div>
					:null}
					{reportType !== "lidar_dem"?
					<div className='selection_type'>
						<div className='selection_label'>KMZ PlaceMark Source</div>
						<div className='selection_list'>
							<Select
								isClearable
								isMulti={false}
								onChange={selectKMZDataSource}
								styles={selectStyle}
								options={kmzDataSourceList}
								value = {
									kmzDataSourceList.filter(function (option) {
										return (selectedKMZDataSource)?option.value.item.id === selectedKMZDataSource.item.id:false;
									})
								}
							></Select>
						</div>
					</div>
					:null}

					{reportType !== "lidar_dem"?
					<div className='selection_type'>
						<div className='selection_label'>KMZ PlaceMark Label</div>
						<div className='selection_list'>
							<Select
								isClearable
								isMulti={false}
								onChange={selectKMZDataLabel}
								styles={selectStyle}
								options={kmzDataLabelList}
								value = {
									kmzDataLabelList.filter(function (option) {
										return (selectedKMZDataLabel)?option.value === selectedKMZDataLabel:false;
									})
								}
							></Select>
						</div>
					</div>
					:null}

					{reportType === "image"?
					<div className='selection_type'>
						<div className='selection_label'>Zoom Option</div>
						<div className='selection_list'>
							<Select
								isMulti={false}
								onChange={(event) => {
									if (event) {
										setZoomOption(event.value)
									}
								}}
								styles={selectStyle}
								options={zoomOptionList}
								value = {
									zoomOptionList.filter(function (option) {
										return (zoomOption)?option.value === zoomOption:false;
									})
								}
							></Select>
						</div>
					</div>
					:null}

					{reportType === "lidar"?
					<div className='selection_type'>
						<div className='selection_label'>Annotation Spacing</div>
						<div className='selection_list'>
							<Select
								isClearable={false}
								onChange={selectAnnotationSpacing}
								styles={selectStyle}
								options={annotationSpacingList}
								value = {
									annotationSpacingList.filter(function (option) {
										return option.value === annotationSpacing;
									})
								}
							></Select>
						</div>
					</div>
					:null}

					{reportType === "lidar"?
					<div className='selection_type flex_part report_date start_date'>
						<div className='calendar_icon '></div>
						<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText={'All Dates'}/>
						<div className='selection_label '>Start</div>
					</div>
					:null}

					{reportType === "lidar"?
					<div className='selection_type flex_part report_date'>
						<div className='calendar_icon '></div>
						<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
						<div className='selection_label '>End</div>
					</div>
					:null}
				</div>
				{reportType !== "image_grouper" && reportType !== "lidar_dem"?
				<div className={`configure_part parameter_part_lidar_configure`} >
					<div className='title_part'>
						<div className='title_rules'>{reportType === "lidar"?"2. MEASUREMENT RULES":"2. Report Rules"}</div>
						{reportType === "lidar"?
						<div className='rule_button_style' title="A-Z Sort" onClick={sortRules}>
							<div className='icon sort_rule_icon'></div>
							<div className='rule_button_title'>Sort</div>
						</div>
						:null}
						{reportType === "lidar"?
						<div className='rule_button_style' title="Show Rule Table" onClick={showRuleTable}>
							<div className='icon table_rule_icon'></div>
							<div className='rule_button_title'>Rule Table</div>
						</div>
						:null}
						{reportType === "lidar"?
						<div className='rule_button_style' title="Add Group" onClick={addGroup}>
							<div className='icon add_rule_icon'></div>
							<div className='rule_button_title'>Add Group</div>
						</div>
						:null}
						{reportType === "lidar"?
						<div className='rule_button_style' title="Add Rules" onClick={addRules}>
							<div className='icon add_rule_icon'></div>
							<div className='rule_button_title'>Add Rule</div>
						</div>
						:null}
					</div>
					{reportType === "lidar"?
					<div className='rules_list'>
						{getRulesList()}
					</div>
					:
					<div className='rules_list'>
							<div>
								<div className='expand_filter_component'>
									<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
										setExpandedDateFilter(!isExpandedDateFilter);
										setExpandedTypeFilter(false);
										setExpandedImageNamingFilter(false);
										setExpandedStatusFilter(false);
										setExpandedSeverityFilter(false);
										setExpandedTextFilter(false);
									}}>
										{!isExpandedDateFilter?
											<div className='icon below_icon'></div>
										:
											<div className='icon up_icon'></div>
										}
									</div>
									<label className='expand_filter_label' onClick={() => {
										setExpandedDateFilter(!isExpandedDateFilter);
										setExpandedTypeFilter(false);
										setExpandedImageNamingFilter(false);
										setExpandedStatusFilter(false);
										setExpandedSeverityFilter(false);
										setExpandedTextFilter(false);
									}}>Date Filter</label>
								</div>
								{isExpandedDateFilter?
								<div className='flex_part filter_menu'>
									<div>
										<div className='image_filter_date flex_part report_date'>
											<div className='calendar_icon '></div>
											<DatePicker selected={startDate}
												onChange={(date) => setStartDate(date)}
												placeholderText={'All Dates'}
												popperProps={{
													positionFixed: true
												}}
											/>
											<div className='selection_label hover_text' onClick={() => setStartDate(null)}>Start</div>
										</div>
										<div className='image_filter_date flex_part report_date'>
											<div className='calendar_icon '></div>
											<DatePicker selected={endDate}
												onChange={(date) => setEndDate(date)}
												placeholderText={'All Dates'}
												popperProps={{
													positionFixed: true
												}}
											/>
											<div className='selection_label hover_text' onClick={() => setEndDate(null)}>End</div>
										</div>
									</div>
									{reportType === "image"?
									<RadioGroup
										name="controlled-radio-buttons-group"
										value={filterOption}
										onChange={selectFilterOption}
									>
										<FormControlLabel value="annotation" control={<Radio />} label="Annotation" />
										<FormControlLabel value="image" control={<Radio />} label="Image" />
									</RadioGroup>
									:null}
								</div>
								:null}
							</div>
						<div className='expand_filter_component'>
							<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
								setExpandedTypeFilter(!isExpandedTypeFilter);
								setExpandedDateFilter(false);
								setExpandedStatusFilter(false);
								setExpandedSeverityFilter(false);
								setExpandedTextFilter(false);
								setExpandedImageNamingFilter(false);
							}}>
								{!isExpandedTypeFilter?
									<div className='icon below_icon'></div>
								:
									<div className='icon up_icon'></div>
								}
							</div>
							<label className='expand_filter_label' onClick={() => {
								setExpandedTypeFilter(!isExpandedTypeFilter);
								setExpandedDateFilter(false);
								setExpandedStatusFilter(false);
								setExpandedSeverityFilter(false);
								setExpandedTextFilter(false);
								setExpandedImageNamingFilter(false);
							}}>Type Filter</label>
						</div>
						{isExpandedTypeFilter?
						<div className='filter_menu'>
							{getAnnotationTypeMenu()}
						</div>
						:null}
						<div className='expand_filter_component'>
							<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
								setExpandedStatusFilter(!isExpandedStatusFilter);
								setExpandedDateFilter(false);
								setExpandedTypeFilter(false);
								setExpandedSeverityFilter(false);
								setExpandedTextFilter(false);
								setExpandedImageNamingFilter(false);
							}}>
								{!isExpandedStatusFilter?
									<div className='icon below_icon'></div>
								:
									<div className='icon up_icon'></div>
								}
							</div>
							<label className='expand_filter_label' onClick={() => {
								setExpandedStatusFilter(!isExpandedStatusFilter);
								setExpandedDateFilter(false);
								setExpandedTypeFilter(false);
								setExpandedSeverityFilter(false);
								setExpandedTextFilter(false);
								setExpandedImageNamingFilter(false);
							}}>Status Filter</label>
						</div>
						{isExpandedStatusFilter?
						<div className='filter_menu'>
							{getAnnotationStatusMenu()}
						</div>
						:null}
						<div className='expand_filter_component'>
							<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
								setExpandedSeverityFilter(!isExpandedSeverityFilter);
								setExpandedDateFilter(false);
								setExpandedTypeFilter(false);
								setExpandedStatusFilter(false);
								setExpandedTextFilter(false);
								setExpandedImageNamingFilter(false);
							}}>
								{!isExpandedSeverityFilter?
									<div className='icon below_icon'></div>
								:
									<div className='icon up_icon'></div>
								}
							</div>
							<label className='expand_filter_label' onClick={() => {
								setExpandedSeverityFilter(!isExpandedSeverityFilter);
								setExpandedDateFilter(false);
								setExpandedTypeFilter(false);
								setExpandedStatusFilter(false);
								setExpandedTextFilter(false);
								setExpandedImageNamingFilter(false);
							}}>Severity Filter</label>
						</div>
						{isExpandedSeverityFilter?
						<div className='filter_menu'>
							<CustomCheckbox checked={annotationSeverity.indexOf("red") >= 0} className="filter_inspect_5_checkbox" onClick={selectAnnotationSeverity} data-id={"red"}/>
							<CustomCheckbox checked={annotationSeverity.indexOf("orange") >= 0} className="filter_inspect_4_checkbox" onClick={selectAnnotationSeverity} data-id={"orange"}/>
							<CustomCheckbox checked={annotationSeverity.indexOf("yellow") >= 0} className="filter_inspect_3_checkbox" onClick={selectAnnotationSeverity} data-id={"yellow"}/>
							<CustomCheckbox checked={annotationSeverity.indexOf("green") >= 0} className="filter_inspect_2_checkbox" onClick={selectAnnotationSeverity} data-id={"green"}/>
						</div>
						:null}
						{reportType === "image"?
						<div>
							<div className='expand_filter_component'>
								<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
									setExpandedImageNamingFilter(!isExpandedIamgeNamingFilter);
									setExpandedDateFilter(false);
									setExpandedTypeFilter(false);
									setExpandedStatusFilter(false);
									setExpandedTextFilter(false);
									setExpandedSeverityFilter(false);
								}}>
									{!isExpandedIamgeNamingFilter?
										<div className='icon below_icon'></div>
									:
										<div className='icon up_icon'></div>
									}
								</div>
								<label className='expand_filter_label' onClick={() => {
									setExpandedImageNamingFilter(!isExpandedIamgeNamingFilter);
									setExpandedDateFilter(false);
									setExpandedTypeFilter(false);
									setExpandedStatusFilter(false);
									setExpandedTextFilter(false);
									setExpandedSeverityFilter(false);
								}}>Image Naming</label>
							</div>
							{isExpandedIamgeNamingFilter?
							<div className='filter_menu'>
								<RadioGroup
									name="controlled-radio-buttons-group"
									value={imageNaming}
									onChange={selectImageNaming}
								>
									<FormControlLabel value="no_change" control={<Radio />} label="No change" />
									<FormControlLabel value="kmz_label_type_severity" control={<Radio />} label="KMZ Label+Type+Severity" />
									<FormControlLabel value="kmz_label_type" control={<Radio />} label="KMZ Label+Type" />
									<FormControlLabel value="kmz_label" control={<Radio />} label="KMZ Label" />
								</RadioGroup>
							</div>
							:null}
						</div>
						:null}
						<div className='expand_filter_component'>
							<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
								setExpandedTextFilter(!isExpandedTextFilter);
								setExpandedDateFilter(false);
								setExpandedTypeFilter(false);
								setExpandedStatusFilter(false);
								setExpandedSeverityFilter(false);
								setExpandedImageNamingFilter(false);
							}}>
								{!isExpandedTextFilter?
									<div className='icon below_icon'></div>
								:
									<div className='icon up_icon'></div>
								}
							</div>
							<label className='expand_filter_label' onClick={() => {
								setExpandedTextFilter(!isExpandedTextFilter);
								setExpandedDateFilter(false);
								setExpandedTypeFilter(false);
								setExpandedStatusFilter(false);
								setExpandedSeverityFilter(false);
								setExpandedImageNamingFilter(false);
							}}>Text Filter</label>
						</div>
						{isExpandedTextFilter?
						<div className='filter_menu'>
							<input type="text" className="search-name-input"
								value={imageSearch}
								placeholder="Search Name"
								onChange={onChangeSearchImage}></input>
						</div>
						:null}
					</div>
					}

				</div>
				:null}
				{(reportType !== "lidar" && reportType !== "image" && reportType !== "map")?
				<div className={`output_part ${(reportType === "image_grouper" || reportType === "lidar_dem") ? 'parameter_part_2' : 'parameter_part'}`}>
					<div className='part_label'>2. OUTPUTS</div>
					{reportType === "image_grouper"?
					<div className='selection_type'>
						<label className='report-output-label'>Renames images based on proximity to KMZ placemarks as follows: "KMZ_XX.Xft_X.jpg"</label>
					</div>
					:reportType === "lidar_dem"?
					<div className='selection_type'>
						<label className='report-output-label'>Creates a rasterized DEM(Digital Elevation Model) from a Lidar dataset for visualization and download</label>
					</div>
					:null}
					{reportType === "lidar_dem"?
					<div className='selection_type'>
						<div className='selection_label'>Dem Color Scale</div>
						<div className='selection_list'>
							<Select
								isClearable
								onChange={selectDemColorScale}
								styles={selectStyle}
								options={demColorScaleList}
								value = {
									demColorScaleList.filter(function (option) {
										return option.value === demColorScale;
									})
								}
							></Select>
						</div>
					</div>
					:null}
					{reportType === "lidar_dem"?
					<div className='selection_type'>
						<div className='selection_label'>Dem Color Depth</div>
						<div className='selection_list'>
							<Select
								isClearable
								onChange={selectDemColorDepth}
								styles={selectStyle}
								options={demColorDepthList}
								value = {
									demColorDepthList.filter(function (option) {
										return option.value === demColorDepth;
									})
								}
							></Select>
						</div>
					</div>
					:null}
					{reportType === "lidar_dem"?
					<div className='selection_type'>
						<div className='column_flex'>
							<div className='scale_input'>
								<div className='scale_input_title'>Min Scale</div>
								<input type="text" className="report-radius-class" value={minScale} onChange={onMinScaleChange} autoComplete="new-password"/>
							</div>
							<div className='radius-divider'> - </div>
							<div className='scale_input'>
								<div className='scale_input_title'>Max Scale</div>
								<input type="text" className="report-radius-class" value={maxScale} onChange={onMaxScaleChange} autoComplete="new-password"/>
							</div>
						</div>
						<div className='small_explain_label'>Only applicable for manual scale</div>
					</div>
					:null}
				</div>
				:null
				}
			</div>
			<div className='footer_part'>
				<div className='report_type'>{props.selectedProject.project.name}: {getReportType()}</div>
				<Button
					variant="contained"
					color="secondary"
					onClick={generateReport}
					className={classes.button}
				>
					{reportType !== "image_grouper"?"Generate":"Group"}
				</Button>
			</div>

			<Modal show={isShowingRule} animation={false} className="rule-dialog">
				<Modal.Header>
					<Modal.Title>
						<div className="dialog_title">
							<div className='logo_title'>
							{updateRuleID === -1?"Configure Rule":"Update Rule"}
							</div>
							<div className='close_button' onClick={hideRuleDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
                <Modal.Body>
					<div className='selection_type_flex'>
						<div className='selection_label_small'>Rule Name: </div>
						<input type="text" className="report-input-class" name="report_name" value={ruleName} onChange={onChangeRuleName} autoComplete="new-password"/>
					</div>
					<div className='selection_type_flex'>
						<div className='selection_label_large'>Rule Group: </div>
						<div className='selection_list_half'>
							<Select
								isMulti={false}
								onChange={selectGroup}
								styles={selectStyle}
								options={getRuleGroupsSelectOptions()}
								value = {
									getRuleGroupsSelectOptions().filter(function (option) {
										return option.value === selectedGroup;
									})
								}
							></Select>
						</div>
					</div>
                    <div className='selection_type_flex'>
						<div className='selection_label_large'>Point Cloud Reference: </div>
						<div className='selection_list_half'>
							<Select
								isClearable
								isMulti={false}
								onChange={selectClassSource}
								styles={selectStyle}
								options={classSourceList}
								value = {
									classSourceList.filter(function (option) {
										return (selectedClassSource !== null)?option.value === selectedClassSource:false;
									})
								}
							></Select>
						</div>
					</div>
					<div className='selection_type_flex'>
						<div className='selection_label_large'>Point Cloud Measurement: </div>
						<div className='selection_list_half'>
							<Select
								isClearable
								isMulti={false}
								onChange={selectMeasurementSource}
								styles={selectStyle}
								options={classSourceList}
								value = {
									classSourceList.filter(function (option) {
										return (selectedMeasurementSource !== null)?option.value === selectedMeasurementSource:false;
									})
								}
							></Select>
						</div>
					</div>
					<div className='selection_type_flex' style={showAdminOnly()}>
						<div className='selection_label_large'>Rule Constraints:</div>
						<div className='selection_list_half'>
							<Select
								isClearable
								isMulti={false}
								onChange={selectRuleConstraints}
								styles={selectStyle}
								options={ruleConstraintsList}
								value = {
									ruleConstraintsList.filter(function (option) {
										return option.value === selectedRuleConstraints;
									})
								}
							></Select>
						</div>
					</div>
					<div className='selection_type_flex' style={showAdminOnly()}>
						<div className='selection_label_large'>Rule Function: </div>
						<div className='selection_list_half'>
							<Select
								isClearable
								isMulti={false}
								onChange={selectRuleFunction}
								styles={selectStyle}
								options={ruleFunctionList}
								value = {
									ruleFunctionList.filter(function (option) {
										return option.value === selectedRuleFunction;
									})
								}
							></Select>
						</div>
					</div>
					<div className='column_flex'>
						<div className='radius_input'>
							<div className='radius_input_title'>Red</div>
							<input type="text" className="report-radius-class red_input" name="report_start_radius" value={redRadius} onChange={onRedRadiusChange} autoComplete="new-password"/>
						</div>
						<div className='radius-divider'> - </div>
						<div className='radius_input'>
							<div className='radius_input_title'>Orange</div>
							<input type="text" className="report-radius-class orange_input" name="report_start_radius" value={orangeRadius} onChange={onOrangeRadius} autoComplete="new-password"/>
						</div>
						<div className='radius-divider'> - </div>
						<div className='radius_input'>
							<div className='radius_input_title'>Yellow</div>
							<input type="text" className="report-radius-class yellow_input" name="report_start_radius" value={yellowRadius} onChange={onYellowRadiusChange} autoComplete="new-password"/>

						</div>
						<div className='radius-divider'> ft </div>

						<div className='selection_type vertical_only_checkbox' style={depricatedField()}>
							<CustomCheckbox checked={verticalOnly} onChange={handleVerticalOnly} />
							<label className='report-output-label' onClick={handleVerticalOnly}>Vertical Measurements Only</label>
						</div>
					</div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={saveRule} className={classes.button}>
                    {updateRuleID !== -1?"Update":"Create"}
                </Button>
                </Modal.Footer>
            </Modal>

			<Modal show={showGroupDialog} animation={false} className="rule-dialog">
				<Modal.Header>
					<Modal.Title>
						<div className="dialog_title">
							<div className='logo_title'>
							{editGroupObject?"Update Rule Group":"Create Rule Group"}
							</div>
							<div className='close_button' onClick={hideGroupDialog}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
                <Modal.Body>
					<div className='selection_type_flex'>
						<div className='selection_label_small'>Rule Group Name: </div>
						<input type="text" className="report-input-class" name="group_name" value={groupName} onChange={(evt) => {
							setGroupName(evt.target.value);
						}} autoComplete="new-password"/>
					</div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={saveGroup} className={classes.button}>
                    {editGroupObject?"Update":"Create"}
                </Button>
                </Modal.Footer>
            </Modal>

			<Modal show={showTable} animation={false} className="rule-dialog">
				<Modal.Header>
					<Modal.Title>
						<div className="dialog_title">
							<div className='logo_title'>
							Update Rule
							</div>
							<div className='close_button' onClick={hideRuleTable}>
								<img src="/close.png" alt="close" style={{height: "100%"}}/>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
                <Modal.Body>
					<div className='selection_type_flex'>
						<textarea className="report-input-class report-input-csv" value={csvRuleData} onChange={(evt) => {
							setCSVRuleData(evt.target.value);
						}} autoComplete="new-password"/>
					</div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={saveCSVRule} className={classes.button}>
                    Update
                </Button>
                </Modal.Footer>
            </Modal>
		</div>
	);
}

const mapStateToProps = state => ({
	userInfo: state.global.userInfo,
	datasetList : state.project.datasetList,
	selectedProject: state.project.selectedProject,
	classificationList: state.project.classificationList
});

Report.propTypes = {
}

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
	connect(mapStateToProps, authActionCreators),
)(Report);