import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as api from '../../utils/api';
import { globalAction, projectAction } from '../../store/actions';
import Creatable from 'react-select/creatable';
import { CircularProgress } from '@mui/material';
import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import ProjectDatasetItem from './ProjectDatasetItem';
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import DatePicker from "react-datepicker";
import BackButton from '../../components/BackButton';
import * as mapUtils from '../../components/MapView/MapUtils';

import clsx from 'clsx';

import "react-datepicker/dist/react-datepicker.css";

import './style.scss';
import { toast } from 'react-toastify';

const Cesium = window.Cesium;

const useStyles = makeStyles(theme => ({
	root: {
        padding: 10,
        // overflow: "auto",
        width: "100%"
	},
    button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
    panel_title:{
        display: 'flex',
        gap: 10,
        width: '100%',
        alignItems: 'center',
    },
    panel_label : {
        float: "left",
        color: "#2196f3",
        fontSize: 20,
        '@media (max-width: 1000px)': {
            flex: '1 1 100%',
        }
    },
    panel_actions: {
        display: 'flex',
        width: '100%',
    },
    panel_selection: {
        width: '100%',
        float: 'left',
        textAlign: 'left',
    },
    panel_selection_viewer: {
        width: 'calc(100% - 110px)',
        float: 'left',
        textAlign: 'left',
    },
    panel_create: {
        float: "left",
    },
    info_section: {
        position: 'absolute',
        marginTop: '20px',
        justifyContent: 'center',
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 120px)',
        overflow: 'auto',
        '@media (max-width: 1000px)': {
            height: 'calc(100% - 115px)',
            marginTop: 20
        }
    },
    project_menu: {
        width: "100%",
        height: 50,
        display: 'flex',
    },
    project_list: {
        width: "100%",
        height: "calc(99% - 50px)",
    },
    circular_progress: {
        width: 40,
        height: 40,
        position: 'absolute',
    },
    annotation_button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20,
		marginTop: 20,
    },
    select_sort_order: {
        width: "calc(50% - 50px)",
        marginRight: 10,
        marginTop: 5
    },
    select_sort_order_viewer: {
        width: "calc(50% + 50px)",
        marginRight: 10,
        marginTop: 5
    },
	show: {
		display: 'block !important',
	},
	hide: {
		display: 'none !important',
	}
}));

const selectStyle = {
    control: (base, state) => ({
      ...base,
      background: "#17181b",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "#323232",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "white"
      },
      color: 'white',
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
    input: base => ({
      ...base,
      color: "white"
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused?"#27282b":"#17181b",
      color : "white",
      zIndex: 1,
      textAlign: 'left',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      zIndex: 9999,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      border: "1px solid white"
    })
};

const sortTypeList = [
	{
		value : "date",
		label: "Date",
	},
	{
		value : "name",
		label: "Name",
	},
	{
		value : "type",
		label: "Type",
	}
];

const sortOrderList = [
	{
		value : "asc",
		label: "Ascending",
	},
	{
		value : "desc",
		label: "Descending",
	}
];

function ProjectPanel(props) {
	const classes = useStyles();

    function redirectToLogin() {
        props.history.replace("/");
    }

    const [projectList, setProjectList] = React.useState([]);
    const [project, setProject] = React.useState(null);

    const [progress, setProgress] = React.useState(false);

    const [isCreatingProject, setCreatingProject] = React.useState(false);
    const [creatingProjectName, setCreatingProjectName] = React.useState("");
    const [creatingProjectDescription, setCreatingProjectDescription] = React.useState("");
    const [projectUpdating, setProjectUpdating] = React.useState(false);

    const [isEditLidarAnnotation, setEditLidarAnnotation] = React.useState(false);
	const [editAnnotationName, setEditAnnotationName] = React.useState("");
    const [editAnnotationObject, setEditAnnotation] = React.useState(null);
    const [editAnnotationDescription, setEditAnnotationDescription] = React.useState("");
    const [annotationType, setAnnotationType] = React.useState("other");
    const [annotationStatus, setAnnotationStatus] = React.useState("0");
	const [severitylevel, setSeverityLevel] = React.useState(1);

    const [isCreatingShare, setCreatingShare] = React.useState(false);
    const [sharingPassword, setSharingPassword] = React.useState("");
    const [sharingConfirmPassword, setSharingConfirmPassword] = React.useState("");
    const [sharingToken, setSharingToken] = React.useState("");
    const [sharingExpire, setSharingExpire] = React.useState(new Date(new Date().setDate(new Date().getDate() + 30)));

    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordMatched, setPasswordMatched] = React.useState(true);

    const [matchPasswordString, setMatchPasswordString] = React.useState(false);
	const [matchPasswordCapital, setMatchPasswordCapital] = React.useState(false);
	const [matchPasswordSymbol, setMatchPasswordSymbol] = React.useState(false);
	const [matchPasswordSpace, setMatchPasswordSpace] = React.useState(false);

    const [annotationTypeList, setAnnotationTypeList] = React.useState([]);
    const [annotationStatusList, setAnnotationStatusList] = React.useState([]);

	function containsUppercase(str) {
		return /[A-Z]/.test(str);
	}

	function containsSpecialChars(str) {
		return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
	}

	function checkPasswordCondition(passwordString) {
		if (passwordString === 0) {
			setMatchPasswordString(false);
			setMatchPasswordCapital(false);
			setMatchPasswordSymbol(false);
			setMatchPasswordSpace(false);
			return;
		}
		if (passwordString.length >= 8 && passwordString.length <= 20) {
			setMatchPasswordString(true);
		}
		else {
			setMatchPasswordString(false);
		}

		if (containsUppercase(passwordString)) {
			setMatchPasswordCapital(true);
		}
		else {
			setMatchPasswordCapital(false);
		}

		if (containsSpecialChars(passwordString)) {
			setMatchPasswordSymbol(true);
		}
		else {
			setMatchPasswordSymbol(false);
		}

		if (passwordString.indexOf(" ") < 0) {
			setMatchPasswordSpace(true);
		}
		else {
			setMatchPasswordSpace(false);
		}
	}

    useEffect(() => {
		setProjectListOptions(props.allProjects);
	}, [props.allProjects]);

    useEffect(() => {
        if (props.userInfo.annotation_type_list === "" || !props.userInfo.annotation_type_list) {
            setAnnotationTypeList(mapUtils.generateAnnotationTypeList());
        }
        else {
            setAnnotationTypeList(JSON.parse(props.userInfo.annotation_type_list));
        }

        if (props.userInfo.annotation_status_list === "" || !props.userInfo.annotation_status_list) {
            setAnnotationStatusList(mapUtils.generateAnnotationStatusList());
        }
        else {
            setAnnotationStatusList(JSON.parse(props.userInfo.annotation_status_list));
        }

        if (props.selectedProject) {
            generateProjectInfo();
        }
	}, [props.selectedProject]);

    useEffect(() => {
        if (props.selectedProject) {
            generateProjectInfo(true);
        }
	}, [props.userInfo]);

    function setStatus(id, datasetItem, datasetIDList, visibleList, forceHide) {
        let indexDataset = datasetIDList.indexOf(id + "");
        if (indexDataset >= 0) {
            let visible = false;
            if (visibleList) {
                if (visibleList[indexDataset] === true || visibleList[indexDataset] === "true" || visibleList[indexDataset] === "1") {
                    visible = true;
                }
            }
            if (forceHide) {
                datasetItem.visible = false;
            }
            else {
                datasetItem.visible = visible;
            }
            datasetItem.index = indexDataset;
            return datasetItem;
        }

        return undefined;
    }

    function startCallProjectItem(id) {
        if (window.processCheck) {
            clearTimeout(window.processCheck);
            window.processCheck = null;
        }

        window.processCheck = setTimeout(function() {
            getProjectItem(id);
        }, 60000);
    }


    function getProfileClassification(datasetItem) {
        let _classificationList = JSON.parse(JSON.stringify(props.classificationList));
        if (props.userInfo.profile_name === "profile1" && props.userInfo.profile1_classification && props.userInfo.profile1_classification !== "") {
            _classificationList = JSON.parse(props.userInfo.profile1_classification);
        }
        else if (props.userInfo.profile_name === "profile2" && props.userInfo.profile2_classification && props.userInfo.profile2_classification !== "") {
            _classificationList = JSON.parse(props.userInfo.profile2_classification);
        }
        else if (props.userInfo.profile_name === "profile3" && props.userInfo.profile3_classification && props.userInfo.profile3_classification !== "") {
            _classificationList = JSON.parse(props.userInfo.profile3_classification);
        }

        if (datasetItem.lasinfo !== "") {
            let lasInfo = JSON.parse(datasetItem.lasinfo);

            if (lasInfo.classification) {
                for (let i = 0; i < _classificationList.length; i ++) {
                    if (lasInfo.classification.indexOf(_classificationList[i].index) < 0) {
                        _classificationList.splice(i, 1);
                        i --;
                    }
                }
            }
        }

        return _classificationList;
    }

    function generateProjectInfo(userInfoChanged) {
        let currentProject = props.selectedProject.project;
        let annotations = props.selectedProject.annotations;
        let files = props.selectedProject.files;
        let currentDatasets = props.selectedProject.datasets;
        let list = new Map();

        let visibleList;
        let datasetIDList = [];
        if (currentProject && currentProject.visible && currentProject.visible !== "") {
            visibleList = JSON.parse(currentProject.visible)
        }
        if (currentProject && currentProject.dataset_ids && currentProject.dataset_ids !== "") {
            datasetIDList = JSON.parse(currentProject.dataset_ids)
        }

        let startProcessCheck = false;
        if (window.processCheck) {
            clearTimeout(window.processCheck);
            window.processCheck = null;
        }
        if (project && project.id === currentProject.id) {
            if (currentDatasets) {
                let datasetItem = props.datasetList.get(-1);
                if (datasetItem) {
                    datasetItem.annotationList = annotations.filter(function(annotation) {
                        return annotation.type.indexOf("image_") < 0;
                    });
                    datasetItem.filterImage = (props.selectedProject.filterImage)?props.selectedProject.filterImage[currentProject.id + "_" + datasetItem.id]:undefined;
                    datasetItem.filterImageAnnotation = (props.selectedProject.filterImageAnnotation)?props.selectedProject.filterImageAnnotation[currentProject.id + "_" + datasetItem.id]:undefined;
                    datasetItem.filterImageAnnotationStatus = (props.selectedProject.filterImageAnnotationStatus)?props.selectedProject.filterImageAnnotationStatus[currentProject.id + "_" + datasetItem.id]:undefined;
                    datasetItem.filterImageSearch = (props.selectedProject.filterImageSearch)?props.selectedProject.filterImageSearch[currentProject.id + "_" + datasetItem.id]:"";
                    datasetItem.filterImageDate = (props.selectedProject.filterImageDate)?props.selectedProject.filterImageDate[currentProject.id + "_" + datasetItem.id]:undefined;
                    datasetItem.labelProperty = (props.selectedProject.labelProperty)?props.selectedProject.labelProperty[currentProject.id + "_" + datasetItem.id]:"none";
                    list.set(-1, datasetItem);
                }
                else {
                    let annotationDataset = {
                        id: -1,
                        name: "Map Annotations",
                        datatype: "annotations",
                        is_report: false,
                        status: "finish",
                        lasinfo: ""
                    };
                    let datasetItem = {
                        expanded : false,
                        classExpanded : false,
                        annotationExpanded : false,
                        imagesExpanded : false,
                        item : annotationDataset,
                        files : [],
                        index: -1,
                        visible: false,
                        pointSize: 3,
                        materialMode: "rgba",
                        labelMode: "xyz",
                        enableTerrain: false,
                        showLabel: false,
                        classificationList : getProfileClassification(annotationDataset),
                        annotationList: annotations.filter(function(annotation) {
                            return annotation.type.indexOf("image_") < 0;
                        }),
                        selected: false,
                        labelProperty: (props.selectedProject.labelProperty)?props.selectedProject.labelProperty[currentProject.id + "_" + annotationDataset.id]:"none",
                        filterImage: (props.selectedProject.filterImage)?props.selectedProject.filterImage[currentProject.id + "_" + annotationDataset.id]:undefined,
                        filterImageAnnotation: (props.selectedProject.filterImageAnnotation)?props.selectedProject.filterImageAnnotation[currentProject.id + "_" + annotationDataset.id]:undefined,
                        filterImageAnnotationStatus: (props.selectedProject.filterImageAnnotationStatus)?props.selectedProject.filterImageAnnotationStatus[currentProject.id + "_" + annotationDataset.id]:undefined,
                        filterImageSearch: (props.selectedProject.filterImageSearch)?props.selectedProject.filterImageSearch[currentProject.id + "_" + annotationDataset.id]:"",
                        filterImageDate: (props.selectedProject.filterImageDate)?props.selectedProject.filterImageDate[currentProject.id + "_" + annotationDataset.id]:undefined
                    }
                    list.set(annotationDataset.id, datasetItem);
                }

                let isFirstLoad = false;

                for (let i = 0; i < currentDatasets.length; i ++) {
                    if (currentDatasets[i].status === "processing" && !startProcessCheck) {
                        startProcessCheck = true;
                        startCallProjectItem(currentProject.id);
                    }
                    let annotationList = [];

                    if (currentDatasets[i].datatype === "img") {
                        annotationList = annotations.filter(function(annotation) {
                            return annotation.dataset_id === currentDatasets[i].id;
                        });
                    }

                    if (props.datasetList.get(currentDatasets[i].id)) {
                        let datasetItem = props.datasetList.get(currentDatasets[i].id);
                        datasetItem.item = currentDatasets[i];
                        datasetItem = setStatus(currentDatasets[i].id, datasetItem, datasetIDList, visibleList);
                        datasetItem.annotationList = annotationList;
                        datasetItem.filterImage = (props.selectedProject.filterImage)?props.selectedProject.filterImage[currentProject.id + "_" + currentDatasets[i].id]:undefined;
                        datasetItem.filterImageAnnotation = (props.selectedProject.filterImageAnnotation)?props.selectedProject.filterImageAnnotation[currentProject.id + "_" + currentDatasets[i].id]:undefined;
                        datasetItem.filterImageAnnotationStatus = (props.selectedProject.filterImageAnnotationStatus)?props.selectedProject.filterImageAnnotationStatus[currentProject.id + "_" + currentDatasets[i].id]:undefined;
                        datasetItem.filterImageSearch = (props.selectedProject.filterImageSearch)?props.selectedProject.filterImageSearch[currentProject.id + "_" + currentDatasets[i].id]:"";
                        datasetItem.filterImageDate = (props.selectedProject.filterImageDate)?props.selectedProject.filterImageDate[currentProject.id + "_" + currentDatasets[i].id]:undefined;
                        datasetItem.labelProperty = (props.selectedProject.labelProperty)?props.selectedProject.labelProperty[currentProject.id + "_" + currentDatasets[i].id]:"none";
                        datasetItem.files = files.filter(function(file){
                            return file.dataset_id === currentDatasets[i].id
                        });
                        let classificationList = [...datasetItem.classificationList];
                        datasetItem.classificationList = getProfileClassification(currentDatasets[i]);

                        if (!userInfoChanged) {
                            for (let classIndex = 0; classIndex < datasetItem.classificationList.length; classIndex ++) {
                                for (let originalIndex = 0; originalIndex < classificationList.length; originalIndex ++) {
                                    if (datasetItem.classificationList[classIndex].index === classificationList[originalIndex].index) {
                                        datasetItem.classificationList[classIndex].visible = classificationList[originalIndex].visible;
                                        break;
                                    }
                                }
                            }
                        }

                        if (datasetItem) {
                            list.set(currentDatasets[i].id, datasetItem);
                        }
                    }
                    else {
                        let datasetItem = {
                            expanded : false,
                            classExpanded : false,
                            annotationExpanded : false,
                            imagesExpanded : false,
                            item : currentDatasets[i],
                            files : files.filter(function(file){
                                return file.dataset_id === currentDatasets[i].id
                            }),
                            index: i,
                            visible: false,
                            pointSize: 3,
                            materialMode: (currentDatasets[i].datatype === "pointcloud")?"elevation":"rgba",
                            enableTerrain: false,
                            showLabel: false,
                            classificationList : getProfileClassification(currentDatasets[i]),
                            annotationList: annotationList,
                            selected: false,
                            labelProperty: (props.selectedProject.labelProperty)?props.selectedProject.labelProperty[currentProject.id + "_" + currentDatasets[i].id]:"none",
                            filterImage: (props.selectedProject.filterImage)?props.selectedProject.filterImage[currentProject.id + "_" + currentDatasets[i].id]:undefined,
                            filterImageAnnotation: (props.selectedProject.filterImageAnnotation)?props.selectedProject.filterImageAnnotation[currentProject.id + "_" + currentDatasets[i].id]:undefined,
                            filterImageAnnotationStatus: (props.selectedProject.filterImageAnnotationStatus)?props.selectedProject.filterImageAnnotationStatus[currentProject.id + "_" + currentDatasets[i].id]:undefined,
                            filterImageSearch: (props.selectedProject.filterImageSearch)?props.selectedProject.filterImageSearch[currentProject.id + "_" + currentDatasets[i].id]:"",
                            filterImageDate: (props.selectedProject.filterImageDate)?props.selectedProject.filterImageDate[currentProject.id + "_" + currentDatasets[i].id]:undefined
                        }
                        datasetItem = setStatus(currentDatasets[i].id, datasetItem, datasetIDList, visibleList, true);
                        if (datasetItem) {
                            list.set(currentDatasets[i].id, datasetItem);
                        }

                        isFirstLoad = true;
                    }
                }

                if (isFirstLoad) {
                    updateProjectVisibleAll(false);
                    updateProjectVisibleAllDatabase(currentProject.id, false);
                }
            }
        }
        else if (currentProject) {
            if (currentDatasets) {
                let annotationDataset = {
                    id: -1,
                    name: "Map Annotations",
                    datatype: "annotations",
                    is_report: false,
                    status: "finish",
                    lasinfo: ""
                };
                let datasetItem = {
                    expanded : false,
                    classExpanded : false,
                    annotationExpanded : false,
                    imagesExpanded : false,
                    item : annotationDataset,
                    files : [],
                    index: -1,
                    visible: false,
                    pointSize: 3,
                    materialMode: "rgba",
                    labelMode: "xyz",
                    enableTerrain: false,
                    showLabel: false,
                    classificationList : getProfileClassification(annotationDataset),
                    annotationList: annotations.filter(function(annotation) {
                        return annotation.type.indexOf("image_") < 0;
                    }),
                    selected: false,
                    labelProperty: (props.selectedProject.labelProperty)?props.selectedProject.labelProperty[currentProject.id + "_" + annotationDataset.id]:"none",
                    filterImage: (props.selectedProject.filterImage)?props.selectedProject.filterImage[currentProject.id + "_" + annotationDataset.id]:undefined,
                    filterImageAnnotation: (props.selectedProject.filterImageAnnotation)?props.selectedProject.filterImageAnnotation[currentProject.id + "_" + annotationDataset.id]:undefined,
                    filterImageAnnotationStatus: (props.selectedProject.filterImageAnnotationStatus)?props.selectedProject.filterImageAnnotationStatus[currentProject.id + "_" + annotationDataset.id]:undefined,
                    filterImageSearch: (props.selectedProject.filterImageSearch)?props.selectedProject.filterImageSearch[currentProject.id + "_" + annotationDataset.id]:"",
                    filterImageDate: (props.selectedProject.filterImageDate)?props.selectedProject.filterImageDate[currentProject.id + "_" + annotationDataset.id]:undefined
                }
                list.set(annotationDataset.id, datasetItem);

                for (let i = 0; i < currentDatasets.length; i ++) {
                    if (currentDatasets[i].status === "processing" && !startProcessCheck) {
                        startProcessCheck = true;
                        startCallProjectItem(currentProject.id);
                    }
                    let annotationList = [];

                    if (currentDatasets[i].datatype === "img") {
                        annotationList = annotations.filter(function(annotation) {
                            return annotation.dataset_id === currentDatasets[i].id;
                        });
                    }

                    let datasetItem = {
                        expanded : false,
                        classExpanded : false,
                        annotationExpanded : false,
                        imagesExpanded : false,
                        item : currentDatasets[i],
                        files : files.filter(function(file){
                            return file.dataset_id === currentDatasets[i].id
                        }),
                        index: i,
                        visible: false,
                        pointSize: 3,
                        materialMode: (currentDatasets[i].datatype === "pointcloud")?"elevation":"rgba",
                        enableTerrain: false,
                        showLabel: false,
                        classificationList : getProfileClassification(currentDatasets[i]),
                        annotationList: annotationList,
                        selected: false,
                        labelProperty: (props.selectedProject.labelProperty)?props.selectedProject.labelProperty[currentProject.id + "_" + currentDatasets[i].id]:"none",
                        filterImage: (props.selectedProject.filterImage)?props.selectedProject.filterImage[currentProject.id + "_" + currentDatasets[i].id]:undefined,
                        filterImageAnnotation: (props.selectedProject.filterImageAnnotation)?props.selectedProject.filterImageAnnotation[currentProject.id + "_" + currentDatasets[i].id]:undefined,
                        filterImageAnnotationStatus: (props.selectedProject.filterImageAnnotationStatus)?props.selectedProject.filterImageAnnotationStatus[currentProject.id + "_" + currentDatasets[i].id]:undefined,
                        filterImageSearch: (props.selectedProject.filterImageSearch)?props.selectedProject.filterImageSearch[currentProject.id + "_" + currentDatasets[i].id]:"",
                        filterImageDate: (props.selectedProject.filterImageDate)?props.selectedProject.filterImageDate[currentProject.id + "_" + currentDatasets[i].id]:undefined
                    }
                    datasetItem = setStatus(currentDatasets[i].id, datasetItem, datasetIDList, visibleList, true);

                    if (datasetItem) {
                        list.set(currentDatasets[i].id, datasetItem);
                    }
                }
                updateProjectVisibleAll(false);
                updateProjectVisibleAllDatabase(currentProject.id, false);
            }
        }

        props.setDatasetList(list);
        setProject(currentProject);
    }

    function updateProjectVisibleAll(visible) {
        let visibleList = JSON.parse(props.selectedProject.project.visible);
        for (let i = 0; i < visibleList.length; i ++) {
            visibleList[i] = visible;
        }
        let selectedProject = Object.assign({}, props.selectedProject);
        selectedProject.project.visible = JSON.stringify(visibleList);
        props.selectProject(selectedProject);
    }

    async function updateProjectVisibleAllDatabase(project_id, visible) {
        /* This is all visible setup when first. but no need update. If need we can enable later */
        if (!api.isEnableVisibleAllDatabase) {
            return;
        }
        let userToken = localStorage.getItem("userToken");

        if (api.isSharing()) {
            return;
        }
        if (userToken) {
            await api.updateProjectVisibleAll({token: userToken, id: project_id, visible: visible});
        }
        else {
            redirectToLogin();
        }
    }

    async function updateVisible(project_id, dataset_id, visible) {
        let userToken = localStorage.getItem("userToken");

        if (api.isSharing()) {
            return;
        }
        if (userToken) {
            await api.updateVisible({token: userToken, id: project_id, dataset_id: dataset_id, visible: visible});
        }
        else {
            redirectToLogin();
        }
    }

    async function updateReportVisible(dataset_id, reportFiles) {
        let userToken = localStorage.getItem("userToken");
        if (api.isSharing()) {
            return;
        }
        if (userToken) {
            await api.updateReportVisible({token: userToken, id: dataset_id, reportFiles: JSON.stringify(reportFiles)});
        }
        else {
            redirectToLogin();
        }
    }

    async function deleteDataset(project_id, dataset_id) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            setProgress(true);
            let response = await api.deleteDatasetFromProject({token: userToken, id: project_id, dataset_id: dataset_id});
            if (response.data && !response.data.error) {
                props.selectProject(response.data);
            }
            setProgress(false);
        }
        else {
            redirectToLogin();
        }
    }

    async function updateAnnotationVisible(annotation_id, visible) {
        let userToken = localStorage.getItem("userToken");
        if (api.isSharing()) {
            return;
        }
        if (userToken) {
            await api.updateAnnotationVisible({token: userToken, id: annotation_id, visible: visible});
        }
        else {
            redirectToLogin();
        }
    }

    async function deleteAnnotation(annotation_id) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            await api.deleteAnnotationByID({token: userToken, id: annotation_id});
            setProgress(false);
        }
        else {
            redirectToLogin();
        }
    }

    async function createProject(name, description) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.createProject({token: userToken, name: name, description: description});

            if (response.data && !response.data.error) {
                props.selectProject(response.data);
                if (response.data.project) {
                    let allProjects = props.allProjects;
                    allProjects.push(response.data.project);
                    props.setAllProjects(allProjects);
                    setProjectListOptions(allProjects);
                    api.setParameter("project_id", response.data.project.id);
                }
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

    async function updateProject(name, description) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.updateProject({token: userToken, id: props.selectedProject.project.id, name: name, description: description});

            if (response.data && !response.data.error) {
                props.selectProject(response.data);
                if (response.data.project) {
                    let allProjects = props.allProjects;
                    for (let i = 0; i < allProjects.length; i ++) {
                        if ((allProjects[i].id + "") === (response.data.project.id + "")) {
                            allProjects[i] = response.data.project;
                        }
                    }
                    props.setAllProjects(allProjects);
                    setProjectListOptions(allProjects);
                    api.setParameter("project_id", response.data.project.id);
                }
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
    }

    function deleteProjectWithDialog() {
        props.onDeleteDialog("Delete Project", "Do you want to delete this project?", function() {
            deleteProject();
        })
    }

    async function deleteProject() {
        let userToken = localStorage.getItem("userToken");
        let project_id = props.selectedProject.project.id;
        if (userToken) {
            setProgress(true);
            let response = await api.deleteProjectByID({token: userToken, id: project_id});
            if (response.data && !response.data.error) {
                props.selectProject({
                    project : {
                      id : -1
                    }
                });
                let allProjects = props.allProjects;
                for (let i = 0; i < allProjects.length; i ++) {
                    if ((allProjects[i].id + "") === (project_id + "")) {
                        allProjects.splice(i, 1);
                    }
                }
                props.setAllProjects(allProjects);
                setProjectListOptions(allProjects);
                api.setParameter("project_id", "");
            }
            setProgress(false);
        }
        else {
            redirectToLogin();
        }
    }

    function showProjectCreating(isUpdating, name) {
        if (isUpdating) {
            setProjectUpdating(true);
            setCreatingProjectName((props.selectedProject.project.name !== null)?props.selectedProject.project.name:"");
            setCreatingProjectDescription((props.selectedProject.project.description !== null)?props.selectedProject.project.description:"");
            setCreatingProject(true);
        }
        else {
            setProjectUpdating(false);
            setCreatingProjectName(name);
            setCreatingProjectDescription("");
            setCreatingProject(true);
        }
    }

    async function getProjectItem(projectID, showReportToast) {
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
            setProgress(true);
			let response = await api.getProjectByID({id :projectID, token: userToken});
			if (response.data && !response.data.error) {
                let responseProject = response.data;
				props.selectProject(responseProject);
                if (showReportToast) {
                    let currentDatasets = responseProject.datasets;
                    if (currentDatasets) {
                        for (let i = 0; i < currentDatasets.length; i ++) {
                            if (localStorage.getItem("report_" + currentDatasets[i].id)) {
                                toast(currentDatasets[i].name + " Report started successfully. Please check projects to check progress.");
                                if (currentDatasets[i].status == "finish") {
                                    localStorage.removeItem("report_" + currentDatasets[i].id);
                                }
                            }
                        }
                    }
                }
			}
			else {
				redirectToLogin();
			}
            setProgress(false);
		}
		else {
			redirectToLogin();
		}
	}

    function selectProjectByID(project) {
        api.setParameter("project_id", project.id);
        props.selectProject({
            project: {
                id: project.id
            }
        });
        getProjectItem(project.id, true);
    }

    function selectProject(newValue, actionMeta) {
        if (actionMeta.action === 'create-option') {
            showProjectCreating(false, newValue.label);
        }
        else if (newValue) {
          if (newValue.value) {
            selectProjectByID(newValue.value);
          }
        }
    }

    function setProjectListOptions(projects) {
        let optionValues = [];
        for (let i = 0; i < projects.length; i ++) {
          optionValues.push({
            label: projects[i].name,
            value: projects[i]
          })
        }
        optionValues.sort(function(first, end) {
            return first.label.toLowerCase() < end.label.toLowerCase() ? 1 : -1
        })
        setProjectList(optionValues);
    }

    function onChangeName(evt) {
        setCreatingProjectName(evt.target.value);
    }

    function onChangeDescription(evt) {
        setCreatingProjectDescription(evt.target.value);
    }

    function onChangeSharingPassword(evt) {
        setSharingPassword(evt.target.value);
        checkPasswordCondition(evt.target.value);
        setPasswordMatched(true);
    }

    function onChangeSharingConfirmPassword(evt) {
        setSharingConfirmPassword(evt.target.value);
		setPasswordMatched(true);
    }

    function hideSharingDialog() {
        setSharingPassword("");
        setSharingToken("");
        setCreatingShare(false);
    }

    function hideProjectDialog() {
        setCreatingProject(false);
    }

    function createProjectDialog() {
        if (projectUpdating) {
            if (creatingProjectName !== "") {
                updateProject(creatingProjectName, creatingProjectDescription);
                setCreatingProject(false);
            }
        }
        else {
            if (creatingProjectName !== "") {
                createProject(creatingProjectName, creatingProjectDescription);
                setCreatingProject(false);
            }
        }
    }

    function createSharing() {
        if (sharingToken === "") {
            toast("Share Address should be not empty");
            return;
        }

        if (sharingPassword !== sharingConfirmPassword) {
			setPasswordMatched(false);
			return;
		}

        if (!matchPasswordCapital || !matchPasswordSpace || !matchPasswordSymbol || !matchPasswordString) {
			toast("Please check the password strength.");
			return;
		}

        createSharingOnServer(sharingToken, sharingExpire, sharingPassword);
    }

    function getDatasetIndex(id) {
        let currentDatasets = props.selectedProject.datasets;
        for (let i = 0; i < currentDatasets.length; i ++) {
            if (currentDatasets[i].id === id) return i;
        }

        return 0;
    }

    async function reProcessDataset(dataset_id) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.startProcessDataset({
                token : userToken, id : dataset_id, attach: false
            });

            if (response.data && !response.data.error) {
                if (props.selectedProject.project) {
                    getProjectItem(props.selectedProject.project.id);
                }
            }
        }
        else {
            toast("Failed. Please reload and try again.");
        }
    }

    async function reCreateLidarReport(id, project_id) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.startLidarReport({token: userToken, id: id, project_id: project_id});

            if (response.data && !response.data.error) {
                if (props.selectedProject.project) {
                    toast("Report started successfully. Please check projects to check progress.");
                    getProjectItem(props.selectedProject.project.id);
                }
            }
        }
        else {
            toast("Failed. Please reload and try again.");
        }
    }

    async function reCreateMapReport(id, project_id) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.startMapReport({token: userToken, id: id, project_id: project_id});

            if (response.data && !response.data.error) {
                if (props.selectedProject.project) {
                    toast("Report started successfully. Please check projects to check progress.");
                    getProjectItem(props.selectedProject.project.id);
                }
            }
        }
        else {
            toast("Failed. Please reload and try again.");
        }
    }

    async function reprocessDem(id, project_id) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.startLidarDem({token: userToken, id: id, project_id: project_id});

            if (response.data && !response.data.error) {
                if (props.selectedProject.project) {
                    toast("Report started successfully. Please check projects to check progress.");
                    getProjectItem(props.selectedProject.project.id);
                }
            }
        }
        else {
            toast("Failed. Please reload and try again.");
        }
    }

    async function reCreateImageReport(id, project_id) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.startImageReport({token: userToken, id: id, project_id: project_id});

            if (response.data && !response.data.error) {
                if (props.selectedProject.project) {
                    toast("Report started successfully. Please check projects to check progress.");
                    getProjectItem(props.selectedProject.project.id);
                }
            }
        }
        else {
            toast("Failed. Please reload and try again.");
        }
    }

    function getDatasetListItems() {
        const values = Array.from(props.datasetList.values());
        values.sort(function(first, second) {
            if (second.item.id === -1) return -1;
            let firstValue = '';
            let secondValue = '';
            if (props.sortType === 'name') {
                firstValue = first.item.name;
                secondValue = second.item.name;
            }
            else if (props.sortType === 'date') {
                firstValue = first.item.updated_at;
                secondValue = second.item.updated_at;
            }
            else if (props.sortType === 'type') {
                firstValue = first.item.datatype;
                secondValue = second.item.datatype;
            }

            if (props.sortOrder === 'asc') return firstValue.toLowerCase().localeCompare(secondValue.toLowerCase());
            return secondValue.toLowerCase().localeCompare(firstValue.toLowerCase());
        })
        const items = values.map((value, index) => (
            <ProjectDatasetItem
                key={value.item.id}
                item={value}
                hasTopBorder={index === 0}
                isMinimize={props.isMinimize}
                onExpandable={() => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        datasetItem.expanded = !datasetItem.expanded;
                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                    }
                }}
                onExpandClassification={() => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        datasetItem.classExpanded = !datasetItem.classExpanded;
                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                    }
                }}
                onExpandAnnotations={() => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        datasetItem.annotationExpanded = !datasetItem.annotationExpanded;
                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                    }
                }}
                onExpandIamges={() => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        datasetItem.imagesExpanded = !datasetItem.imagesExpanded;
                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                    }
                }}
                onChangeLabelDisplay={() => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        datasetItem.showLabel = !datasetItem.showLabel;
                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                    }
                }}
                onVisible={(isVisible) => {
                    if (value.item.id === -1) {
                        let list = new Map(props.datasetList);
                        let datasetItem = props.datasetList.get(-1);
                        if (datasetItem) {
                            datasetItem.visible = isVisible;
                            list.set(-1, datasetItem);
                            props.setDatasetList(list);
                        }
                    }
                    else {
                        let visibleList = JSON.parse(props.selectedProject.project.visible);
                        let index = getDatasetIndex(value.item.id);
                        visibleList[index] = isVisible;
                        let selectedProject = Object.assign({}, props.selectedProject);
                        selectedProject.project.visible = JSON.stringify(visibleList);
                        props.selectProject(selectedProject);

                        updateVisible(selectedProject.project.id, value.item.id, isVisible);
                    }

                }}
                onVisibleReport={(reportFile, isVisible) => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        let reportFiles = (datasetItem.item.reportFiles)?JSON.parse(datasetItem.item.reportFiles):[0, 0, 0, 0, 0, 0, 0, 0];
                        if (reportFiles.length === 4) {
                            reportFiles.push(0);
                            reportFiles.push(0);
                            reportFiles.push(0);
                            reportFiles.push(0);
                        }
                        if (reportFile === "kmz") {
                            reportFiles[7] = (isVisible)?1:0;
                        }
                        else if (reportFile === "las") {
                            reportFiles[4] = (isVisible)?1:0;
                        }
                        else if (reportFile === "pdf") {
                            reportFiles[5] = (isVisible)?1:0;
                        }
                        else if (reportFile === "csv") {
                            reportFiles[6] = (isVisible)?1:0;
                        }
                        datasetItem.item.reportFiles = JSON.stringify(reportFiles);

                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                        updateReportVisible(value.item.id, reportFiles);
                    }
                }}
                onVisibleImageReport={(reportFile, isVisible) => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        let reportFiles = (datasetItem.item.reportFiles)?JSON.parse(datasetItem.item.reportFiles):[0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                        if (reportFiles.length === 5) {
                            reportFiles.push(0);
                            reportFiles.push(0);
                            reportFiles.push(0);
                            reportFiles.push(0);
                            reportFiles.push(0);
                        }
                        if (reportFile === "kmz") {
                            reportFiles[5] = (isVisible)?1:0;
                        }
                        else if (reportFile === "csv") {
                            reportFiles[6] = (isVisible)?1:0;
                        }
                        else if (reportFile === "pdf") {
                            reportFiles[7] = (isVisible)?1:0;
                        }
                        else if (reportFile === "with_overlay") {
                            reportFiles[8] = (isVisible)?1:0;
                        }
                        else if (reportFile === "no_overlay") {
                            reportFiles[9] = (isVisible)?1:0;
                        }
                        datasetItem.item.reportFiles = JSON.stringify(reportFiles);

                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                        updateReportVisible(value.item.id, reportFiles);
                    }
                }}
                onVisibleMapReport={(reportFile, isVisible) => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        let reportFiles = (datasetItem.item.reportFiles)?JSON.parse(datasetItem.item.reportFiles):[0, 0, 0, 0, 0, 0];
                        if (reportFiles.length === 3) {
                            reportFiles.push(0);
                            reportFiles.push(0);
                            reportFiles.push(0);
                        }
                        if (reportFile === "kmz") {
                            reportFiles[3] = (isVisible)?1:0;
                        }
                        else if (reportFile === "csv") {
                            reportFiles[3] = (isVisible)?1:0;
                        }
                        else if (reportFile === "pdf") {
                            reportFiles[5] = (isVisible)?1:0;
                        }
                        datasetItem.item.reportFiles = JSON.stringify(reportFiles);

                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                        updateReportVisible(value.item.id, reportFiles);
                    }
                }}
                onChangePointSize={(pointSize) => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        datasetItem.pointSize = pointSize
                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                    }
                }}
                onChangeMaterialMode={(materialMode) => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        datasetItem.materialMode = materialMode
                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                    }
                }}
                onChangeLabelMode={(labelMode) => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        datasetItem.labelMode = labelMode
                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                    }
                }}
                onChangeEnableTerrain={(enable) => {
                    let list = new Map(props.datasetList);
                    list.forEach(function(datasetItem, key) {
                        if (key === value.item.id) {
                            datasetItem.enableTerrain = enable;
                        }
                        else {
                            datasetItem.enableTerrain = false;
                        }

                        list.set(key, datasetItem);
                    });

                    props.setDatasetList(list);
                }}
                onClassificationVisible={(id, isVisible) => {
                    let list = new Map(props.datasetList);
                    if (list.get(value.item.id)) {
                        let datasetItem = list.get(value.item.id);
                        for (let i = 0; i < datasetItem.classificationList.length; i ++) {
                            if (datasetItem.classificationList[i].index === id) {
                                datasetItem.classificationList[i].visible = isVisible;
                            }
                        }
                        list.set(value.item.id, datasetItem);
                        props.setDatasetList(list);
                    }
                }}
                onDeleteAnnotation={(id) => {
                    props.onDeleteDialog("Delete Annotation", "Do you want to delete this annotation?", function() {
                        let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                        if (cloneProject.project.id >= 0) {
                            for (let i = 0; i < cloneProject.annotations.length; i ++) {
                                if (cloneProject.annotations[i].id + "" === id + "") {
                                    cloneProject.annotations.splice(i, 1);
                                    break;
                                }
                            }
                            deleteAnnotation(id);
                            props.selectProject(cloneProject);
                        }
                    })
                }}
                onDeleteAllAnnotation={(id) => {
                    props.onDeleteDialog("Delete All Annotations", "Do you want to delete all annotations?", async function() {
                        let userToken = localStorage.getItem("userToken");
                        if (userToken) {
                            let response = await api.deleteAllAnnotation({token: userToken, project_id: props.selectedProject.project.id});
                            if (response.data && !response.data.error) {
                                let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                                cloneProject.annotations = cloneProject.annotations.filter(function(annotation) {
                                    return annotation.type.indexOf("image_") >= 0;
                                });
                                props.selectProject(cloneProject);
                            }
                            else {
                                toast("Delete All annotations failed. Please retry to refresh.");
                            }
                        }
                        else {
                            toast("Delete All annotations failed");
                        }
                    })
                }}
                onEditAnnotation={(id) => {
                    if (props.selectedProject.project.id >= 0) {
                        for (let i = 0; i < props.selectedProject.annotations.length; i ++) {
                            if (props.selectedProject.annotations[i].id + "" === id + "") {
                                setAnnotationType(props.selectedProject.annotations[i].annotation_type);
                                setEditAnnotationName(props.selectedProject.annotations[i].name?props.selectedProject.annotations[i].name:"");
                                setEditAnnotationDescription(props.selectedProject.annotations[i].description?props.selectedProject.annotations[i].description:"");
                                setSeverityLevel(props.selectedProject.annotations[i].severity_level);
                                setEditAnnotation(props.selectedProject.annotations[i]);
                                setEditLidarAnnotation(true);
                                break;
                            }
                        }
                    }
                }}
                onVisibleAnnotation={(id) => {
                    let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                    if (cloneProject.project.id >= 0) {
                        let visible;
                        for (let i = 0; i < cloneProject.annotations.length; i ++) {
                            if (cloneProject.annotations[i].id + "" === id + "") {
                                visible = cloneProject.annotations[i].visible
                                cloneProject.annotations[i].visible = !visible;
                                break;
                            }
                        }
                        updateAnnotationVisible(id, !visible);
                        props.selectProject(cloneProject);
                    }
                }}
                onGoImageViewer = {(datasetID, fileID) => {
                    if (api.isSharing()) {
                        api.openNewWindow("/image/viewer?project_id=" + props.selectedProject.project.id
                        + "&dataset_id=" + datasetID
                        + "&file_id=" + fileID + "&share_token=" + api.getParameterByName("share_token"));
                    }
                    else {
                        api.openNewWindow("/image/viewer?project_id=" + props.selectedProject.project.id
                        + "&dataset_id=" + datasetID
                        + "&file_id=" + fileID);
                    }

                }}
                onImageFilterChange = {(datasetID, filterArrays) => {
                    let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                    if (cloneProject.project.id >= 0) {
                        if (!cloneProject.filterImage) cloneProject.filterImage = {};
                        cloneProject.filterImage[props.selectedProject.project.id + "_" + datasetID] = filterArrays;
                        props.selectProject(cloneProject);
                    }
                }}
                onImageFilterTypeChange = {(datasetID, filterArrays) => {
                    let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                    if (cloneProject.project.id >= 0) {
                        if (!cloneProject.filterImageAnnotation) cloneProject.filterImageAnnotation = {};
                        cloneProject.filterImageAnnotation[props.selectedProject.project.id + "_" + datasetID] = filterArrays;
                        props.selectProject(cloneProject);
                    }
                }}
                onImageFilterStatusChange = {(datasetID, filterArrays) => {
                    let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                    if (cloneProject.project.id >= 0) {
                        if (!cloneProject.filterImageAnnotationStatus) cloneProject.filterImageAnnotationStatus = {};
                        cloneProject.filterImageAnnotationStatus[props.selectedProject.project.id + "_" + datasetID] = filterArrays;
                        props.selectProject(cloneProject);
                    }
                }}
                onImageFilterTextChange = {(datasetID, value) => {
                    let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                    if (cloneProject.project.id >= 0) {
                        if (!cloneProject.filterImageSearch) cloneProject.filterImageSearch = {};
                        cloneProject.filterImageSearch[props.selectedProject.project.id + "_" + datasetID] = value;
                        props.selectProject(cloneProject);
                    }
                }}
                onImageFilterDateChange = {(datasetID, dateValues) => {
                    let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                    if (cloneProject.project.id >= 0) {
                        if (!cloneProject.filterImageDate) cloneProject.filterImageDate = {};
                        cloneProject.filterImageDate[props.selectedProject.project.id + "_" + datasetID] = dateValues;
                        props.selectProject(cloneProject);
                    }
                }}
                onLabelPropertyChange = {(datasetID, labelProperty) => {
                    let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                    if (cloneProject.project.id >= 0) {
                        if (!cloneProject.labelProperty) cloneProject.labelProperty = {};
                        cloneProject.labelProperty[props.selectedProject.project.id + "_" + datasetID] = labelProperty;
                        props.selectProject(cloneProject);
                    }
                }}
                onDelete={() => {
                    props.onDeleteDialog("Delete Dataset", "Do you want to delete this dataset from Project?", function() {
                        deleteDataset(props.selectedProject.project.id, value.item.id);
                    })
                }}
                onEdit={() => {
                    window.open("/upload?dataset_id=" + value.item.id);
                }}
                onRecreateLidarReport={(id, project_id) => {
                    props.onDeleteDialog("Report", "Do you want to recreate Lidar Report?", function() {
                        reCreateLidarReport(id, project_id);
                    }, "Start")
                }}
                onRecreateImageReport={(id, project_id) => {
                    props.onDeleteDialog("Report", "Do you want to recreate Image Report?", function() {
                        reCreateImageReport(id, project_id);
                    }, "Start")
                }}
                onRecreateMapReport={(id, project_id) => {
                    props.onDeleteDialog("Report", "Do you want to recreate Map Report?", function() {
                        reCreateMapReport(id, project_id);
                    }, "Start")
                }}
                onReprocessDem={(id, project_id) => {
                    props.onDeleteDialog("Lidar to DEM", "Do you want to recreate DEM from Lidar?", function() {
                        reprocessDem(id, project_id);
                    }, "Start")
                }}
                onReprocessDataset={(dataset_id) => {
                    props.onDeleteDialog("Dataset", "Do you want to reprocess dataset?", function() {
                        reProcessDataset(dataset_id);
                    }, "Start")
                }}
                onPropertyChange={(key, property) => {

                }}
            />
        ));

        return (items);
    }

    function editProject() {
        showProjectCreating(true, "");
    }

    function selectSortType(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				props.setSortType(newValue.value);
			}
		}
	}

    function selectSortOrder(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				props.setSortOrder(newValue.value);
			}
		}
	}

    function hideAnnoationDialog() {
        setEditLidarAnnotation(false);
        setAnnotationStatus("0");
    }

    function onChangeAnnotationName(evt) {
        setEditAnnotationName(evt.target.value);
    }

    function onChangeAnnotationDescription(evt) {
        setEditAnnotationDescription(evt.target.value);
    }

    function selectAnnotationType(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setAnnotationType(newValue.value);
			}
		}
	}

    function selectAnnotationStatus(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setAnnotationStatus(newValue.value);
			}
		}
	}

    function handleChangeSeverityLevel(event) {
		setSeverityLevel(parseInt(event.target.value));
	}

    async function editAnnotation() {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			let response = await api.updateAnnotation({
				id: editAnnotationObject.id,
				name: editAnnotationName,
				type: editAnnotationObject.type,
				description: editAnnotationDescription,
				positions: editAnnotationObject.positions,
				token: userToken,
				severity_level: severitylevel,
				annotation_type: annotationType,
                annotation_status: annotationStatus,
			});

            if (response.data && !response.data.error) {
                let cloneProject = JSON.parse(JSON.stringify(props.selectedProject));
                if (cloneProject.project.id >= 0) {
                    for (let i = 0; i < cloneProject.annotations.length; i ++) {
                        if (cloneProject.annotations[i].id + "" === editAnnotationObject.id + "") {
                            cloneProject.annotations[i] = response.data;
                            break;
                        }
                    }
                    props.selectProject(cloneProject);
                }
                toast("Update Annotation succeed");
            }
            else {
                toast("Edit Failed. Please retry to refresh");
            }
        }
        else {
            redirectToLogin();
        }

        hideAnnoationDialog();
	}

    function createProjectByMenu() {
        console.log('Creating project');
        showProjectCreating(false, "");
    }

    function shareProject() {
        setSharingPassword("");
        setMatchPasswordString(false);
        setMatchPasswordCapital(false);
        setMatchPasswordSymbol(false);
        setMatchPasswordSpace(false);
        setSharingToken("");
        getSharingTokenFromServer();
        setCreatingShare(true);
        setShowPassword(false);
    }

    async function getSharingTokenFromServer() {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.getSharingToken({token: userToken, project_id: props.selectedProject.project.id});
            if (response.data && !response.data.error) {
                setSharingToken(response.data);
            }
            else if (response.data) {
                toast(response.data.error);
            }
        }
        else {
            redirectToLogin();
        }
    }

    async function createSharingOnServer(token, expire_date, password) {
        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            let response = await api.createSharingLink({token: userToken, link_token:token, link_expired:expire_date.getTime() / 1000, project_id:props.selectedProject.project.id, password:password});
            if (response.data && !response.data.error) {
                if (response.data.fail_message) {
                    toast(response.data.fail_message);
                }
                else {
                    toast("Sharing Link Created")
                }
            }
            else if (response.data) {
                toast(response.data.error);
            }
        }
        else {
            redirectToLogin();
        }
    }

    function generateSharingAddress() {
        if (sharingToken !== "") {
            return api.appPath + "map?show_panel=project&project_id=" + props.selectedProject.project.id + "&share_token=" + sharingToken;
        }

        return "";
    }

    function copyAddress() {
        let text = generateSharingAddress();
        if (text === "") {
            toast("No Address");
            return;
        }
        window.navigator.clipboard.writeText(text).then(function() {
            toast("Copy Address Done");
        }, function(error) {
            toast(error);
        });
    }

    function onClickShowPassword() {
		setShowPassword(!showPassword);
	}

    function getAnnotationStatusLabel(annotationItem, index) {
        if (!annotationItem) return ""
		if (index === 0) {
			return annotationItem.created_at.substring(0, 10);
		}
		else if (annotationItem.annotation_status_history) {
			let jsonInfo = JSON.parse(annotationItem.annotation_status_history);
			let times = jsonInfo["" + index];
			if (times) {
				times = new Date(times * 1000);
				let monthValue = times.getMonth() + 1;
				return times.getFullYear() + "-"
				+ (monthValue < 10? "0" + monthValue:monthValue)
				+ "-" + (times.getDate() < 10? "0" + times.getDate():times.getDate());
			}
		}

		return "";
	}

	function getAnnotationStatusItems(annotationItem) {
		const items = annotationStatusList.map((annotationStatus, index) => (
			<div key={index} className='annotation_selection_type_label'>
				<div className='annotation_info_label'>({index + 1}) {annotationStatus.label}:</div>
				<div className='annotation_info_value'>{getAnnotationStatusLabel(annotationItem, index)}</div>
			</div>
        ));

        return items;
	}

    function getAnnotationMeasurementsLabel() {
		if (editAnnotationObject && (editAnnotationObject.type === "distance" || editAnnotationObject.type === "polyline")) {
            let position0, position1;
            let positions = JSON.parse(editAnnotationObject.positions);
            let result = "";
            for (let i = 0; i < positions.length - 5; i += 3) {
                position0 = new Cesium.Cartesian3(positions[i], positions[i + 1], positions[i + 2]);
                position1 = new Cesium.Cartesian3(positions[i + 3], positions[i + 4], positions[i + 5]);

                if (result !== "") {
                    result = result + "<br>";
                }
                result = result + mapUtils.getAnnotationMeasurementsLabel(position0, position1, "all", props.userInfo.measurement_units);

            }
            return result;
		}

		return "";
	}

	return (
		<div className={classes.root}>
			<div id="panel_project_selection">

                <div className={classes.panel_title}>
                    <div className={classes.panel_label}>Projects</div>
                    <BackButton callback={props.callback} />
                </div>

                <div className={classes.panel_actions}>
                    {api.isSharing() && !props.isMinimize?
                    (props.selectedProject.project.id !== -1?
                        <div className={classes.panel_selection_viewer}>
                            <div className='project_name'>
                            {props.selectedProject.project.name}AAA
                            </div>
                        </div>
                        :null)
                    :
                    (!props.isMinimize?
                    <div className={(api.canEditItem(props.userInfo))?classes.panel_selection:classes.panel_selection_viewer}>
                        {api.canEditItem(props.userInfo)?
                        <Creatable
                            isClearable
                            isMulti={false}
                            onChange={selectProject}
                            styles={selectStyle}
                            options={projectList}
                            value = {
                                projectList.filter(option =>
                                option.value.id === props.selectedProject.project.id)
                            }
                        ></Creatable>
                        :
                        <Select
                            isClearable
                            isMulti={false}
                            onChange={selectProject}
                            styles={selectStyle}
                            options={projectList}
                            value = {
                                projectList.filter(option =>
                                option.value.id === props.selectedProject.project.id) }
                        ></Select>}

                    </div>
                    :null)
                    }
                    {api.canEditItem(props.userInfo)?
                    <div className={classes.panel_create}>
                        <Button className="image_button_icon can_hover_icon no_boder_line" title="Create Project" onClick={createProjectByMenu}>
                            <div className='icon add_button_icon'></div>
                        </Button>
                    </div>
                    :null}
                    {api.isOwner(props.userInfo) && props.selectedProject.project.id !== -1 && !props.isMinimize?
                    <div className={classes.panel_create}>
                        <div className="image_button_icon can_hover_icon no_boder_line" title="Share Project" onClick={shareProject}><div className='icon share_button_icon'></div></div>
                    </div>
                    :null}
                </div>
            </div>
            <div className={classes.info_section}>
                {props.selectedProject.project.id !== -1 && !props.isMinimize?
                <div className={classes.project_menu}>
                    <div className='select_sort_type'>
                        <Select
                            isMulti={false}
                            onChange={selectSortType}
                            styles={selectStyle}
                            options={sortTypeList}
                            isSearchable={false}
                            value = {
                                sortTypeList.filter(function (option) {
                                    return option.value === props.sortType;
                                })
                            }
                        ></Select>
                    </div>
                    <div className={(api.canEditItem(props.userInfo))?classes.select_sort_order:classes.select_sort_order_viewer}>
                        <Select
                            isMulti={false}
                            onChange={selectSortOrder}
                            styles={selectStyle}
                            options={sortOrderList}
                            isSearchable={false}
                            value = {
                                sortOrderList.filter(function (option) {
                                    return option.value === props.sortOrder;
                                })
                            }
                        ></Select>
                    </div>
                    {api.canEditItem(props.userInfo)?
                    <div className="dataitem_icon can_hover_icon no_boder_line" title="Delete Project" onClick={deleteProjectWithDialog}><div className='icon delete_icon'></div></div>
                    :null}
                    {api.canEditItem(props.userInfo)?
                    <div className="dataitem_icon can_hover_icon no_boder_line" title="Edit Project" onClick={editProject}><div className='icon edit_icon'></div></div>
                    :null}
                </div>
                :null}
                {progress?
                    <CircularProgress className={classes.circular_progress}/>
                : null}
                <div className={classes.project_list}>
                    {getDatasetListItems()}
                </div>
            </div>
            <Modal show={isCreatingProject} animation={false}>
                <Modal.Header>
                    <Modal.Title>{projectUpdating?"Update Project":"Create Project"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='project-name-box'>
                        <p className="project-name-title">Name</p>
                        <input type="text" className="project-name-input"
                        value={creatingProjectName}
                        onChange={onChangeName}></input>
                    </div>
                    <div className='project-name-box'>
                        <p className="project-name-title">Description</p>
                        <textarea className="project-name-input"
                        value={creatingProjectDescription}
                        onChange={onChangeDescription}></textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="secondary" onClick={hideProjectDialog} className={classes.button}>
                    Close
                </Button>
                <Button variant="contained"
                    color="primary" onClick={createProjectDialog} className={classes.button}>
                    {projectUpdating?"Update":"Create"}
                </Button>
                </Modal.Footer>
            </Modal>
            {api.canEditItem(props.userInfo)?
                <Modal show={isCreatingShare} animation={false}>
                    <Modal.Header>
                        <Modal.Title>
                            <div className="dialog_title">
                                <div className='logo_title'>
                                    Share Project
                                </div>
                                <div className='close_button' onClick={hideSharingDialog}>
                                    <img src="/close.png" alt="close" style={{height: "100%"}}/>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form autoComplete="off">
                            <div className='project-name-box'>
                                <div className='link-name-label'>
                                    <p className="project-name-title address_label">Address</p>
                                    <div className="medium_icon can_hover_icon no_boder_line" title="Copy Clipboard" onClick={copyAddress}><div className='icon clipboard_icon'></div></div>
                                    <a href={generateSharingAddress()} className="project-name-link" target="_blank">{generateSharingAddress()}</a>
                                </div>
                            </div>
                            <div className='project-name-box'>
                                <p className="project-name-title">Expire Date</p>
                                <DatePicker selected={sharingExpire} onChange={(date) => setSharingExpire(date)} />
                            </div>
                            <div className='project-name-box'>
                                <div className='password_wrapper'>
                                    <div className='passwort_input_part'>
                                        <p className="project-name-title">Password</p>
                                        <div className='flex_part'>
                                            <input type="text" className={`project-name-input ${showPassword?'':'password_security'}`} value={sharingPassword} onChange={onChangeSharingPassword} autoComplete="off"/>
                                            <div className={"password_visible_icon can_hover_icon no_boder_line input-usermanagement-password"} onClick={onClickShowPassword}>
                                                {showPassword?
                                                <div className='icon visible_icon'></div>:
                                                <div className='icon invisible_icon'></div>}
                                            </div>
                                        </div>

                                        <p className="project-name-title">Confirm Password</p>
                                        <div className='flex_part'>
                                            <input type="text" className={`project-name-input ${showPassword?'':'password_security'}`} value={sharingConfirmPassword} onChange={onChangeSharingConfirmPassword} autoComplete="off"/>
                                            <div className={"password_visible_icon can_hover_icon no_boder_line input-usermanagement-password"} onClick={onClickShowPassword}>
                                                {showPassword?
                                                <div className='icon visible_icon'></div>:
                                                <div className='icon invisible_icon'></div>}
                                            </div>
                                        </div>

                                        <div className={clsx("form-group", {
                                            [classes.show]: !passwordMatched,
                                            [classes.hide]: passwordMatched
                                        })}>
                                            <label className="error-label">Password Not Matched.</label>
                                        </div>
                                    </div>

                                    <div className='reset_password_condition share_password_condition'>
                                        <div>Password must include:</div>
                                        <div className='password_condition_explain'>
                                            {matchPasswordString?
                                            <div className='check_image'></div>
                                            :
                                            <div className='fail_image'></div>
                                            }
                                            <div>8-20 Characters</div>
                                        </div>
                                        <div className='password_condition_explain'>
                                            {matchPasswordCapital?
                                            <div className='check_image'></div>
                                            :
                                            <div className='fail_image'></div>
                                            }
                                            <div>At least one capital letter</div>
                                        </div>
                                        <div className='password_condition_explain'>
                                            {matchPasswordSymbol?
                                            <div className='check_image'></div>
                                            :
                                            <div className='fail_image'></div>
                                            }
                                            <div>At least one symbol</div>
                                        </div>
                                        <div className='password_condition_explain'>
                                            {matchPasswordSpace?
                                            <div className='check_image'></div>
                                            :
                                            <div className='fail_image'></div>
                                            }
                                            <div>No Spaces</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="contained"
                        color="secondary" onClick={hideSharingDialog} className={classes.button}>
                        Close
                    </Button>
                    <Button variant="contained"
                        color="primary" onClick={createSharing} className={classes.button}>
                        Create
                    </Button>
                    </Modal.Footer>
                </Modal>
            :null}
            <Modal show={isEditLidarAnnotation} animation={false} className="modal-annotation-create">
                <Modal.Header>
					<div className='creating_component_dialog'>
						<Modal.Title>Edit Annotation</Modal.Title>
						<div className='close_button' onClick={hideAnnoationDialog}>
							<img src="/close.png" alt="close" style={{height: "100%"}}/>
						</div>
					</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='annotation_info_part'>
                        <div className='annotation_edit_info_part'>
                            <p className="project-name-title">Annotation Statistics:</p>
                            {getAnnotationStatusItems(editAnnotationObject)}
                            <div className='annotation_selection_type_label'>
                                <div className='annotation_info_label'>Annotation ID:</div>
                                <div className='annotation_info_value'>{editAnnotationObject?editAnnotationObject.id:""}</div>
                            </div>
                            <div className='annotation_selection_type_label'>
                                <div className='annotation_info_label'>Annotation By:</div>
                                <div className='annotation_info_value'>{editAnnotationObject?editAnnotationObject.last_edit_email:props.userInfo.email}</div>
                            </div>
                            {editAnnotationObject && (editAnnotationObject.type === "distance" || editAnnotationObject.type === "polyline")?
							<div className='annotation_selection_type_label'>
								<div className='annotation_info_label'>Annotation Measurements:</div>
								<div className='annotation_info_value' dangerouslySetInnerHTML={{__html: getAnnotationMeasurementsLabel()}}></div>
							</div>
							:null}
                        </div>
                        <div className='annotation_edit_info_part'>
                            <div className='project-name-box'>
                                <p className="project-name-title">Annotation Title</p>
                                <input type="text" className="project-name-input"
                                value={editAnnotationName}
                                onChange={onChangeAnnotationName}></input>
                            </div>
                            <div className='project-name-box'>
                                <p className="project-name-title">Annotation Comment</p>
                                <textarea className="project-name-input"
                                value={editAnnotationDescription}
                                onChange={onChangeAnnotationDescription}></textarea>
                            </div>
                            <div className='annotation_selection_type'>
                                <div className='selection_label'>Annotation Type</div>
                                <div className='selection_list'>
                                    <Select
                                        isMulti={false}
                                        onChange={selectAnnotationType}
                                        styles={selectStyle}
                                        options={annotationTypeList}
                                        isSearchable={false}
                                        value = {
                                            annotationTypeList.filter(function (option) {
                                                return option.value === annotationType;
                                            })
                                        }
                                    ></Select>
                                </div>
                            </div>
                            <div className='annotation_selection_type'>
                                <div className='selection_label'>Annotation Status</div>
                                <div className='selection_list'>
                                    <Select
                                        isMulti={false}
                                        onChange={selectAnnotationStatus}
                                        styles={selectStyle}
                                        options={annotationStatusList}
                                        isSearchable={false}
                                        value = {
                                            annotationStatusList.filter(function (option) {
                                                return option.value === annotationStatus;
                                            })
                                        }
                                    ></Select>
                                </div>
                            </div>
                            <div className='severity_selection_type'>
                                <div className='selection_label'>Severity Level 1-4</div>
                                <div className='selection_list'>
                                    <div className='severity_level_1'>
                                        <Radio
                                            checked={severitylevel === 1}
                                            onChange={handleChangeSeverityLevel}
                                            value="1"
                                            name="radio-buttons"
                                            sx={{
                                                color: "grey",
                                                '&.Mui-checked': {
                                                    color: "black",
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className='severity_level_2'>
                                        <Radio
                                            checked={severitylevel === 2}
                                            onChange={handleChangeSeverityLevel}
                                            value="2"
                                            name="radio-buttons"
                                            sx={{
                                                color: "grey",
                                                '&.Mui-checked': {
                                                    color: "black",
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className='severity_level_3'>
                                        <Radio
                                            checked={severitylevel === 3}
                                            onChange={handleChangeSeverityLevel}
                                            value="3"
                                            name="radio-buttons"
                                            sx={{
                                                color: "grey",
                                                '&.Mui-checked': {
                                                    color: "black",
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className='severity_level_4'>
                                        <Radio
                                            checked={severitylevel === 4}
                                            onChange={handleChangeSeverityLevel}
                                            value="4"
                                            name="radio-buttons"
                                            sx={{
                                                color: "grey",
                                                '&.Mui-checked': {
                                                    color: "black",
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained"
                        color="primary" onClick={editAnnotation} className={classes.annotation_button}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
		</div>
	);
}

const mapStateToProps = state => ({
	allDatasets : state.project.allDatasets,
	allProjects : state.project.allProjects,
	selectedProject : state.project.selectedProject,
    loadedObjects : state.project.loadedObjects,
    datasetList : state.project.datasetList,
    classificationList : state.project.classificationList,
    sortType : state.project.sortType,
    sortOrder : state.project.sortOrder,
    userInfo : state.global.userInfo,
});

ProjectPanel.propTypes = {
}
export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, projectAction),
)(ProjectPanel);
