import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { globalAction } from '../../store/actions';
import { projectAction } from '../../store/actions';
import { mapAction } from '../../store/actions';
import './style.scss';

import * as api from '../../utils/api';

import {Modal} from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { Menu, MenuItem } from '@material-ui/core';

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { DataGrid, useFirstRender } from '@mui/x-data-grid';
import { ImageList, ImageListItem, Pagination, Stack, Switch } from '@mui/material';
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import ImageDownArrow from '../../assets/images/image_down_arrow.png';
import ImageLeftArrow from '../../assets/images/image_left_arrow.png';
import ImageUpArrow from '../../assets/images/image_up_arrow.png';
import ImageRightArrow from '../../assets/images/image_right_arrow.png';

import ImageDownArrowSelection from '../../assets/images/image_down_arrow_selection.png';
import ImageLeftArrowSelection from '../../assets/images/image_left_arrow_selection.png';
import ImageUpArrowSelection from '../../assets/images/image_up_arrow_selection.png';
import ImageRightArrowSelection from '../../assets/images/image_right_arrow_selection.png';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Select from 'react-select';
import Radio from '@mui/material/Radio';
import * as mapUtils from '../MapView/MapUtils';
import { map, tileLayer, Browser, marker, circle } from 'leaflet';

import { Checkbox, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Radio as RadioCore } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from "react-datepicker";

import { toast } from 'react-toastify';

const CustomCheckbox = withStyles({
	root: {
		color: '#67757c',
		'&$checked': {
			color: '#67757c',
		},
	}
})(props => <Checkbox color="default" {...props} />);

const selectStyle = {
    control: (base, state) => ({
      ...base,
      background: "#17181b",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: "#323232",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: "white"
      },
      color: 'white',
	  height: 40,
      textAlign: 'left',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
    input: base => ({
      ...base,
      color: "white"
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: isFocused?"#27282b":"#17181b",
      color : "white",
      zIndex: 10000000000,
      textAlign: 'left',
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
	  zIndex: 9999
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
	  border: "1px solid white"
    })
};

const useStyles = makeStyles(theme => ({
	view: {
		position: 'relative',
		width: '100%',
		height: '100vh',
		overflow: 'hidden',
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	viewShift: {
		position: 'relative',
		height: '100vh',
		overflow: 'hidden',
		width: `100%`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20
    },
	annotation_button: {
        fontSize: 15,
        fontWeight: 400,
        marginRight: 20,
		marginTop: 20,
    },
	measure_done_button: {
        fontSize: 12,
        fontWeight: 300,
		display: "flex"
    },
}));

const annotationDrawList = [
	{
		value : "",
		label: "None",
	},
	{
		value : "image_point",
		label: "Marker",
	},
	{
		value : "image_polyline",
		label: "PolyLine",
	},
	{
		value : "image_polygon",
		label: "Polygon",
	},
	{
		value : "image_up_arrow",
		label: "Up Arrow",
	},
	{
		value : "image_down_arrow",
		label: "Down Arrow",
	},
	{
		value : "image_left_arrow",
		label: "Left Arrow",
	},
	{
		value : "image_right_arrow",
		label: "Right Arrow",
	},
];

const columns = [
	{ field: 'icon', headerName: '', width: 40, sortable: false, headerClassName: 'annotation_table_class',
		renderCell: (cellValue) => {
		return (
			<div className="small_icon"><div className='icon annotation_icon'></div></div>
		);
		}
	},
    { field: 'name', headerName: 'Name', width: 150, sortable: false, headerClassName: 'annotation_table_class'},
    { field: 'description', headerName: 'Description', width: 150, sortable: false, headerClassName: 'annotation_table_class' },
    { field: 'type', headerName: 'Type', width: 40, sortable: false, headerClassName: 'annotation_table_class',
		renderCell: (cellValue) => {
			if (cellValue.value === "image_point") {
				return (
					<div className="small_icon"><div className='icon marker_icon'></div></div>
				);
			}
			else if (cellValue.value === "image_polyline") {
				return (
					<div className="small_icon"><div className='icon measurement_polyline_icon'></div></div>
				);
			}
			return (
				<div className="small_icon"><div className='icon polygon_icon'></div></div>
			);
		}
	},
	{ field: 'date', headerName: 'Date', width: 100, sortable: false, headerClassName: 'annotation_table_class' },
    { field: 'action', headerName: 'Action', width: 130, sortable: false, headerClassName: 'annotation_table_class',
		renderCell: (cellValue) => {
			return (
				<div>
					<div className="small_icon can_hover_icon" onClick={cellValue.value.onDelete} data-id={cellValue.value.id}>
						<div className='icon delete_icon'></div>
					</div>
					<div className="small_icon can_hover_icon" onClick={cellValue.value.onVisible} data-id={cellValue.value.id}>
						{cellValue.value.visible? <div className='icon visible_icon'></div> : <div className='icon invisible_icon'></div>}
					</div>
					<div className="small_icon can_hover_icon" onClick={cellValue.value.onEdit} data-id={cellValue.value.id}>
					<div className='icon edit_icon'></div>
					</div>
				</div>
			);
		}
	},
];

function useReferredState(initialValue) {
    const [state, setState] = React.useState(initialValue);
    const reference = React.useRef(state);

    const setReferredState = value => {
        reference.current = value;
        setState(value);
    };

    return [reference, setReferredState];
}

const sortTypeList = [
	{
		value : "name",
		label: "Name",
	},
	{
		value : "date",
		label: "Date",
	},
];

const sortOrderList = [
	{
		value : "asc",
		label: "Ascending",
	},
	{
		value : "desc",
		label: "Descending",
	}
];

function ImageAnnotationView(props) {
	/* state variables */
	const classes = useStyles();

	let projectID;
    let datasetID;
    let fileID;

	if (api.getParameterByName("project_id") && api.getParameterByName("project_id") !== "") {
		projectID = api.getParameterByName("project_id");
	}

    if (api.getParameterByName("dataset_id") && api.getParameterByName("dataset_id") !== "") {
		datasetID = api.getParameterByName("dataset_id");
	}

    if (api.getParameterByName("file_id") && api.getParameterByName("file_id") !== "") {
		fileID = api.getParameterByName("file_id");
	}

    if (!datasetID || !projectID) {
        props.history.goBack();
    }

	const [imageList, setImageList] = React.useState(null);
	const [annotationList, setAnnotationList] = React.useState([]);

	const [selectedIndex, setSelectedIndex] = React.useState(-1);
	const [drawingMode, setDrawingMode] = useReferredState("");
	const [canvasEvents, setCanvasEvents] = React.useState([]);
	const [transformElement, setTransformElement] = useReferredState(null);
	const [windowResolution, setWindowResolution] = React.useState([window.innerWidth, window.innerHeight]);
	const [currentImage, setCurrentImage] = useReferredState(null);
	const [shouldCenter, setShouldCenter] = React.useState(false);

	const [anchorFilter, setAnchorFilter] = React.useState(null);
	const isFilterMenuOpen = Boolean(anchorFilter);

	const [isCreatingAnnotation, setCreatingAnnotation] = useReferredState(false);
	const [creatingAnnotationName, setCreatingAnnotationName] = React.useState("");
    const [creatingAnnotationDescription, setCreatingAnnotationDescription] = React.useState("");

	const [tableRows, setTableRows] = React.useState([]);
	const [annotationCountMap, setAnnotationCountMap] = React.useState(new Map());

	const [leftArrowImage, setLeftArrowImage] = useReferredState(null);
	const [rightArrowImage, setRightArrowImage] = useReferredState(null);
	const [upArrowImage, setUpArrowImage] = useReferredState(null);
	const [downArrowImage, setDownArrowImage] = useReferredState(null);

	const [leftArrowImageSelection, setLeftArrowImageSelection] = useReferredState(null);
	const [rightArrowImageSelection, setRightArrowImageSelection] = useReferredState(null);
	const [upArrowImageSelection, setUpArrowImageSelection] = useReferredState(null);
	const [downArrowImageSelection, setDownArrowImageSelection] = useReferredState(null);

	const [autoCreateAnnotation, setAutoCreateAnnotation] = React.useState(false);
	const [annotationType, setAnnotationType] = React.useState("other");
	const [annotationStatus, setAnnotationStatus] = React.useState("0");
	const [severitylevel, setSeverityLevel] = useReferredState(1);
	const [inspectlevel, setInspectLevel] = React.useState(0);

	const [allVisible, setAllVisible] = React.useState(true);
	const [annotationSelected, setAnnotationSelected] = React.useState(-1);

	const [currentImagePage, setCurrentImagePage] = React.useState(1);
    const [totalImagePage, setTotalImagePage] = React.useState(1);
    const [itemCountPage, setItemCountPage] = React.useState(50);

	const [leafletMap, setLeafletMap] = React.useState(null);
	const [imageMapMarker, setImageMapMarker] = React.useState(null);
	const [showImageList, setShowImageList] = React.useState(true);
	const [isShowImageThumb, setShowImageThumb] = React.useState(false);
	const [imageSearch, setImageSearch] = React.useState("");

	const [annotationUpdating, setAnnotationUpdating] = React.useState(false);

	const [isShowingMenu, setShowingMenu] = React.useState(true);
	const [filterArrays, setFilterArrays] = React.useState([0, 2, 3, 4, 5]);
	const [filterTypeArrays, setFilterTypeArrays] = React.useState([]);
	const [filterStatusArrays, setFilterStatusArrays] = React.useState(["not_annotated"]);

	const [isDelete, setDeleteDialog] = useReferredState(false);
	const [deleteTitle, setDeleteTitle] = React.useState("");
	const [deleteMessage, setDeleteMessage] = React.useState("");

	const [keyCodeEvent, setKeyCodeEvent] = React.useState("");
	const [minScale, setMinScale] = React.useState(1.0);
	const [fitWidthMode, setFitWidthMode] = React.useState(true);

	const [isImageThumMode, setImageThumbMode] = React.useState(false);

	const [filterOption, setFilterOption] = React.useState("annotation");

	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(null);

	const [annotationTypeList, setAnnotationTypeList] = React.useState([]);
    const [annotationStatusList, setAnnotationStatusList] = React.useState([]);

	const [isExpandedDateFilter, setExpandedDateFilter] = React.useState(false);
	const [isExpandedTypeFilter, setExpandedTypeFilter] = React.useState(false);
	const [isExpandedStatusFilter, setExpandedStatusFilter] = React.useState(false);
	const [isExpandedSeverityFilter, setExpandedSeverityFilter] = React.useState(false);
	const [isExpandedTextFilter, setExpandedTextFilter] = React.useState(false);

	const [sortType, setSortType] = React.useState("date");
	const [sortOrder, setSortOrder] = React.useState("asc");

	const [checkedAll, setTypeCheckAll] = React.useState(true);

	const [isThreePointReference, setThreePointReference] = useReferredState(true);
	const [measureUnit, setMeasureUnit] = useReferredState(props.userInfo.measurement_units);
	const [imageMeasureReference, setImageMeasureReference] = useReferredState([]);
	const [selectedMeasure, setSelectedMeasure] = useReferredState(-1);

	const [isMeasureNameChange, setMeasureNameChange] = React.useState(false);
	const [measureSelectedName, setMeasureSelectedName] = React.useState("");
	const [measureSelectedValue, setMeasureSelectedValue] = React.useState("");

	function handleImagePage(event, value) {
        setCurrentImagePage(value);
    }

	function goPrevFastPage() {
		if (currentImagePage <= 10) {
			setCurrentImagePage(1);
		}
        else {
			setCurrentImagePage(currentImagePage - 10);
		}
    }

	function goNextFastPage() {
		if (currentImagePage >= totalImagePage - 10) {
			setCurrentImagePage(totalImagePage);
		}
        else {
			setCurrentImagePage(currentImagePage + 10);
		}
    }

	function getArrowImages() {
		let left_arrow_image = new Image();
		left_arrow_image.onload = function() {
			setLeftArrowImage(left_arrow_image);
		};
		left_arrow_image.src = ImageLeftArrow;

		let right_arrow_image = new Image();
		right_arrow_image.onload = function() {
			setRightArrowImage(right_arrow_image);
		};
		right_arrow_image.src = ImageRightArrow;

		let up_arrow_image = new Image();
		up_arrow_image.onload = function() {
			setUpArrowImage(up_arrow_image);
		};
		up_arrow_image.src = ImageUpArrow;

		let down_arrow_image = new Image();
		down_arrow_image.onload = function() {
			setDownArrowImage(down_arrow_image);
		};
		down_arrow_image.src = ImageDownArrow;

		// Selection Images
		let left_arrow_image_selection = new Image();
		left_arrow_image_selection.onload = function() {
			setLeftArrowImageSelection(left_arrow_image_selection);
		};
		left_arrow_image_selection.src = ImageLeftArrowSelection;

		let right_arrow_image_selection = new Image();
		right_arrow_image_selection.onload = function() {
			setRightArrowImageSelection(right_arrow_image_selection);
		};
		right_arrow_image_selection.src = ImageRightArrowSelection;

		let up_arrow_image_selection = new Image();
		up_arrow_image_selection.onload = function() {
			setUpArrowImageSelection(up_arrow_image_selection);
		};
		up_arrow_image_selection.src = ImageUpArrowSelection;

		let down_arrow_image_selection = new Image();
		down_arrow_image_selection.onload = function() {
			setDownArrowImageSelection(down_arrow_image_selection);
		};
		down_arrow_image_selection.src = ImageDownArrowSelection;
	}

	useEffect(() => {
		if (api.isSharing() && !window.share_password && window.hasSharePassword) {
			props.history.push("/" + props.location.search);
			return;
		}
		getArrowImages();
		getImageList();
		getAnnnotationList();
		function handleResize() {
			setWindowResolution([window.innerWidth, window.innerHeight]);
		};

		document.addEventListener('keydown', keyDownListener, false);
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize)
			document.removeEventListener('keydown', keyDownListener);
		}
	}, []);

	useEffect(() => {
		if (keyCodeEvent === "") return;

		if (imageList && imageList.files && imageList.files.length > 0) {
			if (keyCodeEvent === "up") {
				prevImage()
			}
			else if (keyCodeEvent === "down") {
				nextImage()
			}
			else if (keyCodeEvent === "left") {
				prevCheckImage()
			}
			else if (keyCodeEvent === "right") {
				nextCheckImage();
			}
		}

		setKeyCodeEvent("");
	}, [keyCodeEvent]);

	useEffect(() => {
		let annotationTypeArrays = [];
		let annotationStatusArrays = [];
        if (props.userInfo.annotation_type_list === "" || !props.userInfo.annotation_type_list) {
            annotationTypeArrays = mapUtils.generateAnnotationTypeList();
        }
        else {
			annotationTypeArrays = JSON.parse(props.userInfo.annotation_type_list);

        }
		setAnnotationTypeList(annotationTypeArrays);
		setFilterTypeArrays(annotationTypeArrays.map((annotationType) => annotationType.value));

        if (props.userInfo.annotation_status_list === "" || !props.userInfo.annotation_status_list) {
            annotationStatusArrays = mapUtils.generateAnnotationStatusList();
        }
        else {
			annotationStatusArrays = JSON.parse(props.userInfo.annotation_status_list);

        }

		setAnnotationStatusList(annotationStatusArrays);
		let filterArray = annotationStatusArrays.map((annotationStatus) => annotationStatus.value);
		filterArray.push("not_annotated");
		setFilterStatusArrays(filterArray);
	}, [props.userInfo]);

	function canKeyCodeAction() {
		if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA") return false;
		return !isDelete.current && !isCreatingAnnotation.current;
	}

	function keyDownListener(event) {
		if (!canKeyCodeAction()) return;
		if (event.keyCode === 37) { // Left Key
			setKeyCodeEvent("left");
		}
		else if (event.keyCode === 39) { // Right Key
			setKeyCodeEvent("right");
		}
		else if (event.keyCode === 40) { // Up Key
			setKeyCodeEvent("down");
		}
		else if (event.keyCode === 38) { // Down Key
			setKeyCodeEvent("up");
		}
	}

	useEffect(() => {
		if (imageList === null || selectedIndex < 0) return;
		let filterList = imageList.files.filter(function(value) {
			return filterArrays.indexOf(value.inspect) >= 0 && isInListBySearch(value) && hasAnnotation(value);
		});
		let totalPage = Math.ceil(filterList.length / itemCountPage);
		setTotalImagePage(totalPage);

		let pageIndex = filterList.findIndex(function(value) {
			return value.id + "" === imageList.files[selectedIndex].id + "";
		});
		if (pageIndex >= 0)
			setCurrentImagePage(Math.floor(pageIndex / itemCountPage) + 1);
		if (currentImagePage > totalPage) {
			setCurrentImagePage(1);
		}
	}, [filterArrays, imageSearch]);

	useEffect(() => {
		if (imageList === null) return;
		let orderedImageList = sortImageList(imageList);
		setImageList(orderedImageList);
		if (fileID) {
			for (let i = 0; i < orderedImageList.files.length; i ++) {
				if (orderedImageList.files[i].id + "" === fileID + "") {
					setSelectImageItem(orderedImageList, i);
				}
			}
		}

		if (!fileID) {
			setSelectImageItem(orderedImageList, 0);
		}

	}, [sortOrder, sortType]);

	function initMapViewer(force) {
		if (leafletMap && !force) return;
		let mapContainer = document.getElementById("map_container");
		if (!mapContainer) return;

		const initialState = {
			lng: 11,
			lat: 49,
			zoom: 4,
		};

		const creatingMap = map(mapContainer, {zoomControl: false}).setView(
			[initialState.lat, initialState.lng],
			initialState.zoom
		);

		const myAPIKey = '023f31a7ebc0492dac8042ef6a1d83ae';
		const isRetina = Browser.retina;
		var baseUrl = `https://maps.geoapify.com/v1/tile/osm-liberty/{z}/{x}/{y}.png?apiKey=${myAPIKey}`;
		var retinaUrl = `https://maps.geoapify.com/v1/tile/osm-liberty/{z}/{x}/{y}@2x.png?apiKey=${myAPIKey}`;

		tileLayer(isRetina ? retinaUrl : baseUrl, {
			attribution:
				'PoleHawk',
			maxZoom: 20,
			id: 'osm-liberty',
		}).addTo(creatingMap);

		setLeafletMap(creatingMap);
	}

	function gotoImageLocation() {
		if (!leafletMap) return;
		let imageFileItem = imageList.files[selectedIndex];
		let locationInfo = mapUtils.getImageLocation(imageFileItem.location);

		if (imageMapMarker) {
			imageMapMarker.removeFrom(leafletMap);
		}

		if (locationInfo.longitude !== null && locationInfo.latitude !== null) {
			let image_marker = circle([locationInfo.latitude, locationInfo.longitude], {
				radius: 20,
				color: mapUtils.getInspectColor(imageFileItem.inspect),
				fillColor: mapUtils.getInspectColor(imageFileItem.inspect),
				fill: true
			}).addTo(leafletMap);
			setImageMapMarker(image_marker);
			leafletMap.setView([locationInfo.latitude, locationInfo.longitude], 15);

			window.map_longitude = locationInfo.longitude;
			window.map_latitude = locationInfo.latitude;
			if (locationInfo.altitude) {
				window.map_altitude = locationInfo.altitude;
			}
			else {
				window.map_altitude = 0;
			}
		}
		else {
			setImageMapMarker(imageMapMarker);
		}
	}

	useEffect(() => {
		updateCanvasSize();
		drawCanvas();
		updateVisibleStatus();
		initMapViewer();
		updateAnnotationTable();
		if (shouldCenter) {
			setShouldCenter(false);
			if (transformElement && transformElement.current) {
				transformElement.current.resetTransform();
				transformElement.current.centerView(1.0);
				setMinScaleFromComponent();
			}
		}
	}, [windowResolution, currentImage.current, annotationList, annotationSelected, isShowingMenu, selectedMeasure.current, imageMeasureReference.current, isThreePointReference.current]);

	useEffect(() => {
		setupCanvasEvent();
	}, [annotationList, currentImage.current]);

	useEffect(() => {
		let isUpdateReference = false;
		if (annotationList && annotationSelected !== -1) {
			let annotation = annotationList[annotationSelected];
			if (annotation.type === "image_measure_line") {
				if (annotation.measurements_list && annotation.measurements_list !== "") {
					let measurements_list = JSON.parse(annotation.measurements_list);
					setThreePointReference(measurements_list["three_point_reference"]);
					setImageMeasureReference(measurements_list["measure_reference_label"]);
					setMeasureUnit(measurements_list["measure_reference_unit"]);
					isUpdateReference = true;
				}
			}
		}
		if (!isUpdateReference) {
			setImageMeasureReference([]);
			setSelectedMeasure(-1);
			setMeasureUnit(props.userInfo.measurement_units);
		}

	}, [annotationSelected, annotationList]);

	useEffect(() => {
		gotoImageLocation();
	}, [leafletMap, currentImage.current]);

	useEffect(() => {
		setShouldCenter(true);
		updateImageViewer(true);
	}, [selectedIndex, imageList]);

	useEffect(() => {
		setShouldCenter(true);
		if (leafletMap) {
			leafletMap.off();
			leafletMap.remove();
			initMapViewer(true);
		}
		updateImageViewer(true, true);
	}, [isImageThumMode]);

	useEffect(() => {
		const onKeyEvent = (event) => {
			if (event.key === "Escape") {
				if (isDelete.current) {
					hideDeleteDialog();
				}
				else if (isCreatingAnnotation.current) {
					hideAnnoationDialog();
				}
				else if (isMeasureNameChange && imageMeasureReference.current.length > selectedMeasure.current && selectedMeasure.current !== -1) {
					hideNameChangeDialog();
				}
			}
			else if (event.key === "Enter") {
				if (isDelete.current) {
					deleteRun();
				}
				else if (isCreatingAnnotation.current) {
					if (annotationUpdating) {
						updateAnnotation();
					}
					else {
						createAnnotation();
					}
				}
				else if (isMeasureNameChange && imageMeasureReference.current.length > selectedMeasure.current && selectedMeasure.current !== -1) {
					let measurements = JSON.parse(JSON.stringify(imageMeasureReference.current));
					measurements[selectedMeasure.current].label = measureSelectedName;
					measurements[selectedMeasure.current].value = parseFloat(measureSelectedValue);
					setImageMeasureReference(measurements);
					hideNameChangeDialog();
				}
			}
		};

		window.addEventListener("keydown", onKeyEvent);

		return () => {
			window.removeEventListener("keydown", onKeyEvent);
		}
	}, [isMeasureNameChange, annotationUpdating, measureSelectedValue, measureSelectedName, measureSelectedValue, creatingAnnotationName, creatingAnnotationDescription, annotationSelected, annotationType, annotationStatus]);

	function updateImageViewer(resetImage, force) {
		if (!imageList || imageList.files.length <= selectedIndex || selectedIndex < 0) {
			return;
		}
		let isResize = resetImage && imageList.files[selectedIndex].width > mapUtils.defaultImageSize;
		let imageLink = mapUtils.getImagePathLink(imageList.dataset, imageList.files[selectedIndex].name, !isResize, isResize, mapUtils.defaultImageSize);

		if (currentImage && currentImage.current && currentImage.current.src === imageLink && !force) return;

		if (resetImage) {
			clearCanvas();
		}

		let img = new Image();
		let loadIndex = selectedIndex;
		img.onload = function(){
			if (selectedIndex >= 0 && loadIndex === selectedIndex) {
				setCurrentImage(img);
			}
		};
		img.src = imageLink;
		setInspectLevel(imageList.files[selectedIndex].inspect);
		preloadImage(selectedIndex);
		scrollIntoViewMethod("selected_image_item", "image_scroll_list");
			scrollIntoViewMethod("selected_image_no_thumb_item", "image_scroll_list");

		if (isShowImageThumb) {
			scrollIntoViewMethod("selected_image_item", "image_scroll_list");
		}
		else {
			scrollIntoViewMethod("selected_image_no_thumb_item", "image_scroll_list");
		}
	}

	useEffect(() => {
		setTimeout(function() {
			if (isShowImageThumb) {
				scrollIntoViewMethod("selected_image_item", "image_scroll_list");
			}
			else {
				scrollIntoViewMethod("selected_image_no_thumb_item", "image_scroll_list");
			}
		}, 100);
	}, [isShowImageThumb]);

	function updateVisibleStatus() {
		let exitAnnotation = false;
		for (let i = 0; i < annotationList.length; i ++) {
			if ("" + annotationList[i].file_id !== "" + fileID) continue;
			exitAnnotation = true;
			if (annotationList[i].visible) {
				setAllVisible(true);
				return;
			}
		}

		if (exitAnnotation)
			setAllVisible(false);
	}

	function updateAnnotationTable() {
		let rows = [];
		let annotationCount = new Map();

		for (let i = 0; i < annotationList.length; i ++) {
			let annotationFileID = "" + annotationList[i].file_id;
			let currentCount = annotationCount.get(annotationFileID);
			if (currentCount !== undefined) {
				annotationCount.set(annotationFileID, currentCount + 1);
			}
			else {
				annotationCount.set(annotationFileID, 1);
			}
			if (annotationFileID !== "" + fileID) continue;
			rows.push({
				id : i,
				icon: "",
				name: annotationList[i].name,
				description: annotationList[i].description,
				type : annotationList[i].type,
				date: annotationList[i].updated_at.substring(0, 10),
				action: {
					id: i,
					visible: annotationList[i].visible,
					onDelete: function(event) {
						if (event.currentTarget && event.currentTarget.dataset.id) {
							deleteAnnotationByDialog(parseInt(event.currentTarget.dataset.id));
						}
					},
					onVisible: function(event) {
						if (event.currentTarget && event.currentTarget.dataset.id) {
							updateVisibleAnnotation(parseInt(event.currentTarget.dataset.id));
						}
					},
					onEdit: function(event) {
						if (event.currentTarget && event.currentTarget.dataset.id) {
							showCreateAnnotationDilaog(true, parseInt(event.currentTarget.dataset.id));
						}
					}
				}
			});
		}

		setTableRows(rows);
		setAnnotationCountMap(annotationCount);
	}

	const getClientOffset = (event) => {
		if (!currentImage.current) return {
			x: 0,
			y : 0
		};
		let image_part = document.getElementById('drawing_part');
		let canvas = document.getElementById('drawing_canvas');
		const {pageX, pageY} = event.touches ? event.touches[0] : event;
		const x = ((pageX - image_part.offsetLeft - transformElement.current.state.positionX) * canvas.width / canvas.clientWidth) / transformElement.current.state.scale;
		const y = ((pageY - image_part.offsetTop - transformElement.current.state.positionY) * canvas.height / canvas.clientHeight) / transformElement.current.state.scale;

		return {
		   x,
		   y
		}
	}

	useEffect(() => {
		if (autoCreateAnnotation) {
			createAnnotationOnServer(window.addingAnnotation.label, "", true);
			setAutoCreateAnnotation(false);
		}
	}, [autoCreateAnnotation]);

	function showCreateAnnotationDilaog(isUpdating, selectedAnnotation) {
		if (isUpdating) {
			setAnnotationUpdating(true);
			setAnnotationSelected(selectedAnnotation);
			setCreatingAnnotation(true);
			setCreatingAnnotationName(annotationList[selectedAnnotation].name?annotationList[selectedAnnotation].name:"");
			setCreatingAnnotationDescription(annotationList[selectedAnnotation].description?annotationList[selectedAnnotation].description:"");
			setAnnotationType(annotationList[selectedAnnotation].annotation_type);
			setAnnotationStatus(annotationList[selectedAnnotation].annotation_status);
			setSeverityLevel(annotationList[selectedAnnotation].severity_level);
		}
		else if (window.addingAnnotation) {
			if (props.userInfo.enable_annotation_popup) {
				setAnnotationUpdating(false);
				setCreatingAnnotation(true);
				setCreatingAnnotationName(window.addingAnnotation.label);
				setCreatingAnnotationDescription("");
			}
			else {
				setAutoCreateAnnotation(!autoCreateAnnotation);
			}
		}

	}

	function mapExtend() {
		setImageThumbMode(!isImageThumMode);
	}

	function onChangeAnnotationName(evt) {
        setCreatingAnnotationName(evt.target.value);
    }

	function onChangeSearchImage(evt) {
        setImageSearch(evt.target.value);
    }

    function onChangeAnnotationDescription(evt) {
        setCreatingAnnotationDescription(evt.target.value);
    }

	function setAddingAnnotation(annotation) {
		window.addingAnnotation = annotation;
		drawCanvas();
	}

	function hideNameChangeDialog() {
		setMeasureNameChange(false);
		setMeasureSelectedName("");
		setMeasureSelectedValue("");
	}

    function hideAnnoationDialog() {
        setCreatingAnnotation(false);
		setAnnotationUpdating(false);
		setAnnotationStatus("0");
		setAddingAnnotation(null);
		setImageMeasureReference([]);
		setDrawing("");
		setSelectedMeasure(-1);
    }

    function createAnnotation() {
		createAnnotationOnServer(creatingAnnotationName, creatingAnnotationDescription);
		hideAnnoationDialog();
    }

	function updateAnnotation() {
		if (annotationSelected !== -1) {
			updateAnnotationOnServer(creatingAnnotationName, creatingAnnotationDescription, annotationSelected);
			hideAnnoationDialog();
		}
    }

	function updateInspectByAnnotation(annotations) {
		if (!annotations) changeInspectCurrent(0);
		let filterAnnotations = annotations.filter(function(value) {
			return "" + value.file_id === "" + fileID
		});

		let severity_level = 6;
		for (let i = 0; i < filterAnnotations.length; i ++) {
			severity_level = Math.min(severity_level, filterAnnotations[i].severity_level)
		}

		if (severity_level === 1) {
			changeInspectCurrent(5);
		}
		else if (severity_level === 2) {
			changeInspectCurrent(4);
		}
		else if (severity_level === 3) {
			changeInspectCurrent(3);
		}
		else if (severity_level === 4) {
			changeInspectCurrent(2);
		}
		else if (severity_level === 5) {
			changeInspectCurrent(1);
		}
		else {
			changeInspectCurrent(0);
		}
	}

	async function createAnnotationOnServer(name, description, autoCreate) {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			let positions = [];
			if (window.addingAnnotation.type === "image_point"
				|| window.addingAnnotation.type === "image_up_arrow"
				|| window.addingAnnotation.type === "image_down_arrow"
				|| window.addingAnnotation.type === "image_left_arrow"
				|| window.addingAnnotation.type === "image_right_arrow") {
				let position = window.addingAnnotation.position;
				positions.push(position.x / window.imageRate);
				positions.push(position.y / window.imageRate);
			}
			else {
				for (let i = 0; i < window.addingAnnotation.positions.length; i ++) {
					let position = window.addingAnnotation.positions[i];
					positions.push(position.x / window.imageRate);
					positions.push(position.y / window.imageRate);
				}
			}
			let measurements_list = "";
			if (window.addingAnnotation.type === "image_measure_line") {
				measurements_list = new Object();
				measurements_list.three_point_reference = isThreePointReference.current;
				measurements_list.measure_reference_label = imageMeasureReference.current;
				measurements_list.measure_reference_unit = props.userInfo.measurement_units;
				measurements_list = JSON.stringify(measurements_list);
			}
            let response = await api.createImageAnnotation({token: userToken,
				project_id: projectID,
				dataset_id: datasetID,
				file_id: fileID,
				name: name,
				description: description,
				type: window.addingAnnotation.type,
				positions: JSON.stringify(positions),
				severity_level: severitylevel.current,
				annotation_type: annotationType,
				annotation_status: annotationStatus,
				measurements_list: measurements_list
			});

            if (response.data && !response.data.error) {
                setAddingAnnotation(null);
				let annotations = [...annotationList];
				annotations.push(response.data);
				setAnnotationList(annotations);
				updateInspectByAnnotation(annotations);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

	async function updateAnnotationOnServer(name, description, index) {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
			let measurements_list = "";
			if (annotationList[index].type === "image_measure_line") {
				measurements_list = new Object();
				measurements_list.three_point_reference = isThreePointReference.current;
				measurements_list.measure_reference_label = imageMeasureReference.current;
				measurements_list.measure_reference_unit = measureUnit.current;
				measurements_list = JSON.stringify(measurements_list);
			}

            let response = await api.updateAnnotation({
				id: annotationList[index].id,
				name: name,
				type: annotationList[index].type,
				description: description,
				positions: annotationList[index].positions,
				token: userToken,
				severity_level: severitylevel.current,
				annotation_type: annotationType,
				annotation_status: annotationStatus,
				measurements_list: measurements_list
			});

            if (response.data && !response.data.error) {
				let annotations = [...annotationList];
				annotations[index] = response.data;
				setAnnotationList(annotations);
				updateInspectByAnnotation(annotations);
            }
            else {
                redirectToLogin();
            }
        }
        else {
            redirectToLogin();
        }
	}

	const mouseDownListener = (event) =>{
	}

	const mouseMoveListener = (event) =>{
		if (drawingMode.current === "image_up_arrow" || drawingMode.current === "image_down_arrow" ||
			drawingMode.current === "image_left_arrow" || drawingMode.current === "image_right_arrow") {
			if (window.addingAnnotation && window.addingAnnotation.type === drawingMode.current) {
				let annotation = JSON.parse(JSON.stringify(window.addingAnnotation));
				annotation.position = getClientOffset(event);
				setAddingAnnotation(annotation);
			}
			else {
				setAddingAnnotation({
					label: "Untitled Arrow",
					type: drawingMode.current,
					position: getClientOffset(event)
				});
			}
		}
		else if (drawingMode.current === "image_polyline") {
			if (window.addingAnnotation && window.addingAnnotation.type === "image_polyline") {
				let annotation = JSON.parse(JSON.stringify(window.addingAnnotation));
				annotation.positions[annotation.positions.length - 1] = getClientOffset(event);
				setAddingAnnotation(annotation);
			}
		}
		else if (drawingMode.current === "image_polygon") {
			if (window.addingAnnotation && window.addingAnnotation.type === "image_polygon") {
				let annotation = JSON.parse(JSON.stringify(window.addingAnnotation));
				annotation.positions[annotation.positions.length - 1] = getClientOffset(event);
				setAddingAnnotation(annotation);
			}

		}
	}

	const rightClickListener = (event) =>{
		event.preventDefault();
		if (drawingMode.current === "image_polyline") {
			if (window.addingAnnotation && window.addingAnnotation.type === "image_polyline") {
				let annotation = JSON.parse(JSON.stringify(window.addingAnnotation));
				annotation.positions.pop();
				setAddingAnnotation(annotation);
				setDrawing("")
				showCreateAnnotationDilaog();
			}

		}
		else if (drawingMode.current === "image_polygon") {
			if (window.addingAnnotation && window.addingAnnotation.type === "image_polygon") {
				let annotation = JSON.parse(JSON.stringify(window.addingAnnotation));
				annotation.positions.pop();
				setAddingAnnotation(annotation);
				setDrawing("");
				showCreateAnnotationDilaog();
			}

		}
	}

	const mouseupListener = (event) =>{
		let position = getClientOffset(event);
		if (drawingMode.current === "") {
			let selectedIndex = selectCanvasIndex(position.x, position.y);
			setAnnotationSelected(selectedIndex);
		}

		if (drawingMode.current === "image_point") {
			setAddingAnnotation({
				label: "Untitled Point",
				type: "image_point",
				position: position
			});
			setDrawing("");
			showCreateAnnotationDilaog();
		}
		else if (drawingMode.current === "image_up_arrow" || drawingMode.current === "image_down_arrow" ||
			drawingMode.current === "image_left_arrow" || drawingMode.current === "image_right_arrow") {
			if (window.addingAnnotation && window.addingAnnotation.type === drawingMode.current) {
				setDrawing("");
				showCreateAnnotationDilaog();
			}
			else {
				setAddingAnnotation({
					label: "Untitled Arrow",
					type: drawingMode.current,
					position: position
				});
				setDrawing("");
				showCreateAnnotationDilaog();
			}
		}
		else if (drawingMode.current === "image_polyline") {
			let annotation;
			if (window.addingAnnotation && window.addingAnnotation.type === "image_polyline") {
				annotation = JSON.parse(JSON.stringify(window.addingAnnotation));
				annotation.positions.push(position);
			}
			else {
				annotation = {
					label : "Untitled Line",
					type: "image_polyline",
					positions: [position, position]
				}
			}
			setAddingAnnotation(annotation);
		}
		else if (drawingMode.current === "image_polygon") {
			let annotation;
			if (window.addingAnnotation && window.addingAnnotation.type === "image_polygon") {
				annotation = JSON.parse(JSON.stringify(window.addingAnnotation));
				annotation.positions.push(position);
			}
			else {
				annotation = {
					label : "Untitled Polygon",
					type: "image_polygon",
					positions: [position, position]
				}
			}
			setAddingAnnotation(annotation);
		}
		else if (drawingMode.current === "image_measure_line") {
			let annotation;
			if (window.addingAnnotation && window.addingAnnotation.type === "image_measure_line") {
				annotation = JSON.parse(JSON.stringify(window.addingAnnotation));
				if (selectedMeasure.current !== -1 && annotation.positions.length > selectedMeasure.current) {
					annotation.positions[selectedMeasure.current] = position;
				}
				else {
					annotation.positions.push(position);
				}
			}
			else {
				annotation = {
					label : "Untitled Measure",
					type: "image_measure_line",
					positions: [position]
				}
			}
			setAddingAnnotation(annotation);


			let measurements = JSON.parse(JSON.stringify(imageMeasureReference.current));
			if (selectedMeasure.current !== -1 && annotation.positions.length > selectedMeasure.current) {
				setImageMeasureReference(measurements);
			}
			else {
				if (annotation.positions.length === 1) {
					measurements.push({
						label: "Reference-Start",
						value: 0
					});
				}
				else if (isThreePointReference.current) {
					if (annotation.positions.length === 2) {
						measurements.push({
							label: "Reference-Center",
							value: 0
						});
					}
					else if (annotation.positions.length === 3) {
						measurements.push({
							label: "Reference-End",
							value: 0
						});
					}
					else {
						measurements.push({
							label: "Measure-" + (annotation.positions.length - 3),
							value: 0
						});
					}
				}
				else {
					if (annotation.positions.length === 2) {
						measurements.push({
							label: "Reference-End",
							value: 0
						});
					}
					else {
						measurements.push({
							label: "Measure-" + (annotation.positions.length - 2),
							value: 0
						});
					}
				}
				setImageMeasureReference(measurements);
			}
		}
	}

	function setupCanvasEvent() {
		let canvas = document.getElementById('drawing_canvas');
		if (!canvas) return;
		if (canvasEvents.length > 0) {
			canvas.removeEventListener('mousedown', canvasEvents[0]);
			canvas.removeEventListener('mousemove', canvasEvents[1]);
			canvas.removeEventListener('mouseup', canvasEvents[2]);
			canvas.removeEventListener('contextmenu', canvasEvents[3]);
		}
		setCanvasEvents([
			mouseDownListener,
			mouseMoveListener,
			mouseupListener,
			rightClickListener
		]);
		canvas.addEventListener('mousedown', mouseDownListener);
		canvas.addEventListener('mousemove', mouseMoveListener);
		canvas.addEventListener('mouseup', mouseupListener);
		canvas.addEventListener('contextmenu', rightClickListener);
	}

	function clearCanvas() {
		let canvas = document.getElementById('drawing_canvas');
		if (!canvas) return;
		let context = canvas.getContext('2d');
		context.clearRect(0, 0, canvas.width, canvas.height);
	}

	function selectCanvasIndex(positionX, positionY) {
		if (!window.imageRate) window.imageRate = 1.0;

		if (drawingMode.current !== "") {
			return -1;
		}

		let canvas = document.getElementById('drawing_canvas');
		if (!canvas || !currentImage.current) return;
		let rate = canvas.width / canvas.clientWidth;

		for (let i = 0; i < annotationList.length; i ++) {
			let annotation = annotationList[i];
			if ((annotationList[i].file_id + "") !== ("" + fileID)) continue;
			if (annotation.visible) {
				if (annotation.type === "image_up_arrow" || annotation.type === "image_down_arrow"
				|| annotation.type === "image_left_arrow" || annotation.type === "image_right_arrow") {
					let positions = JSON.parse(annotation.positions);

					if (positions.length > 0) {
						let width = rate * 50;
						let height = rate * 50;

						let centerPosition = {
							x: positions[0] * window.imageRate,
							y: positions[1] * window.imageRate,
						};

						if (annotation.type === "image_right_arrow") {
							if (positionX >= centerPosition.x - width && positionX <= centerPosition.x
								&& positionY >= centerPosition.y - height / 2 && positionY <= centerPosition.y + height / 2) {
									return i;
								}
						}
						else if (annotation.type === "image_left_arrow") {
							if (positionX >= centerPosition.x && positionX <= centerPosition.x + width
								&& positionY >= centerPosition.y - height / 2 && positionY <= centerPosition.y + height / 2) {
									return i;
								}
						}
						else if (annotation.type === "image_down_arrow") {
							if (positionX >= centerPosition.x - width / 2 && positionX <= centerPosition.x + width / 2
								&& positionY >= centerPosition.y - height && positionY <= centerPosition.y) {
									return i;
								}
						}
						else if (annotation.type === "image_up_arrow") {
							if (positionX >= centerPosition.x - width / 2 && positionX <= centerPosition.x + width / 2
								&& positionY >= centerPosition.y && positionY <= centerPosition.y + height) {
									return i;
								}
						}
					}
				}
				else {
					let positions = JSON.parse(annotation.positions);
					if (positions.length > 0) {
						if (positions.length === 2) {
							let distance = Math.sqrt((positions[0] * window.imageRate - positionX) * (positions[0] * window.imageRate - positionX) + (positions[1] * window.imageRate - positionY) * (positions[1] * window.imageRate - positionY));

							if (distance < rate * 4) {
								return i;
							}
						}
						else if (positions.length === 4) {
							let distance = mapUtils.pDistance(positionX, positionY, positions[0] * window.imageRate, positions[1] * window.imageRate, positions[2] * window.imageRate, positions[3] * window.imageRate);

							if (distance < rate * 4) {
								return i;
							}
						}
						else {
							if (annotation.type === "image_polyline") {
								let distance = mapUtils.pDistance(positionX, positionY, positions[0] * window.imageRate, positions[1] * window.imageRate, positions[2] * window.imageRate, positions[3] * window.imageRate);
								if (distance < rate * 4) {
									return i;
								}
								for (let j = 2; j <= positions.length - 4; j += 2) {
									distance = mapUtils.pDistance(positionX, positionY, positions[j] * window.imageRate, positions[j + 1] * window.imageRate, positions[j + 2] * window.imageRate, positions[j + 3] * window.imageRate);
									if (distance < rate * 4) {
										return i;
									}
								}
							}
							else if (annotation.type === "image_measure_line") {
								let distance = mapUtils.pDistance(positionX, positionY, positions[0] * window.imageRate, positions[1] * window.imageRate, positions[2] * window.imageRate, positions[3] * window.imageRate);
								if (distance < rate * 4) {
									return i;
								}
								for (let j = 2; j <= positions.length - 4; j += 2) {
									distance = mapUtils.pDistance(positionX, positionY, positions[j] * window.imageRate, positions[j + 1] * window.imageRate, positions[j + 2] * window.imageRate, positions[j + 3] * window.imageRate);
									if (distance < rate * 4) {
										return i;
									}
								}
							}
							else {
								let polygonPoints = [];
								for (let j = 0; j < positions.length - 1; j += 2) {
									polygonPoints.push([positions[j] * window.imageRate, positions[j + 1] * window.imageRate]);
								}

								if (mapUtils.insidePolygon(positionX, positionY, polygonPoints)) {
									return i;
								}
							}
						}
					}
				}
			}
		}

		return -1;
	}

	// relativePosition(up: 0, down: 1, left: 2, right: 3)
	function drawRectangleAnnotation(context, rate, canvasWidth, canvasHeight, positionX, positionY, annotation, relativePosition, isSelected) {
		let name = "Name: " + annotation.name;
		let annotation_type = "Type: ";
		let annotation_status = "Status: ";
		let type = "Style: ";
		let updated_date = "Date: " + annotation.updated_at;
		let severity_label = "Severity: " + annotation.severity_level;

		for (let i = 0; i < annotationDrawList.length; i ++) {
			if (annotationDrawList[i].value === annotation.type) {
				type = "Style: " + annotationDrawList[i].label;
			}
		}

		for (let i = 0; i < annotationTypeList.length; i ++) {
			if (annotationTypeList[i].value === annotation.annotation_type) {
				annotation_type = "Type: " + annotationTypeList[i].label;
			}
		}

		for (let i = 0; i < annotationStatusList.length; i ++) {
			if (annotationStatusList[i].value === annotation.annotation_status) {
				annotation_status = "Status: " + annotationStatusList[i].label;
			}
		}

		context.font = 12 * rate + 'px Montserrat';
		context.fillStyle = "#000000aa";
		context.strokeStyle = (isSelected)?"#03a9f4":mapUtils.getSeverityColor(annotation.severity_level);
		context.lineWidth = rate * 3;

		let width = Math.max(
			context.measureText(name).width,
			context.measureText(annotation_type).width,
			context.measureText(annotation_status).width,
			context.measureText(type).width,
			context.measureText(updated_date).width,
			context.measureText(severity_label).width
		);
		let height = rate * 13;
		let rectHeight = height * 6 + 5 * rate;
		let rectWidth = width + 5 * rate;
		let startX = positionX;
		let startY = positionY;

		if (relativePosition === 0) {
			startY -= rectHeight;
			startX -= rectWidth / 2;
		}
		else if (relativePosition === 1) {
			startX -= rectWidth / 2;
		}
		else if (relativePosition === 2) {
			startX -= rectWidth;
			startY -= rectHeight / 2;
		}
		else if (relativePosition === 3) {
			startY -= rectHeight / 2;
		}

		if (startY < 0) startY = 0;
		if (startX < 0) startX = 0;
		if (startX + rectWidth > canvasWidth) startX = canvasWidth - rectWidth;
		if (startY + rectHeight > canvasHeight) startY = canvasHeight - rectHeight;

		context.beginPath();
		context.rect(startX, startY, rectWidth, rectHeight);
		context.stroke();
		context.fill();

		context.fillStyle = "white";
		context.fillText(name, startX + 2 * rate, startY + 12 * rate);
		context.fillText(annotation_type, startX + 2 * rate, startY + 12 * rate + height * 1);
		context.fillText(type, startX + 2 * rate, startY + 12 * rate + height * 2);
		context.fillText(updated_date, startX + 2 * rate, startY + 12 * rate + height * 3);
		context.fillText(severity_label, startX + 2 * rate, startY + 12 * rate + height * 4);
		context.fillText(annotation_status, startX + 2 * rate, startY + 12 * rate + height * 5);
	}


	function imageToCanvas(image, width, height){
		const c = document.createElement("canvas");
		c.width = width;
		c.height = height;
		c.ctx = c.getContext("2d"); // attach context to the canvas for eaasy reference
		c.ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);
		return c;
	}

	function colorImage(img, color, width, height){ // image is a canvas image
		let image = imageToCanvas(img, width, height);
		image.ctx.fillStyle = color;
		image.ctx.globalCompositeOperation = "source-in";
		image.ctx.fillRect(0,0, width, width);
		image.ctx.globalCompositeOperation = "source-over";
		return image;
   }

	function getArrowImage(type, severity, width, height) {
		let result;
		if (type === "image_left_arrow") {
			result = leftArrowImage.current;
		}
		else if (type === "image_down_arrow") {
			result = downArrowImage.current;
		}
		else if (type === "image_right_arrow") {
			result = rightArrowImage.current;
		}
		else if (type === "image_up_arrow") {
			result = upArrowImage.current;
		}

		if (severity === 1) {
			return result;
		}
		else if (severity === 2) {
			return colorImage(result, "#fe7e01", width, height);
		}
		else if (severity === 3) {
			return colorImage(result, "#ffff01", width, height);
		}
		else if (severity === 4) {
			return colorImage(result, "#00ff01", width, height);
		}
		else if (severity === 5) {
			return colorImage(result, "#002fff", width, height);
		}

		return result;
	}

	function updateCanvasSize() {
		let canvas = document.getElementById('drawing_canvas');
		if (!canvas || !currentImage.current) return;

		if (imageList.files[selectedIndex].width > 0) {
			canvas.width = imageList.files[selectedIndex].width;
			canvas.height = imageList.files[selectedIndex].height;
		}
		else {
			canvas.width = currentImage.current.width;
			canvas.height = currentImage.current.height;
		}

		window.imageRate = mapUtils.defaultImageSize / canvas.width;

		if (window.imageRate < 1.0) {
			window.imageRate = 1.0;
		}

		canvas.width = window.imageRate * canvas.width;
		canvas.height = window.imageRate * canvas.height;
	}

	function calcPositionFromMeasureReference(index, annotation) {
		if (window.addingAnnotation && window.addingAnnotation.type === "image_measure_line") {
			if (imageMeasureReference.current.length <= index) return "";
			if (!isThreePointReference.current) {
				if (index < 2) {
					return mapUtils.getLabelOfMeasureFromOriginal(imageMeasureReference.current[index].value, props.userInfo.measurement_units, props.userInfo.measurement_units);
				}
				let positions = window.addingAnnotation.positions;
				let distanceFirst = Math.sqrt( Math.pow((positions[0].x - positions[1].x), 2) + Math.pow((positions[0].y - positions[1].y), 2));

				let distance = Math.sqrt( Math.pow((positions[index].x - positions[0].x), 2) + Math.pow((positions[index].y - positions[0].y), 2))

				distance = distance / distanceFirst * Math.abs(imageMeasureReference.current[0].value - imageMeasureReference.current[1].value) + imageMeasureReference.current[0].value;

				return mapUtils.getLabelOfMeasureFromOriginal(distance, props.userInfo.measurement_units, props.userInfo.measurement_units);
			}
			else {
				if (index < 3) {
					return mapUtils.getLabelOfMeasureFromOriginal(imageMeasureReference.current[index].value, props.userInfo.measurement_units, props.userInfo.measurement_units);
				}
				let positions = window.addingAnnotation.positions;
				let distanceFirst = Math.sqrt( Math.pow((positions[0].x - positions[1].x), 2) + Math.pow((positions[0].y - positions[1].y), 2));
				let measureFirst = Math.abs(imageMeasureReference.current[1].value - imageMeasureReference.current[0].value);
				let distanceSecond = Math.sqrt( Math.pow((positions[1].x - positions[2].x), 2) + Math.pow((positions[1].y - positions[2].y), 2));
				let measureSecond = Math.abs(imageMeasureReference.current[2].value - imageMeasureReference.current[1].value);

				let rate = 1;
				if (distanceFirst !== 0) rate = distanceSecond / distanceFirst;

				let distance = Math.sqrt( Math.pow((positions[index].x - positions[0].x), 2) + Math.pow((positions[index].y - positions[0].y), 2))
				let count = 1;
				if (distanceFirst !== 0) {
					if (rate === 1) {
						count = distance / distanceFirst;
					}
					else {
						count = Math.log(1 - (1 - rate) * distance / distanceFirst) / Math.log(rate); // 1 - rate ^ (step) = (1 - rate) * distance / distanceFirst;
					}
				}

				let measureRate = 1;
				if (measureFirst !== 0) measureRate = measureSecond / measureFirst;
				if (measureRate === 1) {
					distance = count * measureFirst + imageMeasureReference.current[0].value;
				}
				else {
					distance = measureFirst * (1 - Math.pow(measureRate, count)) / (1 - measureRate) + imageMeasureReference.current[0].value;
				}

				if (isNaN(distance)) return "No Calculation Measure";

				return mapUtils.getLabelOfMeasureFromOriginal(distance, props.userInfo.measurement_units, props.userInfo.measurement_units);
			}
		}
		let annotationItem = annotation;
		if (!annotationItem && annotationList && annotationSelected !== -1) {
			annotationItem = annotationList[annotationSelected];
		}
		if (annotationItem) {
			if (annotationItem.measurements_list && annotationItem.measurements_list !== "") {
				let measurements_list = JSON.parse(annotationItem.measurements_list);
				let threePointReference = measurements_list["three_point_reference"];
				let measureReference = measurements_list["measure_reference_label"];
				let measureUnitValue = measurements_list["measure_reference_unit"];
				if (annotationSelected !== -1) {
					measureReference = imageMeasureReference.current;
				}
				let positions = JSON.parse(annotationItem.positions);

				if (measureReference.length <= index) return "";

				if (!threePointReference) {
					if (index < 2) {
						return mapUtils.getLabelOfMeasureFromOriginal(measureReference[index].value, measureUnitValue, props.userInfo.measurement_units);
					}
					let distanceFirst = Math.sqrt( Math.pow((positions[0] - positions[2]), 2) + Math.pow((positions[1] - positions[3]), 2));

					let distance = Math.sqrt( Math.pow((positions[0] - positions[2 * index]), 2) + Math.pow((positions[1] - positions[2 * index + 1]), 2))

					distance = distance / distanceFirst * Math.abs(measureReference[0].value - measureReference[1].value) + measureReference[0].value;

					return mapUtils.getLabelOfMeasureFromOriginal(distance, measureUnitValue, props.userInfo.measurement_units);
				}
				else {
					if (index < 3) {
						return mapUtils.getLabelOfMeasureFromOriginal(measureReference[index].value, measureUnitValue, props.userInfo.measurement_units);
					}
					let distanceFirst = Math.sqrt( Math.pow((positions[0] - positions[2]), 2) + Math.pow((positions[1] - positions[3]), 2));
					let measureFirst = Math.abs(measureReference[0].value - measureReference[1].value);
					let distanceSecond = Math.sqrt( Math.pow((positions[2] - positions[4]), 2) + Math.pow((positions[3] - positions[5]), 2));
					let measureSecond = Math.abs(measureReference[2].value - measureReference[1].value);

					let rate = 1;
					if (distanceFirst !== 0) rate = distanceSecond / distanceFirst;

					let distance = Math.sqrt( Math.pow((positions[0] - positions[2 * index]), 2) + Math.pow((positions[1] - positions[2 * index + 1]), 2));
					let count = 1;
					if (distanceFirst !== 0) {
						if (rate === 1) {
							count = distance / distanceFirst;
						}
						else {
							count = Math.log(1 - (1 - rate) * distance / distanceFirst) / Math.log(rate); // 1 - rate ^ (step) = (1 - rate) * distance / distanceFirst;
						}
					}

					let measureRate = 1;
					if (measureFirst !== 0) measureRate = measureSecond / measureFirst;
					if (measureRate === 1) {
						distance = count * measureFirst + measureReference[0].value;
					}
					else {
						distance = measureFirst * (1 - Math.pow(measureRate, count)) / (1 - measureRate) + measureReference[0].value;
					}

					if (isNaN(distance)) return "No Calculation Measure";

					return mapUtils.getLabelOfMeasureFromOriginal(distance, measureUnitValue, props.userInfo.measurement_units);
				}
			}
		}

		return "";
	}

	function drawCanvas() {
		let canvas = document.getElementById('drawing_canvas');
		if (!canvas || !currentImage.current) return;
		var context = canvas.getContext('2d');
		clearCanvas();

		context.drawImage(currentImage.current, 0, 0, canvas.width, canvas.height);
		if (!isShowingMenu || isImageThumMode) return;
		let rate = canvas.width / canvas.clientWidth;
		let imageFileItem = imageList.files[selectedIndex];
		if (imageFileItem) {
			let locationInfo = mapUtils.getImageLocation(imageFileItem.location);
			let label_name = "Name: " + imageFileItem.name;

			let label_date = "Date: ";
			let label_author = "Supplier Name: EXIF";
			let labelLongitude = "Longitude: ";
			let labelLatitude = "Latitude: ";

			if (locationInfo) {
				if (locationInfo.date.length > 10) {
					label_date = "Date: " + locationInfo.date.substring(0, 10).replaceAll(":", "-") + " / " + locationInfo.date.substring(10);
				}
				// label_author = "Supplier Name: " + locationInfo.make;
				label_author = "Supplier Name: " + (imageList && imageList.dataset && imageList.dataset.supplier_name?imageList.dataset.supplier_name:"");
				labelLongitude = labelLongitude + locationInfo.longitude.toFixed(5);
				labelLatitude = labelLatitude + locationInfo.latitude.toFixed(5);
			}

			context.font = 14 * rate + 'px Montserrat';
			context.fillStyle = "#000000aa";
			context.strokeStyle = mapUtils.getInspectColor(imageFileItem.inspect);
			context.lineWidth = rate * 2;

			let width = Math.max(
				context.measureText(label_name).width,
				context.measureText(label_date).width,
				context.measureText(label_author).width,
				context.measureText(labelLongitude).width,
				context.measureText(labelLatitude).width
			);
			let height = rate * 15;
			let startX = 10 * rate;
			let startY = 10 * rate;

			context.beginPath();
			context.rect(startX, startY, width + 20 * rate, height * 5 + 10 * rate);
			context.stroke();
			context.fill();

			context.fillStyle = "white";
			context.fillText(label_name, startX + 12 * rate, startY + 14 * rate);
			context.fillText(label_date, startX + 12 * rate, startY + 14 * rate + height * 1);
			context.fillText(label_author, startX + 12 * rate, startY + 14 * rate + height * 2);
			context.fillText(labelLongitude, startX + 12 * rate, startY + 14 * rate + height * 3);
			context.fillText(labelLatitude, startX + 12 * rate, startY + 14 * rate + height * 4);

		}
		if (window.addingAnnotation && window.addingAnnotation.type === "image_point") {
			context.fillStyle = mapUtils.getSeverityColor(severitylevel.current);
			context.beginPath();
			context.arc(window.addingAnnotation.position.x, window.addingAnnotation.position.y, rate * 4, 0, Math.PI * 2, true);
			context.fill();

			// context.fillStyle = "white"; // Red color
			// context.font = 16 * rate + 'px Montserrat';
			// context.fillText(window.addingAnnotation.label, window.addingAnnotation.position.x + rate * 10, window.addingAnnotation.position.y);
		}
		else if (window.addingAnnotation && window.addingAnnotation.type === "image_right_arrow") {
			let width = rate * 50;
			let height = rate * 50;
			let image = getArrowImage(window.addingAnnotation.type, severitylevel.current, width, height);
			context.drawImage(image, 0, 0, image.width, image.height,
				window.addingAnnotation.position.x - width, window.addingAnnotation.position.y - height / 2, width, height);

			// context.fillStyle = "white"; // Red color
			// context.font = 16 * rate + 'px Montserrat';
			// context.fillText(window.addingAnnotation.label, window.addingAnnotation.position.x, window.addingAnnotation.position.y + 4 * rate);
		}
		else if (window.addingAnnotation && window.addingAnnotation.type === "image_left_arrow") {
			let width = rate * 50;
			let height = rate * 50;
			let image = getArrowImage(window.addingAnnotation.type, severitylevel.current, width, height);
			context.drawImage(image, 0, 0, image.width, image.height,
				window.addingAnnotation.position.x, window.addingAnnotation.position.y - height / 2, width, height);

			// context.fillStyle = "white"; // Red color
			// context.font = 16 * rate + 'px Montserrat';
			// let text = context.measureText(window.addingAnnotation.label);
			// context.fillText(window.addingAnnotation.label, window.addingAnnotation.position.x - text.width, window.addingAnnotation.position.y + 4 * rate);
		}
		else if (window.addingAnnotation && window.addingAnnotation.type === "image_down_arrow") {
			let width = rate * 50;
			let height = rate * 50;
			let image = getArrowImage(window.addingAnnotation.type, severitylevel.current, width, height);
			context.drawImage(image, 0, 0, image.width, image.height,
				window.addingAnnotation.position.x - width / 2, window.addingAnnotation.position.y - height, width, height);

			// context.fillStyle = "white"; // Red color
			// context.font = 16 * rate + 'px Montserrat';
			// let text = context.measureText(window.addingAnnotation.label);
			// context.fillText(window.addingAnnotation.label, window.addingAnnotation.position.x - text.width / 2, window.addingAnnotation.position.y + 8 * rate);
		}
		else if (window.addingAnnotation && window.addingAnnotation.type === "image_up_arrow") {
			let width = rate * 50;
			let height = rate * 50;
			let image = getArrowImage(window.addingAnnotation.type, severitylevel.current, width, height);
			context.drawImage(image, 0, 0, image.width, image.height,
				window.addingAnnotation.position.x - width / 2, window.addingAnnotation.position.y, width, height);

			// context.fillStyle = "white"; // Red color
			// context.font = 16 * rate + 'px Montserrat';
			// let text = context.measureText(window.addingAnnotation.label);
			// context.fillText(window.addingAnnotation.label, window.addingAnnotation.position.x - text.width / 2, window.addingAnnotation.position.y);
		}
		else if (window.addingAnnotation && window.addingAnnotation.type === "image_polyline") {
			let positions = window.addingAnnotation.positions;
			if (positions.length > 0) {
				context.fillStyle = "#ff2626"; // Red color
				for (let i = 0; i < positions.length; i ++) {
					context.beginPath();
					context.arc(positions[i].x, positions[i].y, rate * 4, 0, Math.PI * 2, true);
					context.fill();
				}
				let centerPosition = {
					x: positions[0].x,
					y: positions[0].y,
				};
				context.lineWidth = rate * 3;
				context.strokeStyle = mapUtils.getSeverityColor(severitylevel.current); // Red color
				if (positions.length > 1) {
					context.beginPath();
					context.moveTo(positions[0].x, positions[0].y);
					for (let i = 1; i < positions.length; i ++) {
						context.lineTo(positions[i].x, positions[i].y);
						centerPosition.x += positions[i].x;
						centerPosition.y += positions[i].y;
					}
					context.stroke();
				}

				centerPosition.x /= positions.length;
				centerPosition.y /= positions.length;

				// context.fillStyle = "white"; // Red color
				// context.font = 16 * rate + 'px Montserrat';
				// let text = context.measureText(window.addingAnnotation.label);
				// context.fillText(window.addingAnnotation.label, centerPosition.x - text.width / 2, centerPosition.y);
			}
		}
		else if (window.addingAnnotation && window.addingAnnotation.type === "image_polygon") {
			let positions = window.addingAnnotation.positions;
			if (positions.length > 0) {
				context.fillStyle = "#ff2626"; // Red color
				for (let i = 0; i < positions.length; i ++) {
					context.beginPath();
					context.arc(positions[i].x, positions[i].y, rate * 4, 0, Math.PI * 2, true);
					context.fill();
				}
				let centerPosition = {
					x: positions[0].x,
					y: positions[0].y,
				};
				context.lineWidth = rate * 3;
				context.strokeStyle = "yellow"; // Red color
				context.fillStyle = mapUtils.getSeverityColor(severitylevel.current) + "44";
				if (positions.length > 1) {
					context.beginPath();
					context.moveTo(positions[0].x, positions[0].y);
					for (let i = 1; i < positions.length; i ++) {
						context.lineTo(positions[i].x, positions[i].y);
						centerPosition.x += positions[i].x;
						centerPosition.y += positions[i].y;
					}
					context.lineTo(positions[0].x, positions[0].y);
					context.stroke();
					context.fill();
				}

				centerPosition.x /= positions.length;
				centerPosition.y /= positions.length;

				// context.fillStyle = "white"; // Red color
				// context.font = 16 * rate + 'px Montserrat';
				// let text = context.measureText(window.addingAnnotation.label);
				// context.fillText(window.addingAnnotation.label, centerPosition.x - text.width / 2, centerPosition.y);
			}
		}
		else if (window.addingAnnotation && window.addingAnnotation.type === "image_measure_line") {
			let positions = window.addingAnnotation.positions;
			if (positions.length > 0) {
				for (let i = 0; i < positions.length; i ++) {
					context.fillStyle = "#ff2626"; // Red color
					if (i === selectedMeasure.current) {
						context.fillStyle = "#03a9f4";
					}
					context.beginPath();
					context.arc(positions[i].x, positions[i].y, rate * 6, 0, Math.PI * 2, true);
					context.fill();
				}
				context.lineWidth = rate * 3;
				context.strokeStyle = "#22b14d"; // Red color
				if (positions.length > 1) {
					context.beginPath();
					context.moveTo(positions[0].x, positions[0].y);
					for (let i = 1; i < positions.length; i ++) {
						context.lineTo(positions[i].x, positions[i].y);
					}
					context.stroke();
				}

				for (let i = 0; i < positions.length; i ++) {
					let distance = calcPositionFromMeasureReference(i);
					let text = context.measureText(distance);
					let width = text.width;
					let height = rate * 15;
					context.fillStyle = "#000000ee";
					context.beginPath();
					context.rect(positions[i].x + 15 * rate, positions[i].y - height / 2 - 5 * rate, width + 10 * rate, height + 10 * rate);
					context.stroke();
					context.fill();

					context.fillStyle = "white";
					context.font = 14 * rate + 'px Montserrat';
					context.fillText(distance, positions[i].x + 20 * rate, positions[i].y + 5 * rate);

				}
			}
		}

		for (let i = 0; i < annotationList.length; i ++) {
			let annotation = annotationList[i];
			if ((annotation.file_id + "") !== ("" + fileID)) continue;
			if (annotation.visible) {
				if (annotation.type === "image_up_arrow" || annotation.type === "image_down_arrow"
				|| annotation.type === "image_left_arrow" || annotation.type === "image_right_arrow") {
					let positions = JSON.parse(annotation.positions);

					if (positions.length > 0) {
						let width = rate * 50;
						let height = rate * 50;


						let centerPosition = {
							x: positions[0] * window.imageRate,
							y: positions[1] * window.imageRate,
						};
						context.fillStyle = "white"; // Red color
						context.font = 16 * rate + 'px Montserrat';

						if (annotation.type === "image_right_arrow") {
							let image = getArrowImage(annotation.type, annotation.severity_level, width, height);
							context.drawImage(image, 0, 0, image.width, image.height,
								centerPosition.x - width, centerPosition.y - height / 2, width, height);

							if (annotationSelected === i) {
								context.drawImage(rightArrowImageSelection.current, 0, 0, rightArrowImageSelection.current.width, rightArrowImageSelection.current.height,
									centerPosition.x - width, centerPosition.y - height / 2, width, height);
							}

							drawRectangleAnnotation(context, rate, canvas.width, canvas.height, centerPosition.x - width - rate, centerPosition.y, annotation, 2, annotationSelected === i);
						}
						else if (annotation.type === "image_left_arrow") {
							let image = getArrowImage(annotation.type, annotation.severity_level, width, height);
							context.drawImage(image, 0, 0, image.width, image.height,
								centerPosition.x, centerPosition.y - height / 2, width, height);

							if (annotationSelected === i) {
								context.drawImage(leftArrowImageSelection.current, 0, 0, leftArrowImageSelection.current.width, leftArrowImageSelection.current.height, centerPosition.x, centerPosition.y - height / 2, width, height,);
							}

							drawRectangleAnnotation(context, rate, canvas.width, canvas.height, centerPosition.x + width + rate, centerPosition.y, annotation, 3, annotationSelected === i);
						}
						else if (annotation.type === "image_down_arrow") {
							let image = getArrowImage(annotation.type, annotation.severity_level, width, height);
							context.drawImage(image, 0, 0, image.width, image.height,
								centerPosition.x - width / 2, centerPosition.y - height, width, height);

							if (annotationSelected === i) {
								context.drawImage(downArrowImageSelection.current, 0, 0, downArrowImageSelection.current.width, downArrowImageSelection.current.height,
									centerPosition.x - width / 2, centerPosition.y - height, width, height);
							}

							drawRectangleAnnotation(context, rate, canvas.width, canvas.height, centerPosition.x, centerPosition.y - height- rate, annotation, 0, annotationSelected === i);
						}
						else if (annotation.type === "image_up_arrow") {
							let image = getArrowImage(annotation.type, annotation.severity_level, width, height);
							context.drawImage(image, 0, 0, image.width, image.height,
								centerPosition.x - width / 2, centerPosition.y, width, height);

							if (annotationSelected === i) {
								context.drawImage(upArrowImageSelection.current, 0, 0, upArrowImageSelection.current.width, upArrowImageSelection.current.height,
									centerPosition.x - width / 2, centerPosition.y, width, height);
							}

							drawRectangleAnnotation(context, rate, canvas.width, canvas.height, centerPosition.x, centerPosition.y + height, annotation, 1, annotationSelected === i);
						}
					}
				}
				else if (annotation.type === "image_measure_line") {
					let positions = JSON.parse(annotation.positions);
					if (positions.length > 0) {
						for (let i = 0; i < positions.length; i += 2) {
							context.fillStyle = mapUtils.getSeverityColor(annotation.severity_level); // Red color
							if (i / 2 === selectedMeasure.current) {
								context.fillStyle = "#03a9f4";
							}
							context.beginPath();
							context.arc(positions[i] * window.imageRate, positions[i + 1] * window.imageRate, rate * 6, 0, Math.PI * 2, true);
							context.fill();
						}
						context.lineWidth = rate * 3;
						context.strokeStyle = "#22b14d"; // Red color
						if (annotationSelected === i) {
							context.strokeStyle = "#03a9f4";
						}
						if (positions.length > 2) {
							context.beginPath();
							context.moveTo(positions[0] * window.imageRate, positions[1] * window.imageRate);
							for (let i = 2; i < positions.length; i += 2) {
								context.lineTo(positions[i] * window.imageRate, positions[i + 1] * window.imageRate);
							}
							context.stroke();
						}

						for (let i = 0; i < positions.length; i += 2) {
							let distance = calcPositionFromMeasureReference(i / 2, annotation);
							let text = context.measureText(distance);
							let width = text.width;
							let height = rate * 15;
							context.fillStyle = "#000000ee";
							context.beginPath();
							context.rect(positions[i] * window.imageRate + 15 * rate, positions[i + 1] * window.imageRate - height / 2 - 5 * rate, width + 10 * rate, height + 10 * rate);
							context.stroke();
							context.fill();

							context.fillStyle = "white";
							context.font = 14 * rate + 'px Montserrat';
							context.fillText(distance, positions[i] * window.imageRate + 20 * rate, positions[i + 1] * window.imageRate + 5 * rate);

						}
					}
				}
				else {
					let positions = JSON.parse(annotation.positions);
					if (positions.length > 0) {
						if (annotationSelected === i) {
							context.fillStyle = "#03a9f4"; // yellow color
							for (let i = 0; i < positions.length; i += 2) {
								context.beginPath();
								context.arc(positions[i] * window.imageRate, positions[i + 1] * window.imageRate, rate * 5, 0, Math.PI * 2, true);
								context.fill();
							}
						}

						context.fillStyle = (annotation.type === "image_point")?mapUtils.getSeverityColor(annotation.severity_level):"#ff2626"; // Red color
						for (let i = 0; i < positions.length; i += 2) {
							context.beginPath();
							context.arc(positions[i] * window.imageRate, positions[i + 1] * window.imageRate, rate * 4, 0, Math.PI * 2, true);
							context.fill();
						}

						let centerPosition = {
							x: positions[0] * window.imageRate,
							y: positions[1] * window.imageRate,
						};

						if (annotationSelected === i) {
							context.lineWidth = rate * 5;
							context.strokeStyle = "#03a9f4"; // Red color
							if (positions.length > 2) {
								context.beginPath();
								context.moveTo(positions[0] * window.imageRate, positions[1] * window.imageRate);
								for (let i = 2; i < positions.length; i += 2) {
									context.lineTo(positions[i] * window.imageRate, positions[i + 1] * window.imageRate);
								}
								if (annotation.type === "image_polygon") {
									context.lineTo(positions[0] * window.imageRate, positions[1] * window.imageRate);
								}
								context.stroke();
							}
						}

						context.lineWidth = rate * 3;
						context.strokeStyle = (annotation.type === "image_polygon")?"yellow":mapUtils.getSeverityColor(annotation.severity_level); // Red color
						context.fillStyle = mapUtils.getSeverityColor(annotation.severity_level) + "44";
						if (positions.length > 2) {
							context.beginPath();
							context.moveTo(positions[0] * window.imageRate, positions[1] * window.imageRate);
							for (let i = 2; i < positions.length; i += 2) {
								context.lineTo(positions[i] * window.imageRate, positions[i + 1] * window.imageRate);
								centerPosition.x += positions[i] * window.imageRate;
								centerPosition.y += positions[i + 1] * window.imageRate;
							}
							if (annotation.type === "image_polygon") {
								context.lineTo(positions[0] * window.imageRate, positions[1] * window.imageRate);
								context.fill();
							}
							context.stroke();
						}

						centerPosition.x /= positions.length / 2;
						centerPosition.y /= positions.length / 2;

						if (annotation.name) {
							if (annotation.type === "image_point") {
								drawRectangleAnnotation(context, rate, canvas.width, canvas.height, centerPosition.x, centerPosition.y - rate * 6, annotation, 0, annotationSelected === i);
							}
							else {
								drawRectangleAnnotation(context, rate, canvas.width, canvas.height, centerPosition.x, centerPosition.y, annotation, 0, annotationSelected === i);
							}
						}
					}
				}
			}
		}
	}

	function sortImageList(list) {
		if (!list) return [];

		if (list.files) {
			list.files = list.files.sort(function(first, second) {
				if (second.id === -1) return -1;
				let firstValue = '';
				let secondValue = '';
				if (sortType=== 'name') {
					firstValue = first.name;
					secondValue = second.name;
				}
				else if (sortType === 'date') {
					let locationInfo = mapUtils.getImageLocation(first.location)
					if (locationInfo && locationInfo.date && locationInfo.date !== "") {
						firstValue = locationInfo.date
					}
					else {
						firstValue = first.created_at;
					}

					locationInfo = mapUtils.getImageLocation(second.location)
					if (locationInfo && locationInfo.date && locationInfo.date !== "") {
						secondValue = locationInfo.date
					}
					else {
						secondValue = second.created_at;
					}
				}

				if (sortOrder === 'asc') return firstValue.toLowerCase().localeCompare(secondValue.toLowerCase());
				return secondValue.toLowerCase().localeCompare(firstValue.toLowerCase());
			})
		}
		return list;
	}

	async function getImageList() {
		let userToken = localStorage.getItem("userToken");
		if (api.isSharing()) {
			userToken = api.getParameterByName("share_token");
		}
		if (userToken) {
			let response = await api.getProjectDatasetByID({id: datasetID, project_id: projectID, token: userToken});

			if (response.data && !response.data.error) {
				let orderedImageList = sortImageList(response.data);
				setImageList(orderedImageList);
				setTotalImagePage(Math.ceil(orderedImageList.files.length / itemCountPage));
				if (fileID) {
					for (let i = 0; i < orderedImageList.files.length; i ++) {
						if (orderedImageList.files[i].id + "" === fileID + "") {
							setSelectImageItem(orderedImageList, i);
						}
					}
				}

				if (!fileID) {
					setSelectImageItem(orderedImageList, 0);
				}
			}
			else {
				redirectBack();
			}
		}
		else {
			redirectToLogin();
		}
	}

	function setSelectImageItem(data, index) {
		let pageIndex = data.files.filter(function(value) {
			return filterArrays.indexOf(value.inspect) >= 0 && hasAnnotation(value) && isInListBySearch(value);
		}).findIndex(function(value) {
			return value.id + "" === data.files[index].id + "";
		});

		setCurrentImagePage(Math.floor(pageIndex / itemCountPage) + 1)
		setSelectedIndex(index);
		api.setParameter("file_id", data.files[index].id);
		fileID = data.files[index].id;
	}

	async function getAnnnotationList() {
		let userToken = localStorage.getItem("userToken");
		if (api.isSharing()) {
			userToken = api.getParameterByName("share_token");
		}
		if (userToken) {
			let response = await api.getAnnotationByDataset({dataset_id: datasetID, project_id: projectID, token: userToken});

			if (response.data && !response.data.error) {
				setAnnotationList(response.data.annotations);
			}
			else {
				redirectBack();
			}
		}
		else {
			redirectToLogin();
		}
	}

	function redirectToLogin() {
        props.history.replace("/");
    }

	function redirectBack() {
		props.history.goBack();
	}

	async function updateInspect(inspectValue) {
		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            api.updateInspect({token: userToken, id: fileID, inspect: inspectValue});
        }
        else {
            redirectToLogin();
        }
    }

	function changeInspectCurrent(inspectValue) {
		setInspectLevel(inspectValue);
		updateInspect(inspectValue)

		//update Global Dataset
		let list = new Map(props.datasetList);
		let dataset_id = Number(datasetID);
		if (list.get(dataset_id)) {
			let datasetItem = list.get(dataset_id);
			if (datasetItem) {
				if (datasetItem.files[selectedIndex]) {
					datasetItem.files[selectedIndex].inspect = inspectValue;
					list.set(dataset_id, datasetItem);
					props.setDatasetList(list);
				}
			}

		}

		let cloneImageList = JSON.parse(JSON.stringify(imageList));
		cloneImageList.files[selectedIndex].inspect = inspectValue;
		setImageList(cloneImageList);
	}

	function handleChangeInspectLevel(event) {
		changeInspectCurrent(parseInt(event.target.value));
	}

	function prevCheckImage() {
		if (inspectlevel === 0)
			changeInspectCurrent(2);
		prevImage();
	}

	function nextCheckImage() {
		if (inspectlevel === 0)
			changeInspectCurrent(2);
		nextImage();
	}

	function downloadImage() {
		let imagePath = mapUtils.getImagePathLink(imageList.dataset, imageList.files[selectedIndex].name, true);
		toast("Downloading " + imageList.files[selectedIndex].name);

		fetch(imagePath, {cache: "no-store"}).then(response => {
			response.blob().then(blob => {
				const fileURL = window.URL.createObjectURL(blob);
				let alink = document.createElement('a');
				alink.href = fileURL;
				alink.download = imageList.files[selectedIndex].name;
				alink.click();
				toast("Downloaded " + imageList.files[selectedIndex].name);
			})
		});
	}

	function preloadImage(currentIndex) {
		let nextPrevIndex = getPrevIndex(currentIndex);

		if (nextPrevIndex >= 0) {
			let img = new Image();
			let isResize = imageList.files[nextPrevIndex].width > mapUtils.defaultImageSize;
			img.src = mapUtils.getImagePathLink(imageList.dataset, imageList.files[nextPrevIndex].name, !isResize, isResize, mapUtils.defaultImageSize);

			nextPrevIndex = getPrevIndex(nextPrevIndex);

			if (nextPrevIndex >= 0) {
				let img = new Image();
				let isResize = imageList.files[nextPrevIndex].width > mapUtils.defaultImageSize;
				img.src = mapUtils.getImagePathLink(imageList.dataset, imageList.files[nextPrevIndex].name, !isResize, isResize, mapUtils.defaultImageSize);
			}
		}

		nextPrevIndex = getNextIndex(currentIndex);

		if (nextPrevIndex >= 0) {
			let img = new Image();
			let isResize = imageList.files[nextPrevIndex].width > mapUtils.defaultImageSize;
			img.src = mapUtils.getImagePathLink(imageList.dataset, imageList.files[nextPrevIndex].name, !isResize, isResize, mapUtils.defaultImageSize);

			nextPrevIndex = getNextIndex(nextPrevIndex);

			if (nextPrevIndex >= 0) {
				let img = new Image();
				let isResize = imageList.files[nextPrevIndex].width > mapUtils.defaultImageSize;
				img.src = mapUtils.getImagePathLink(imageList.dataset, imageList.files[nextPrevIndex].name, !isResize, isResize, mapUtils.defaultImageSize);
			}
		}
	}

	function getPrevIndex(currentIndex) {
		let index = 0;
		if (currentIndex === 0) {
			return -1;
		}
		else {
			index = currentIndex - 1;
		}

		for (; index >= 0; index --) {
			if (filterArrays.indexOf(imageList.files[index].inspect) >= 0 && isInListBySearch(imageList.files[index]) && hasAnnotation(imageList.files[index])) break;
		}

		if (index >= imageList.files.length || index < 0) return -1;

		return index;
	}

	function getPrevFastIndex(currentIndex) {
		let index = 0;
		if (currentIndex < 10) {
			return -1;
		}
		else {
			index = currentIndex - 10;
		}

		for (; index >= 0; index --) {
			if (filterArrays.indexOf(imageList.files[index].inspect) >= 0 && isInListBySearch(imageList.files[index]) && hasAnnotation(imageList.files[index])) break;
		}

		if (index >= imageList.files.length || index < 0) return -1;

		return index;
	}

	function getNextIndex(currentIndex) {
		let index = 0;
		if (currentIndex === imageList.files.length - 1) {
			return -1;
		}
		else {
			index = currentIndex + 1;
		}

		for (; index < imageList.files.length; index ++) {
			if (filterArrays.indexOf(imageList.files[index].inspect) >= 0 && isInListBySearch(imageList.files[index]) && hasAnnotation(imageList.files[index])) break;
		}

		if (index >= imageList.files.length || index < 0) return -1;

		return index;
	}

	function getNextFastIndex(currentIndex) {
		let index = 0;
		if (currentIndex >= imageList.files.length - 10) {
			return -1;
		}
		else {
			index = currentIndex + 10;
		}

		for (; index < imageList.files.length; index ++) {
			if (filterArrays.indexOf(imageList.files[index].inspect) >= 0 && isInListBySearch(imageList.files[index]) && hasAnnotation(imageList.files[index])) break;
		}

		if (index >= imageList.files.length || index < 0) return -1;

		return index;
	}

	function prevImage() {
		let index = getPrevIndex(selectedIndex);
		if (index < 0) return;

		if (index !== -1)
			setSelectImageItem(imageList, index);
	}

	function prevFastImage() {
		let index = getPrevFastIndex(selectedIndex);
		if (index < 0) return;

		if (index !== -1)
			setSelectImageItem(imageList, index);
	}

	function nextImage() {
		let index = getNextIndex(selectedIndex);
		if (index < 0) return;

		if (index !== imageList.files.length) {
			setSelectImageItem(imageList, index);
		}
	}

	function nextFastImage() {
		let index = getNextFastIndex(selectedIndex);
		if (index < 0) return;

		if (index !== imageList.files.length) {
			setSelectImageItem(imageList, index);
		}
	}

	function handleFilterMenu(event) {
        if (event.currentTarget)
		    setAnchorFilter(event.currentTarget);
	}

	function handleFilterMenuClose() {
		setAnchorFilter(null);
	}

	function referenceMode() {
		if (drawingMode.current !== "") return;
		setDrawing("image_measure_line");
	}

	function pointMode() {
		if (drawingMode.current !== "") return;
		setDrawing("image_point");
	}

	function polylineMode() {
		if (drawingMode.current !== "") return;
		setDrawing("image_polyline");
	}

	function polygonMode() {
		if (drawingMode.current !== "") return;
		setDrawing("image_polygon");
	}

	function upArrowMode() {
		if (drawingMode.current !== "") return;
		setDrawing("image_up_arrow");
	}

	function downArrowMode() {
		if (drawingMode.current !== "") return;
		setDrawing("image_down_arrow");
	}

	function leftArrowMode() {
		if (drawingMode.current !== "") return;
		setDrawing("image_left_arrow");
	}

	function rightArrowMode() {
		if (drawingMode.current !== "") return;
		setDrawing("image_right_arrow");
	}

	function changeFilterInspect(event) {
		let cloneFilterArrays = filterArrays.filter(function(value) {
			return true;
		});
		let inspect = parseInt(event.currentTarget.dataset.id);
		let index = cloneFilterArrays.indexOf(inspect);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(inspect);
		}

		setFilterArrays(cloneFilterArrays);
	}

	function changeFilterType(event) {
		let cloneFilterArrays = filterTypeArrays.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}

		setFilterTypeArrays(cloneFilterArrays);
	}

	function changeFilterStatus(event) {
		let cloneFilterArrays = filterStatusArrays.filter(function(value) {
			return true;
		});
		let type = event.currentTarget.dataset.id;
		let index = cloneFilterArrays.indexOf(type);
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push(type);
		}

		setFilterStatusArrays(cloneFilterArrays);
	}

	function showAnnotated() {
		let cloneFilterArrays = filterStatusArrays.filter(function(value) {
			return true;
		});
		let index = cloneFilterArrays.indexOf("not_annotated");
		if (index >= 0) {
			cloneFilterArrays.splice(index, 1);
		}
		else {
			cloneFilterArrays.push("not_annotated");
		}

		setFilterStatusArrays(cloneFilterArrays);
	}

	function selectFilterOption(event, value) {
		setFilterOption(value);
	}

	function checkAllAnnotationType() {
		if (checkedAll) {
			setFilterTypeArrays([]);
		}
		else {
			setFilterTypeArrays(annotationTypeList.map((annotationType) => annotationType.value));
		}

		setTypeCheckAll(!checkedAll);
	}

	function getAnnotationTypeMenu() {
		let items = annotationTypeList.map((annotationType, index) => (
			<div key={index} onClick={changeFilterType} data-id={annotationType.value} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterTypeArrays.indexOf(annotationType.value) >= 0}/>
				<div className='filter_image_type'>{annotationType.label}</div>
			</div>
        ));

		items.unshift((
			<div key={-1} onClick={checkAllAnnotationType} className='image_filter_annotation_type'>
				<CustomCheckbox checked={checkedAll}/>
				<div className='filter_image_type'>Check/Uncheck All</div>
			</div>
        ));

        return items;
	}

	function getAnnotationStatusMenu() {
		const items= annotationStatusList.map((annotationStatus, index) => (
			<div key={index} onClick={changeFilterStatus} data-id={annotationStatus.value} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterStatusArrays.indexOf(annotationStatus.value) >= 0}/>
				<div className='filter_image_type'>{annotationStatus.label}</div>
			</div>
        ));

		items.push((
			<div key={-1} onClick={changeFilterStatus} data-id={"not_annotated"} className='image_filter_annotation_type'>
				<CustomCheckbox checked={filterStatusArrays.indexOf("not_annotated") >= 0}/>
				<div className='filter_image_type'>Not Annotated</div>
			</div>
		));

        return items;
	}

	function getFilterImageMenu() {
		let renderMenu;
		const menuId = 'primary-action-filtermenu';

		renderMenu = (
			<Menu
				anchorEl={anchorFilter}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				id={menuId}
				className="sidebar-actionmenu-style"
				keepMounted
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
				open={isFilterMenuOpen}
				onClose={handleFilterMenuClose}
			>

				<div className='image_filter'>
					<div className='expand_filter_component'>
						<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
							setExpandedDateFilter(!isExpandedDateFilter);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
						}}>
							{!isExpandedDateFilter?
								<div className='icon below_icon'></div>
							:
								<div className='icon up_icon'></div>
							}
						</div>
						<label className='expand_filter_label' onClick={() => {
							setExpandedDateFilter(!isExpandedDateFilter);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
						}}>Date Filter</label>
					</div>
					{isExpandedDateFilter?
					<div className='flex_part filter_menu'>
						<div>
							<div className='image_filter_date flex_part report_date'>
								<div className='calendar_icon '></div>
								<DatePicker selected={startDate}
									onChange={(date) => setStartDate(date)}
									placeholderText={'All Dates'}
									popperProps={{
										positionFixed: true
									}}
								/>
								<div className='selection_label hover_text' onClick={() => setStartDate(null)}>Start</div>
							</div>
							<div className='image_filter_date flex_part report_date'>
								<div className='calendar_icon '></div>
								<DatePicker selected={endDate}
									onChange={(date) => setEndDate(date)}
									placeholderText={'All Dates'}
									popperProps={{
										positionFixed: true
									}}
								/>
								<div className='selection_label hover_text' onClick={() => setEndDate(null)}>End</div>
							</div>
						</div>
						<RadioGroup
							name="controlled-radio-buttons-group"
							value={filterOption}
							onChange={selectFilterOption}
						>
							<FormControlLabel value="annotation" control={<RadioCore />} label="Annotation" />
							<FormControlLabel value="image" control={<RadioCore />} label="Image" />
						</RadioGroup>
					</div>
					:null}
					<div className='expand_filter_component'>
						<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
							setExpandedTypeFilter(!isExpandedTypeFilter);
							setExpandedDateFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
						}}>
							{!isExpandedTypeFilter?
								<div className='icon below_icon'></div>
							:
								<div className='icon up_icon'></div>
							}
						</div>
						<label className='expand_filter_label' onClick={() => {
							setExpandedTypeFilter(!isExpandedTypeFilter);
							setExpandedDateFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
						}}>Type Filter</label>
					</div>
					{isExpandedTypeFilter?
					<div className='filter_menu'>
						{getAnnotationTypeMenu()}
					</div>
					:null}
					<div className='expand_filter_component'>
						<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
							setExpandedStatusFilter(!isExpandedStatusFilter);
							setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
						}}>
							{!isExpandedStatusFilter?
								<div className='icon below_icon'></div>
							:
								<div className='icon up_icon'></div>
							}
						</div>
						<label className='expand_filter_label' onClick={() => {
							setExpandedStatusFilter(!isExpandedStatusFilter);
							setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedSeverityFilter(false);
                            setExpandedTextFilter(false);
						}}>Status Filter</label>
					</div>
					{isExpandedStatusFilter?
					<div className='filter_menu'>
						{getAnnotationStatusMenu()}
					</div>
					:null}
					<div className='expand_filter_component'>
						<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
							setExpandedSeverityFilter(!isExpandedSeverityFilter);
							setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedTextFilter(false);
						}}>
							{!isExpandedSeverityFilter?
								<div className='icon below_icon'></div>
							:
								<div className='icon up_icon'></div>
							}
						</div>
						<label className='expand_filter_label' onClick={() => {
							setExpandedSeverityFilter(!isExpandedSeverityFilter);
							setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedTextFilter(false);
						}}>Severity Filter</label>
					</div>
					{isExpandedSeverityFilter?
					<div className='filter_menu'>
						<CustomCheckbox checked={filterArrays.indexOf(5) >= 0} className="filter_inspect_5_checkbox" onClick={changeFilterInspect} data-id={5}/>
						<CustomCheckbox checked={filterArrays.indexOf(4) >= 0} className="filter_inspect_4_checkbox" onClick={changeFilterInspect} data-id={4}/>
						<CustomCheckbox checked={filterArrays.indexOf(3) >= 0} className="filter_inspect_3_checkbox" onClick={changeFilterInspect} data-id={3}/>
						<CustomCheckbox checked={filterArrays.indexOf(2) >= 0} className="filter_inspect_2_checkbox" onClick={changeFilterInspect} data-id={2}/>
						<CustomCheckbox checked={filterArrays.indexOf(0) >= 0} className="filter_inspect_0_checkbox" onClick={changeFilterInspect} data-id={0}/>
					</div>
					:null}
					<div className='expand_filter_component'>
						<div className="analytics_icon can_hover_icon no_boder_line" onClick={() => {
							setExpandedTextFilter(!isExpandedTextFilter);
							setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
						}}>
							{!isExpandedTextFilter?
								<div className='icon below_icon'></div>
							:
								<div className='icon up_icon'></div>
							}
						</div>
						<label className='expand_filter_label' onClick={() => {
							setExpandedTextFilter(!isExpandedTextFilter);
							setExpandedDateFilter(false);
                            setExpandedTypeFilter(false);
                            setExpandedStatusFilter(false);
                            setExpandedSeverityFilter(false);
						}}>Text Filter</label>
					</div>
					{isExpandedTextFilter?
					<div className='filter_menu'>
						<input type="text" className="search-name-input"
							value={imageSearch}
							placeholder="Search Name"
							onChange={onChangeSearchImage}></input>
					</div>
					:null}
				</div>

				{/* <MenuItem onClick={changeFilterInspect} data-id={5}>
					<CustomCheckbox checked={filterArrays.indexOf(5) >= 0} />
					<div className='filter_inspect_5'></div>
				</MenuItem>
				<MenuItem onClick={changeFilterInspect} data-id={4}>
					<CustomCheckbox checked={filterArrays.indexOf(4) >= 0} />
					<div className='filter_inspect_4'></div>
				</MenuItem>
				<MenuItem onClick={changeFilterInspect} data-id={3}>
					<CustomCheckbox checked={filterArrays.indexOf(3) >= 0} />
					<div className='filter_inspect_3'></div>
				</MenuItem>
				<MenuItem onClick={changeFilterInspect} data-id={2}>
					<CustomCheckbox checked={filterArrays.indexOf(2) >= 0} />
					<div className='filter_inspect_2'></div>
				</MenuItem>
				<MenuItem onClick={changeFilterInspect} data-id={1}>
					<CustomCheckbox checked={filterArrays.indexOf(1) >= 0} />
					<div className='filter_inspect_1'></div>
				</MenuItem>
				<MenuItem onClick={changeFilterInspect} data-id={0}>
					<CustomCheckbox checked={filterArrays.indexOf(0) >= 0} />
					<div className='filter_inspect_0'></div>
				</MenuItem> */}
			</Menu>
		);

		return renderMenu;
	}

	function setDrawing(value) {
		setAnnotationSelected(-1);
		setDrawingMode(value);
	}

	function selectDrawType(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setDrawing(newValue.value);
			}
		}
	}

	function transformInit(value) {
		setTransformElement(value)
	}

	function transformFinishZoom(event) {
		if (event && event.state.scale >= 2.0) {
			setShouldCenter(false);
			updateImageViewer(false);
		}
	}

	function hideDeleteDialog() {
		setDeleteDialog(false);
		setDeleteMessage("");
		setDeleteTitle("");
		window.deleteCallback = null;
	}

	function deleteRun() {
		setDeleteDialog(false);
		setDeleteMessage("");
		setDeleteTitle("");

		if (window.deleteCallback) {
			window.deleteCallback();
		}
	}

	function getDeleteDialog() {
        return (
            <Modal show={isDelete.current} animation={false} className="modal-delete-dialog" container={container()}>
                <Modal.Header>
					<div className='delete_dialog_title'>
						<Modal.Title>{deleteTitle}</Modal.Title>
						<div className='close_button' onClick={hideDeleteDialog}>
							<img src="/close.png" alt="close" style={{height: "100%"}}/>
						</div>
					</div>
                </Modal.Header>
                <Modal.Body>
                    <div className='delete_dialog_message'>
						{deleteMessage}
                    </div>
                </Modal.Body>
				<Modal.Footer>
					<Button variant="contained"
						color="secondary" onClick={hideDeleteDialog} className={classes.button}>
						Close
					</Button>
					<Button variant="contained"
						color="primary" onClick={deleteRun} className={classes.button}>
						Delete
					</Button>
                </Modal.Footer>
            </Modal>
        );
    }

	function deleteAnnotationByDialog(index) {
		setDeleteTitle("Delete Annotation");
		setDeleteMessage("Do you want to delete this annotation?");
		window.deleteCallback = function() {
			deleteAnnotation(index);
		};
		setDeleteDialog(true);
	}

	async function deleteAnnotation(index) {
		setAnnotationSelected(-1);
		if (index >= annotationList.length) return;
		let annotation_id = annotationList[index].id;
		let updatedAnnotation = [...annotationList];
		updatedAnnotation.splice(index, 1);
		setAnnotationList(updatedAnnotation);
		updateInspectByAnnotation(updatedAnnotation);

        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            await api.deleteAnnotationByID({token: userToken, id: annotation_id});
        }
        else {
            redirectToLogin();
        }
    }

    async function updateVisibleAnnotation(index) {
        if (index >= annotationList.length) return;
		let annotation_id = annotationList[index].id;
		let updatedAnnotation = [...annotationList];
		updatedAnnotation[index].visible = !updatedAnnotation[index].visible;
		setAnnotationList(updatedAnnotation);

		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            await api.updateAnnotationVisible({token: userToken, id: annotation_id, visible: updatedAnnotation[index].visible});
        }
        else {
            redirectToLogin();
        }
    }

	async function updateVisibleAllAnnotation(visible) {
		let updatedAnnotation = [...annotationList];
		for (let i = 0; i < updatedAnnotation.length; i ++) {
			if (updatedAnnotation[i].file_id + "" === "" + fileID) {
				updatedAnnotation[i].visible = visible;
			}
		}
		setAnnotationList(updatedAnnotation);

		let userToken = localStorage.getItem("userToken");
        if (userToken) {
            await api.updateAnnotationAllVisible({token: userToken, project_id: projectID, dataset_id: datasetID, file_id: fileID, visible: visible});
        }
        else {
            redirectToLogin();
        }
    }

	function getAnnotationGridClassName(param) {
		return "annotation_table_class";
	}

	function selectImage(event) {
		if (event.currentTarget && event.currentTarget.dataset.id) {
			let selectedID = event.currentTarget.dataset.id;
			for (let i = 0; i < imageList.files.length; i ++) {
				if (imageList.files[i].id + "" === selectedID + "") {
					setSelectImageItem(imageList, i);
				}
			}

		}
	}

	const isInvisible = function (ele, container) {
		const { bottom, height, top } = ele.getBoundingClientRect();
		const containerRect = container.getBoundingClientRect();
		return top < containerRect.top || bottom > containerRect.bottom;
	};

	const scrollIntoViewMethod = (className, containerName) => {
		let domElement = document.getElementsByClassName(className)[0];
		let container = document.getElementsByClassName(containerName)[0];
		if (domElement && container && isInvisible(domElement, container)) {
			domElement.scrollIntoView();
		}
	};

	function selectAnnotationType(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setAnnotationType(newValue.value);
			}
		}
	}

	function selectAnnotationStatus(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setAnnotationStatus(newValue.value);
			}
		}
	}

	function handleChangeSeverityLevel(event) {
		setSeverityLevel(parseInt(event.target.value));
	}

	function isInListBySearch(file) {
		if (!imageSearch || imageSearch === "") return true;
		let annotationNameSearch = false;
		if (annotationList) {
			let filterdAnnotation = annotationList.filter(function(value) {
				return value.file_id + "" === file.id + "" && value.name.toLowerCase().indexOf(imageSearch.toLowerCase()) !== -1;
			});

			if (filterdAnnotation.length > 0) annotationNameSearch = true;
		}

		return file.name.toLowerCase().indexOf(imageSearch.toLowerCase()) !== -1 || annotationNameSearch;
	}

	function hasAnnotation(file) {
		if (!annotationList || !filterStatusArrays) {
			if (filterStatusArrays) {
				return filterStatusArrays.indexOf("not_annotated") >= 0;
			}
			return true;
		}
		if (startDate || endDate) {
			if (filterOption === "annotation") {
				let filterdAnnotation = annotationList.filter(function(value) {
					return value.file_id + "" === file.id + ""
						&& (!startDate || (new Date(value.updated_at.substring(0, 10))).getTime() >= startDate.getTime())
						&& (!endDate || (new Date(value.updated_at.substring(0, 10))).getTime() <= endDate.getTime());
				});

				if (filterdAnnotation.length === 0) return false;
			}
			else if (filterOption === "image") {
				let locationInfo = mapUtils.getImageLocation(file.location);
				if (locationInfo && locationInfo.date) {
					if (startDate) {
						if (new Date(locationInfo.date.substring(0, 10).replaceAll(":", "-")).getTime() < startDate.getTime()) return false;
					}
					if (endDate) {
						if (new Date(locationInfo.date.substring(0, 10).replaceAll(":", "-")).getTime() > endDate.getTime()) return false;
					}
				}
			}
		}

		let filterdAnnotation = annotationList.filter(function(value) {
			return value.file_id + "" === file.id + "";
		});

		if (filterdAnnotation.length === 0) return filterStatusArrays.indexOf("not_annotated") >= 0;

		return filterdAnnotation.filter(function(value) {
			return filterTypeArrays.indexOf(value.annotation_type) >= 0 && filterStatusArrays.indexOf(value.annotation_status) >= 0;
		}).length > 0;
	}

	function getImageItems() {
		if (imageList.files.length > 0) {
            const pageItems = imageList.files.filter(function(value) {
				return filterArrays.indexOf(value.inspect) >= 0 && isInListBySearch(value) && hasAnnotation(value);
			}).slice((currentImagePage - 1) * itemCountPage, currentImagePage * itemCountPage);
			if (pageItems.length === 0 && currentImagePage !== 1 ) {
				setCurrentImagePage(1);
			}
			if (isShowImageThumb) {
				const items = pageItems.map((item, index) => (
					<ImageListItem key={index}>
						<div className={`image_item ${'image_item_' + item.inspect} ${(selectedIndex >=0 && (item.id + "") === (imageList.files[selectedIndex].id + "")) ? 'selected_image_item' : ''}`} title={item.name} onClick={selectImage} data-id={item.id}>
							{(item.id + "") === (imageList.files[selectedIndex].id + "") ?
							<div className='overlay'></div>
							:null
							}
							<div className={`image_thumb_annotation ${'image_thumb_annotation_' + item.inspect}`}>
								{annotationCountMap.get(item.id + "")?annotationCountMap.get(item.id + ""):0}
							</div>

							<LazyLoadImage
								src={mapUtils.getImagePathLink(imageList.dataset, item.name, false, item.width > 0, 300)}
								alt={item.name}
								width={"100%"}
								height={"100%"}
							/>
						</div>
					</ImageListItem>
				));

				return (
					<ImageList sx={{ width: "100%", maxHeight: "100%" }} cols={2} className="image_scroll_list">
						{items}
					</ImageList>
				);
			}
			else {
				const items = pageItems.map((item, index) => (
					<div key={index}>
						<div className={`image_no_thumb image_no_thumb_item ${'image_no_thumb_item_' + item.inspect} ${(selectedIndex >=0 && (item.id + "") === (imageList.files[selectedIndex].id + "")) ? 'selected_image_no_thumb_item' : ''}`} title={item.name} onClick={selectImage} data-id={item.id}>
							<div className='image_no_thumb_name'>
								{item.name}
							</div>
							<div className={`image_no_thumb_annotation ${'image_no_thumb_annotation_' + item.inspect}`}>
								{annotationCountMap.get(item.id + "")?annotationCountMap.get(item.id + ""):0}
							</div>
						</div>
					</div>
				));

				return (
					<div className="image_scroll_list image_no_thumb_list">
						{items}
					</div>
				);
			}

        }
        return (null);
	}

	function resetAnnotationByDialog() {
		setDeleteTitle("Clear Annotations");
		setDeleteMessage("Do you want to clear all annotations?");
		window.deleteCallback = function() {
			resetAnnotation();
		};
		setDeleteDialog(true);
	}

	async function resetAnnotation() {
		let updatedAnnotation = [...annotationList];
		for (let i = 0; i < updatedAnnotation.length; i ++) {
			if ((updatedAnnotation[i].file_id + "") === ("" + fileID)) {
				updatedAnnotation.splice(i, 1);
				i --;
			}
		}
		setAnnotationList(updatedAnnotation);
		setAnnotationSelected(-1);
		changeInspectCurrent(0);

        let userToken = localStorage.getItem("userToken");
        if (userToken) {
            await api.deleteAllAnnotation({token: userToken, dataset_id: datasetID, project_id: projectID, file_id: fileID});
        }
        else {
            redirectToLogin();
        }

	}

	function visibleAnnotation() {
		updateVisibleAnnotation(annotationSelected);
	}

	function visibleAllAnnotation() {
		updateVisibleAllAnnotation(!allVisible);
		setAllVisible(!allVisible);
	}

	function deleteSelectedAnnotation() {
		deleteAnnotationByDialog(annotationSelected);
	}

	function resetSearch() {
		setImageSearch("");
	}

	function switchView() {
		setShowImageThumb(!isShowImageThumb);
	}

	function switchAnnotation() {
		setShowImageList(!showImageList);
	}

	function editAnnotation() {
		showCreateAnnotationDilaog(true, annotationSelected);
	}

	function visibleMenu() {
		setShowingMenu(!isShowingMenu);
		setAnnotationSelected(-1);
		setDrawing("");
	}

	function deleteImage() {
		setAnnotationSelected(-1);
		setDrawing("");

		if (selectedIndex !== -1) {
			setDeleteTitle("Delete Image");
			setDeleteMessage("Do you want to delete this image?");
			window.deleteCallback = function() {
				deleteCurrentImage();
			};
			setDeleteDialog(true);
		}
	}

	async function deleteCurrentImage() {
		let userToken = localStorage.getItem("userToken");
		if (userToken) {
			let response = await api.deleteExistFile({token: userToken, id: imageList.files[selectedIndex].id, dataset_id: datasetID});

			if (response.data && response.data.success) {
				toast(response.data.success);
				let cloneImageList = JSON.parse(JSON.stringify(imageList));
				cloneImageList.files.splice(selectedIndex, 1);
				setImageList(cloneImageList);

				if (selectedIndex >= cloneImageList.files.length) {
					setSelectImageItem(cloneImageList, cloneImageList.files.length - 1);
				}
				else {
					setSelectImageItem(cloneImageList, selectedIndex);
				}
			}
			else {
				if (response.data && response.data.error) {
					toast(response.data.error);
				}
				else {
					redirectToLogin();
				}
			}
		}
		else {
			redirectToLogin();
		}
	}

	function setMinScaleFromComponent() {
		if (transformElement) {
			let miniScale = 1.0;
			let canvas = document.getElementById('drawing_canvas');
			let image_part = document.getElementById('drawing_part');
			if (image_part && canvas && canvas.clientHeight > 0 && image_part.clientHeight > 0) {
				miniScale = Math.min(miniScale, image_part.clientHeight / (canvas.clientHeight + 4));
				miniScale = Math.min(miniScale, image_part.clientHeight / (canvas.clientHeight + 4));
			}
			setMinScale(miniScale);

			if (fitWidthMode) {
				fitWidthImage();
			}
			else {
				fitHeightImage();
			}
		}
	}

	function fitHeightImage() {
		if (transformElement) {
			let canvas = document.getElementById('drawing_canvas');
			let image_part = document.getElementById('drawing_part');
			if (image_part && canvas && canvas.clientHeight > 0 && image_part.clientHeight > 0) {
				let scale = image_part.clientHeight / (canvas.clientHeight + 4); // border size 4
				transformElement.current.resetTransform();
				transformElement.current.centerView(scale);
			}
		}
		setFitWidthMode(false);
	}

	function fitWidthImage() {
		if (transformElement) {
			let canvas = document.getElementById('drawing_canvas');
			let image_part = document.getElementById('drawing_part');
			if (image_part && canvas && canvas.clientWidth > 0 && image_part.clientWidth > 0) {
				let scale = image_part.clientWidth / (canvas.clientWidth + 4); // border size 4
				transformElement.current.resetTransform();
				transformElement.current.centerView(scale);
			}
		}
		setFitWidthMode(true);
	}

	function isFullScreen() {
		return document.fullscreenElement ||
		document.webkitFullscreenElement ||
		document.mozFullScreenElement ||
		document.msFullscreenElement;
	}

	function container() {
		// Use the fullscreen element if in fullscreen mode, otherwise just the document's body
		return document.fullscreenElement ?? document.body;
	}

	function changeFullScreen() {

		if (
			document.fullscreenElement ||
			document.webkitFullscreenElement ||
			document.mozFullScreenElement ||
			document.msFullscreenElement
		) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		} else {
			let element = document.getElementsByClassName('image_view')[0];
			if (element.requestFullscreen) {
				element.requestFullscreen();
			} else if (element.mozRequestFullScreen) {
				element.mozRequestFullScreen();
			} else if (element.webkitRequestFullscreen) {
				element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (element.msRequestFullscreen) {
				element.msRequestFullscreen();
			}
		}

		if (transformElement && transformElement.current) {
			setTimeout(function() {
				setMinScaleFromComponent();
			}, 500);
		}
	}

	function getAnnotationStatusLabel(listIndex, index) {
		let annotationItem = annotationList[listIndex];
		if (!annotationItem) return ""
		if (index === 0) {
			return annotationItem.created_at.substring(0, 10);
		}
		else if (annotationItem.annotation_status_history) {
			let jsonInfo = JSON.parse(annotationItem.annotation_status_history);
			let times = jsonInfo["" + index];
			if (times) {
				times = new Date(times * 1000);
				let monthValue = times.getMonth() + 1;
				return times.getFullYear() + "-"
				+ (monthValue < 10? "0" + monthValue:monthValue)
				+ "-" + (times.getDate() < 10? "0" + times.getDate():times.getDate());
			}
		}

		return "";
	}

	function getAnnotationStatusItems(listIndex) {
		const items = annotationStatusList.map((annotationStatus, index) => (
			<div key={index} className='annotation_selection_type_label'>
				<div className='annotation_info_label'>({index + 1}) {annotationStatus.label}:</div>
				<div className='annotation_info_value'>{getAnnotationStatusLabel(listIndex, index)}</div>
			</div>
        ));

        return items;
	}

	function selectSortType(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setSortType(newValue.value);
			}
		}
	}

    function selectSortOrder(newValue, actionMeta) {
		if (newValue) {
			if (newValue.value) {
				setSortOrder(newValue.value);
			}
		}
	}

	function updateImageMeasure() {
		if (annotationSelected !== -1) {
			showCreateAnnotationDilaog(true, annotationSelected);
		}
		else {
			showCreateAnnotationDilaog();
		}

	}

	function hideImageMeasure() {
		setImageMeasureReference([]);
		setDrawing("");
		setCreatingAnnotation(false);
		setAnnotationUpdating(false);
		setAnnotationStatus("0");
		setSelectedMeasure(-1);
		setAddingAnnotation(null);
	}

	function getMeasurementReferenceList() {
		const items = imageMeasureReference.current.map((reference, index) => (
			<div key={imageMeasureReference.current.length - index - 1} className='prevent-select image_measure_item' onClick={(event) => {
				event.preventDefault();
				switch (event.detail) {
					case 1:
						if (selectedMeasure.current === imageMeasureReference.current.length - index - 1) {
							setSelectedMeasure(-1);
						}
						else {
							setSelectedMeasure(imageMeasureReference.current.length - index - 1);
						}
						break;
					case 2:
						setSelectedMeasure(imageMeasureReference.current.length - index - 1);
						setMeasureNameChange(true);
						setMeasureSelectedName(imageMeasureReference.current[imageMeasureReference.current.length - index - 1].label);
						setMeasureSelectedValue(imageMeasureReference.current[imageMeasureReference.current.length - index - 1].value + "");
					  	break;
				}
			}}>
				<div className={`image_measure_value ${selectedMeasure.current === imageMeasureReference.current.length - index - 1 ? 'selected' : ''}`}>{"(" + (imageMeasureReference.current.length - index) + ") " + imageMeasureReference.current[imageMeasureReference.current.length - index - 1].label + ": " + calcPositionFromMeasureReference(imageMeasureReference.current.length - index - 1) }</div>
				<div className='image_measure_divider'/>
			</div>
        ));

        return items;
	}

	function getAnnotationMeasurementsLabel() {
		let result = "";
		for (let index = 0; index < imageMeasureReference.current.length; index ++) {
			if (result !== "") {
				result = result + "<br>";
			}
			result = result + "(" + (imageMeasureReference.current.length - index) + ") " + imageMeasureReference.current[imageMeasureReference.current.length - index - 1].label + ": " + calcPositionFromMeasureReference(imageMeasureReference.current.length - index - 1)
		}

		return result;
	}

	return (
		<main className={clsx(classes.view, {
			[classes.viewShift]: props.drawerVisibility,
		})}>
			<div className="viewer viewer-image">
				<div className='left_part'>
					<div className='dataset_name'>{imageList && imageList.dataset?imageList.dataset.name:""}
					</div>
					{imageList && imageList.files.length > 0?
					<div className='filter_part'>
						<div className='search-name-box'>
							{showImageList?
							<input type="text" className="search-name-input"
							value={imageSearch}
							onChange={onChangeSearchImage}></input>
							:
							<div className='annotation_label'>Annotations</div>}
						</div>
						<div className='list-menu-box'>
							<div className="image_button_icon can_hover_icon" title="Reset Search" onClick={resetSearch}><div className='icon reset_icon'></div></div>
							<div className="image_button_icon can_hover_icon" title="Filter Images" onClick={handleFilterMenu}><div className='icon filter_icon'></div></div>
							<div className={`image_button_icon can_hover_icon ${!showImageList ? 'selected_icon' : ''}`} title="Switch List" onClick={switchAnnotation}><div className='icon annotation_manage_icon'></div></div>
							<div className={`image_button_icon can_hover_icon ${filterStatusArrays.indexOf("not_annotated") < 0 ? 'selected_icon' : ''}`} title="Show Only Annotated/All Images" onClick={showAnnotated}><div className='icon annotation_icon'></div></div>
							<div className={`image_button_icon can_hover_icon ${isShowImageThumb ? 'selected_icon' : ''}`} title="Switch View" onClick={switchView}><div className='icon switch_view'></div></div>

						</div>
					</div>
					:null}
					{showImageList && imageList && imageList.files.length > 0?
					<div className='image_sort_part'>
						<div className='image_sort_part_type'>
							<Select
								isMulti={false}
								onChange={selectSortType}
								styles={selectStyle}
								options={sortTypeList}
								isSearchable={false}
								value = {
									sortTypeList.filter(function (option) {
										return option.value === sortType;
									})
								}
							></Select>
						</div>
						<div className="image_sort_part_order">
							<Select
								isMulti={false}
								onChange={selectSortOrder}
								styles={selectStyle}
								options={sortOrderList}
								isSearchable={false}
								value = {
									sortOrderList.filter(function (option) {
										return option.value === sortOrder;
									})
								}
							></Select>
						</div>
					</div>
					:null}
					{showImageList && imageList && imageList.files.length > 0?
					<div className='image_list'>
						{imageList && imageList.files.length > 0?
							getImageItems()
						:null}
					</div>
					:null}

					{showImageList && imageList && imageList.files.length > 0?
						<div className='image-viewer-annotation-page'>
							{totalImagePage > 10?
								<div className="page-fast-button can_hover_icon" title="Prev 10 pages" onClick={goPrevFastPage}>
									<svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="NavigateBeforeIcon"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path></svg>
								</div>
							:null}
							<Pagination
							page={currentImagePage}
							count={totalImagePage}
							className='image-viewer-annotation-page-item'
							onChange={handleImagePage}
							variant="outlined"
							color="primary"
							// boundaryCount={2}
							sx={{
								'& .MuiPaginationItem-root': {
									background: '#898989 !important',
									color: "#ffffff !important",
									fontSize: 13,
								},
								'& .Mui-selected': {
									background: '#03a9f4 !important',
									color: "#ffffff !important",
									fontSize: 13,
								},
							}} />
							{totalImagePage > 10?
								<div className="page-fast-button can_hover_icon" title="Next 10 pages" onClick={goNextFastPage}>
									<svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="NavigateNextIcon"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path></svg>
								</div>
							:null}
						</div>
					:null}
					{!showImageList?
					<div className='annotation_list'>
						<DataGrid
							rows={tableRows}
							columns={columns}
							getCellClassName = {getAnnotationGridClassName}
							hideFooter={true}
							disableSelectionOnClick={true}
							disableColumnSelector={true}
							disableMultipleSelection={true}
							components={{
								NoRowsOverlay: () => (
								<Stack height="100%" alignItems="center" justifyContent="center" className='no_annotations'>
									No Annotations
								</Stack>
								)
							}}
							sx={{
								boxShadow: 1,
								border: 1,
								borderColor: '#363739',
								color: "#797d87",
								'& .MuiDataGrid-overlay': {
									background: '#161616 !important',
									fontSize: "20px"
								},
								'& .MuiDataGrid-cell:hover': {
									color: 'white',
									cursor: 'pointer'
								},
								'& .MuiDataGrid-cell:focus': {
									outline: 'none !important'
								},
								'& .MuiTablePagination-root': {
									color: 'white',
								},
								"& .MuiPaginationItem-root": {
									color: "#fff"
								}
							}}
						/>
					</div>
					:null}
				</div>
				{imageList && imageList.files.length > 0?
					<div className={`image_view ${(selectedIndex >= 0)?'image_view_' + imageList.files[selectedIndex].inspect:""}`}>
						<div className={`image_top_part ${!isImageThumMode ? 'visible_component' : 'hide_component'}`}>
							{api.canEditItem(props.userInfo)?
							<div className="image_button_icon can_hover_icon" title="Prev Check Image" onClick={prevCheckImage}><div className='icon green_right_arrow_icon'></div></div>
							:null}
							{/* <div className="image_button_icon can_hover_icon" title="10 Prev Image" onClick={prevFastImage}><div className='icon fast_right_arrow_icon'></div></div> */}
							<div className="image_button_icon can_hover_icon" title="Prev Image" onClick={prevImage}><div className='icon right_arrow_icon'></div></div>
							<div className="image_button_icon can_hover_icon" title="Next Image" onClick={nextImage}><div className='icon left_arrow_icon'></div></div>
							{/* <div className="image_button_icon can_hover_icon" title="10 Next Image" onClick={nextFastImage}><div className='icon fast_left_arrow_icon'></div></div> */}
							{api.canEditItem(props.userInfo)?
							<div className="image_button_icon can_hover_icon" title="Next Check Image" onClick={nextCheckImage}><div className='icon green_left_arrow_icon'></div></div>
							:null}
							{/* <div className="image_button_icon can_hover_icon" title="Clear All Annotation" onClick={resetAnnotationByDialog}><div className='icon reset_icon'></div></div> */}
							{/* <div className="image_button_icon can_hover_icon" title="Visible All Annotation" onClick={visibleAllAnnotation}>
								{allVisible? <div className='icon visible_icon'></div> : <div className='icon invisible_icon'></div>}
							</div> */}
							<div className="image_button_icon can_hover_icon" title="Download Image" onClick={downloadImage}>
								<div className='icon download_icon'></div>
							</div>
							<div className="image_button_icon can_hover_icon" title="Visible Menu" onClick={visibleMenu}>
								{isShowingMenu? <div className='icon viewmode_icon'></div> : <div className='icon view_mode_invisible_icon'></div>}
							</div>
							{api.canEditItem(props.userInfo)?
							<div className="image_button_icon can_hover_icon" title="Delete Image" onClick={deleteImage}><div className='icon delete_icon'></div></div>
							:null}
							<div className="image_button_icon can_hover_icon" title="Fit Width" onClick={fitWidthImage}>
								<div className='icon fit_width_icon'></div>
							</div>
							<div className="image_button_icon can_hover_icon" title="Fit Height" onClick={fitHeightImage}>
								<div className='icon fit_height_icon'></div>
							</div>
							<div className="image_button_icon can_hover_icon" title="FullScreen" onClick={changeFullScreen}>
								{isFullScreen()? <div className='icon fullscreen_exit'></div> : <div className='icon fullscreen_enter'></div>}
							</div>
						</div>
						{(isShowingMenu && annotationSelected !== -1 && annotationList.length > 0)?
							<div className='image_annotation_top_part'>
								<div className="image_button_icon can_hover_icon" title="Delete Annotation" onClick={deleteSelectedAnnotation}><div className='icon delete_icon'></div></div>
								<div className="image_button_icon can_hover_icon" title="Visible Annotation" onClick={visibleAnnotation}>
									{(annotationList[annotationSelected] && annotationList[annotationSelected].visible)? <div className='icon visible_icon'></div> : <div className='icon invisible_icon'></div>}
								</div>
								<div className="image_button_icon can_hover_icon" title="Edit Annotation" onClick={editAnnotation}>
									<div className='icon edit_icon'></div>
								</div>
							</div>
						:null}
						{/* {isShowingMenu?
						<div className='image_left_part'>
							<div className='inspect_selection_type'>
								<div className='inspect_selection_list'>
									<div className='inspect_level_1'>
										<Radio
											checked={inspectlevel === 5}
											onChange={handleChangeInspectLevel}
											value="5"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
									<div className='inspect_level_2'>
										<Radio
											checked={inspectlevel === 4}
											onChange={handleChangeInspectLevel}
											value="4"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
									<div className='inspect_level_3'>
										<Radio
											checked={inspectlevel === 3}
											onChange={handleChangeInspectLevel}
											value="3"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
									<div className='inspect_level_4'>
										<Radio
											checked={inspectlevel === 2}
											onChange={handleChangeInspectLevel}
											value="2"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
									<div className='inspect_level_5'>
									<Radio
											checked={inspectlevel === 1}
											onChange={handleChangeInspectLevel}
											value="1"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
									<div className='inspect_level_6'>
									<Radio
											checked={inspectlevel === 0}
											onChange={handleChangeInspectLevel}
											value="0"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						:null} */}
						{isShowingMenu && (imageMeasureReference.current.length > 0 || drawingMode.current === "image_measure_line")?
						<div className='image_left_part'>
							<div className='image_measure_menu'>
								<div className='flex_part'>
									<div className='done_button_part'>
										<Button variant="contained"
											color="primary" onClick={updateImageMeasure} className={classes.measure_done_button}>
											Done
										</Button>
									</div>
									<div className='close_button_part'>
										<div className='close_button' onClick={hideImageMeasure}>
											<img src="/close.png" alt="close" style={{height: "100%"}}/>
										</div>
									</div>
								</div>
								<div className='image_measure_divider'/>
								{drawingMode.current === "image_measure_line" ?
									<div className='measure_point_reference'>
										<div className='settings_component'>
											<Switch
												checked={isThreePointReference.current}
												onChange={() => {
													if (window.addingAnnotation && window.addingAnnotation.positions.length >= 2) {
														toast("You can't change the Point Reference after adding two points");
														return;
													}
													setThreePointReference(!isThreePointReference.current);
												}}
											/>
										</div>
										<label className='label_settings' onClick={() => {
											if (window.addingAnnotation && window.addingAnnotation.positions.length >= 2) {
												toast("You can't change the Point Reference after adding two points");
												return;
											}
											setThreePointReference(!isThreePointReference.current);
										}}>3 Point Reference</label>
									</div>
									:null
								}
								{drawingMode.current === "image_measure_line" ?
									<div className='image_measure_divider'/>
									:null
								}
								<div className='measurement_reference_list'>
									{getMeasurementReferenceList()}
								</div>
							</div>
						</div>
						:null}
						{isShowingMenu && api.canEditItem(props.userInfo)?
						<div className={`image_control_part ${!isImageThumMode ? 'visible_component' : 'hide_component'}`}>
							<div className="select_left_icon" onClick={resetAnnotationByDialog} title="Reset Annotations">
								<div className='icon reset_icon'></div>
							</div>
							<div className="select_left_icon" onClick={referenceMode} title="Add Measurements">
								{drawingMode.current === "image_measure_line" ?
									<div className='icon red_measure_line'></div>
									:
									<div className='icon measurement_line_icon'></div>
								}
							</div>
							<div className="select_left_icon" onClick={pointMode} title="Add Marker">
								{drawingMode.current === "image_point" ?
									<div className='icon red_marker'></div>
									:
									<div className='icon marker_icon'></div>
								}
							</div>
							<div className="select_left_icon" onClick={polylineMode} title="Add Polyline">
								{drawingMode.current === "image_polyline" ?
									<div className='icon red_polyline'></div>
									:
									<div className='icon measurement_polyline_icon'></div>
								}
							</div>
							<div className="select_left_icon" onClick={polygonMode} title="Add Polygon">
								{drawingMode.current === "image_polygon" ?
									<div className='icon red_polygon'></div>
									:
									<div className='icon polygon_icon'></div>
								}
							</div>
							<div className="select_left_icon" onClick={leftArrowMode} title="Add Left Arrow">
								{drawingMode.current === "image_left_arrow" ?
									<div className='icon image_left_arrow'></div>
									:
									<div className='icon right_arrow_icon'></div>
								}
							</div>
							<div className="select_left_icon" onClick={rightArrowMode} title="Add Right Arrow">
								{drawingMode.current === "image_right_arrow" ?
									<div className='icon image_right_arrow'></div>
									:
									<div className='icon left_arrow_icon'></div>
								}
							</div>
							<div className="select_left_icon" onClick={upArrowMode} title="Add Up Arrow">
								{drawingMode.current === "image_up_arrow" ?
									<div className='icon image_up_arrow'></div>
									:
									<div className='icon up_arrow_icon'></div>
								}
							</div>
							<div className="select_left_icon" onClick={downArrowMode} title="Add Down Arrow">
								{drawingMode.current === "image_down_arrow" ?
									<div className='icon image_down_arrow'></div>
									:
									<div className='icon down_arrow_icon'></div>
								}
							</div>
						</div>
						:null}
						<div className={`${isImageThumMode ? 'image_map_part_full_screen' : 'image_map_part'} ${isShowingMenu || isImageThumMode ? 'visible_component' : 'hide_component'}`}>
							{!isImageThumMode?
								<div className="small_icon can_hover_icon map_fullscreen_btn" title="Map Extend" onClick={mapExtend}>
									<div className='icon fullscreen_enter'></div>
								</div>
							:null}
							<div className="map-container" id="map_container"></div>
						</div>
						<div className={`${isImageThumMode ? 'image_part_full_screen' : 'image_part'}`} id="drawing_part">
							{isImageThumMode?
								<div className="small_icon can_hover_icon image_fullscreen_btn" title="Image Extend" onClick={mapExtend}>
									<div className='icon fullscreen_enter'></div>
								</div>
							:null}
							<TransformWrapper
								onInit={transformInit}
								onZoomStop={transformFinishZoom}
								centerOnInit={true}
								minScale={minScale}
								defaultScale={1.0}
							>
								<TransformComponent>
									<canvas id="drawing_canvas" className={`${drawingMode.current !== "" ? 'mouse_cross' : ''} ${(selectedIndex >= 0 && !isImageThumMode)?'image_view_' + imageList.files[selectedIndex].inspect:""}`}/>
								</TransformComponent>
							</TransformWrapper>
						</div>
					</div>

				:null}
			</div>
			<Modal show={isCreatingAnnotation.current}
				animation={false}
				className="modal-annotation-create"
				container={container()}
				>
                <Modal.Header>
					<div className='creating_component_dialog'>
						<Modal.Title>{annotationUpdating?"Edit Annotation":"Create Annotation"}</Modal.Title>
						<div className='close_button' onClick={hideAnnoationDialog}>
							<img src="/close.png" alt="close" style={{height: "100%"}}/>
						</div>
					</div>
                </Modal.Header>
                <Modal.Body>
					<div className='annotation_info_part'>
						<div className='annotation_edit_info_part'>
							<p className="project-name-title">Annotation Statistics:</p>
							{annotationUpdating?
							getAnnotationStatusItems(annotationSelected)
							:null}
							<div className='annotation_selection_type_label'>
								<div className='annotation_info_label'>Annotation ID:</div>
								<div className='annotation_info_value'>{annotationUpdating?annotationList[annotationSelected].id:""}</div>
							</div>
							<div className='annotation_selection_type_label'>
								<div className='annotation_info_label'>Annotation By:</div>
								<div className='annotation_info_value'>{annotationUpdating?annotationList[annotationSelected].last_edit_email:props.userInfo.email}</div>
							</div>
							{imageMeasureReference.current.length !== 0?
							<div className='annotation_selection_type_label'>
								<div className='annotation_info_label'>Annotation Measurements:</div>
								<div className='annotation_info_value' dangerouslySetInnerHTML={{__html: getAnnotationMeasurementsLabel()}}></div>
							</div>
							:null}
						</div>
						<div className='annotation_edit_info_part'>
							<div className='project-name-box'>
								<p className="project-name-title">Annotation Title</p>
								<input type="text" className="project-name-input"
								value={creatingAnnotationName}
								onChange={onChangeAnnotationName}></input>
							</div>
							<div className='project-name-box'>
								<p className="project-name-title">Annotation Comment</p>
								<textarea className="project-name-input"
								value={creatingAnnotationDescription}
								onChange={onChangeAnnotationDescription}></textarea>
							</div>
							<div className='annotation_selection_type'>
								<div className='selection_label'>Annotation Type</div>
								<div className='selection_list'>
									<Select
										isMulti={false}
										onChange={selectAnnotationType}
										styles={selectStyle}
										options={annotationTypeList}
										isSearchable={false}
										value = {
											annotationTypeList.filter(function (option) {
												return option.value === annotationType;
											})
										}
									></Select>
								</div>
							</div>
							<div className='annotation_selection_type'>
								<div className='selection_label'>Annotation Status</div>
								<div className='selection_list'>
									<Select
										isMulti={false}
										onChange={selectAnnotationStatus}
										styles={selectStyle}
										options={annotationStatusList}
										isSearchable={false}
										value = {
											annotationStatusList.filter(function (option) {
												return option.value === annotationStatus;
											})
										}
									></Select>
								</div>
							</div>
							<div className='severity_selection_type'>
								<div className='selection_label'>Severity Level 1-4</div>
								<div className='selection_list'>
									<div className='severity_level_1'>
										<Radio
											checked={severitylevel.current === 1}
											onChange={handleChangeSeverityLevel}
											value="1"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
									<div className='severity_level_2'>
										<Radio
											checked={severitylevel.current === 2}
											onChange={handleChangeSeverityLevel}
											value="2"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
									<div className='severity_level_3'>
										<Radio
											checked={severitylevel.current === 3}
											onChange={handleChangeSeverityLevel}
											value="3"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
									<div className='severity_level_4'>
										<Radio
											checked={severitylevel.current === 4}
											onChange={handleChangeSeverityLevel}
											value="4"
											name="radio-buttons"
											sx={{
												color: "grey",
												'&.Mui-checked': {
													color: "black",
												},
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
                </Modal.Body>
                <Modal.Footer>
					{annotationUpdating?
					<Button variant="contained"
						color="primary" onClick={updateAnnotation} className={classes.annotation_button}>
						Update
					</Button>
					:
					<Button variant="contained"
						color="primary" onClick={createAnnotation} className={classes.annotation_button}>
						Create
					</Button>}
                </Modal.Footer>
            </Modal>
			<Modal show={isMeasureNameChange && imageMeasureReference.current.length > selectedMeasure.current && selectedMeasure.current !== -1}
				animation={false}
				className="modal-classification-label"
				container={container()}
				>
                <Modal.Header>
					<div className='creating_component_dialog'>
						<Modal.Title>Update Measure Name</Modal.Title>
						<div className='close_button' onClick={hideNameChangeDialog}>
							<img src="/close.png" alt="close" style={{height: "100%"}}/>
						</div>
					</div>
                </Modal.Header>
                <Modal.Body>
                    <div className="classification_update_container">
                        <div className='classification_update_info'>
                            <div className='project-name-box'>
                                <p className="project-name-title">Measure Name</p>
                                <input type="text" className="project-name-input"
                                    value={measureSelectedName}
                                    onChange={(event) => {
										setMeasureSelectedName(event.target.value);

                                    }}></input>
                            </div>
                        </div>
						<div className='classification_update_info'>
                            <div className='project-name-box'>
                                <p className="project-name-title">Measure Value</p>
                                <input type="text" className="project-name-input"
                                    value={measureSelectedValue}
                                    onChange={(event) => {
										setMeasureSelectedValue(event.target.value);

                                    }}></input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="contained"
                    color="primary" onClick={() => {
						let measurements = JSON.parse(JSON.stringify(imageMeasureReference.current));
						measurements[selectedMeasure.current].label = measureSelectedName;
						measurements[selectedMeasure.current].value = parseFloat(measureSelectedValue);
						setImageMeasureReference(measurements);
						hideNameChangeDialog();
					}} className={classes.button}>
                    Update
                </Button>
                </Modal.Footer>
            </Modal>
			{getFilterImageMenu()}
			{getDeleteDialog()}
		</main >
	);
}


const mapStateToProps = state => ({
	drawerVisibility: state.global.drawerVisibility,
	mapType: state.map.mapType,
	selectedProject: state.project.selectedProject,
	datasetList : state.project.datasetList,
	userInfo: state.global.userInfo
});

ImageAnnotationView.propTypes = {
	drawerVisibility: PropTypes.bool.isRequired,
	mapType: PropTypes.number.isRequired,
}

export default compose(
	withRouter,
	connect(mapStateToProps, globalAction),
	connect(mapStateToProps, mapAction),
	connect(mapStateToProps, projectAction),
)(ImageAnnotationView);