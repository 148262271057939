import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/css/material.css?v=1.6.0';

async function initApplication() {
    ReactDOM.render(<App />, document.getElementById('root'));
}

initApplication();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// While coding to stop the 3D globe from maxing out the GPU
// window.MAIN_CESIUM_VIEWER.scene.canvas.style = "display:none;";